import React from 'react'
import GenerateReport from '@/modules/report-generator/components/generateReport'
import AssetSelection from '@/modules/report-generator/components/assetsSelection'
import { StyledReportGeneratorContainer } from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'
import { ReportGeneratorContextProvider } from '@/contexts/reportGenerator'
import { FetchDataContextProvider } from '@/contexts/common/fetchDataContext'

const ReportGenerator: React.FC = () => {
  return (
    <FetchDataContextProvider>
      <ReportGeneratorContextProvider>
        <StyledReportGeneratorContainer data-testid={dataTestId.reportGenerator}>
          <AssetSelection />
          <GenerateReport />
        </StyledReportGeneratorContainer>
      </ReportGeneratorContextProvider>
    </FetchDataContextProvider>
  )
}
export default ReportGenerator
