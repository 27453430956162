import { EChartsOption } from 'echarts'
import { FC, useMemo } from 'react'
import { RAwidgetDataType, RaWidgetClosedStatusTypes } from '@/models/widgets/recommended-actions/types'
import { ReactEChartsRenderer } from '@/modules/dashboard/components/common/e-charts-renderer'
import {
  DashedPattern,
  applyDashedPatternToMarkArea,
  calculateOptions,
  useCalculateYAxisMaxLabel,
  useNoDataMonthColumnsIndices,
} from '@/modules/dashboard/utils/recommended-actions'
import { useDashboardContext } from '@/contexts/dashboard'
import { formatDate } from '@/shared/dateUtils'

interface RecommendedActionsGraphProps {
  slices: RAwidgetDataType
  showPercentages: boolean
  noDataInAllMonth: boolean
}

const RecommendedActionsGraph: FC<RecommendedActionsGraphProps> = ({ slices, showPercentages, noDataInAllMonth }) => {
  const { dashboardFilters } = useDashboardContext()

  const modifiedData = useMemo(() => {
    const data: { [key: string]: number[] } = {}

    dashboardFilters.raStatus.forEach((key) => {
      if (key === 'closed') {
        data[RaWidgetClosedStatusTypes.completed] = []
        data[RaWidgetClosedStatusTypes.rejected] = []
      } else {
        data[key] = []
      }
    })

    slices.forEach((slice) => {
      const keysOfDataObj = Object.keys(slice.data)

      dashboardFilters.raStatus.forEach((key) => {
        if (key === 'closed') {
          const completedValue = keysOfDataObj.includes(RaWidgetClosedStatusTypes.completed)
            ? slice.data[RaWidgetClosedStatusTypes.completed]
            : 0
          const rejectedValue = keysOfDataObj.includes(RaWidgetClosedStatusTypes.rejected)
            ? slice.data[RaWidgetClosedStatusTypes.rejected]
            : 0

          data[RaWidgetClosedStatusTypes.completed].push(completedValue)
          data[RaWidgetClosedStatusTypes.rejected].push(rejectedValue)
        } else {
          const value = keysOfDataObj.includes(key) ? slice.data[key] : 0
          data[key].push(value)
        }
      })
    })

    return data
  }, [slices, dashboardFilters.raStatus])

  const yAxisMaxVal = useCalculateYAxisMaxLabel(modifiedData)

  const columns = useMemo(() => {
    const doubledColumns: string[] = ['']

    slices.forEach((slice) => {
      // Use Date object correctly without overwriting and ensure proper month handling
      const date = new Date()
      date.setFullYear(slice.period.year, slice.period.month - 1, 1) // Set correct year and month

      let label = `${formatDate(date, 'MMM').toLocaleUpperCase()}`
      label += `\n{year|(${slice.period.year})}`

      doubledColumns.push(label)

      const additionalLabel = `15-${label}`
      doubledColumns.push(additionalLabel)
    })

    return doubledColumns
  }, [slices])

  const noDataMonthColumnsIndices = useNoDataMonthColumnsIndices(slices, noDataInAllMonth)

  const option = useMemo<EChartsOption>(
    () =>
      calculateOptions(
        modifiedData,
        columns,
        yAxisMaxVal,
        showPercentages,
        noDataInAllMonth,
        noDataMonthColumnsIndices
      ),
    [modifiedData, columns, yAxisMaxVal, showPercentages, noDataInAllMonth, noDataMonthColumnsIndices]
  )
  if (option.series && Array.isArray(option.series) && option.series.length > 0 && !noDataInAllMonth) {
    const dashedPattern = DashedPattern('#bfdbfe99', '#fff', 'ra-pieChart')
    if (dashedPattern) {
      applyDashedPatternToMarkArea(option, dashedPattern)
    }
  }

  return <ReactEChartsRenderer option={option} />
}
export default RecommendedActionsGraph
