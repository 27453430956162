import { deviceNumberFormatter } from '@/modules/devicesSensors/utils/constants'
import useGetCharactersLength from '@/modules/devicesSensors/hooks/useGetCharactersLength'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { FC } from 'react'

interface DeviceNumberCellProps {
  deviceNumber: number
}

const DeviceNumberCell: FC<DeviceNumberCellProps> = ({ deviceNumber }) => {
  const characterLengths = useGetCharactersLength()
  return (
    <TextEllipsis
      type={TextEllipsisType.SingleLine}
      characterLength={characterLengths.number}
      value={deviceNumberFormatter.format(deviceNumber)}
    />
  )
}
export default DeviceNumberCell
