import React, { useEffect } from 'react'
import ReportFilters from '@/modules/report-generator/components/reportFilters'
import {
  StyledGenerateReportContainer,
  StyledGenerateReportWrap,
  StyledGenerateReportFooter,
  StyledReportFilters,
  StyledReportGeneratorBackButton,
  StyledGenerateReportExportPDFbutton,
} from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'
import ReportGeneratorHeader from '@/modules/report-generator/components/header'
import ReportPreview from '@/modules/report-generator/components/reportPreview'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Report from '@/modules/report-generator/components/reports'
import { useNavigate, useParams } from 'react-router'
import { ROUTES } from '@/shared/constants'
import useGetReportGeneratorSiteDetails from '@/modules/report-generator/hooks/useGetReportGeneratorSiteDetails'
import { LoadingType } from '@/shared/hooks/useApi'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import { convertToBoolean, generateUUID } from '@/shared/utils'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import GenericConfirmationDialogButton from '@/shared/components/GenericConfirmationDialog/GenericConfirmationDialogButton'
import { Text } from '@skf-internal/ui-components-react'
import { SHOW_REPORT_GENERATOR_EXPORT_PDF_DIALOG } from '@/shared/localStorageUtils'

const GenerateReport: React.FC = () => {
  const navigate = useNavigate()
  const { sid } = useParams()
  const reportGeneratorDispatch = useAppDispatch()
  const { showPreview, reportGeneratorLogoUrl, generatedReportData } = useReportGeneratorContext()

  const { execute: getReportGeneratorSiteDetails, status: getReportGeneratorSiteDetailsStatus } =
    useGetReportGeneratorSiteDetails()

  const handleBackToOverviewClick = () => {
    navigate(`${ROUTES.OVERVIEW}`)
  }

  useEffect(() => {
    getReportGeneratorSiteDetails({ loaderType: LoadingType.GLOBAL })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useEffect(() => {
    if (getReportGeneratorSiteDetailsStatus === 'success' && reportGeneratorLogoUrl) {
      reportGeneratorDispatch(stopLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReportGeneratorSiteDetailsStatus])

  const closeDialog = () => {
    reportGeneratorDispatch(closeGenericConfirmDialog())
  }

  const handleExportPdfClick = () => {
    const showDialog = localStorage.getItem(SHOW_REPORT_GENERATOR_EXPORT_PDF_DIALOG) as string

    if (!convertToBoolean(showDialog))
      reportGeneratorDispatch(
        showGenericConfirmDialog({
          isOpen: true,
          title: 'Export PDF',
          message: (
            <Text>
              Your report is being prepared in the background and will automatically start to download. You may keep
              working while it is being generated.
              <br />
              <br />
              Don't show again
            </Text>
          ),
          buttons: [
            <GenericConfirmationDialogButton
              data-testid={dataTestId.reportGeneratorConfirmDialogConfirmButton}
              type="primary"
              label="OK"
              onClick={async () => {
                localStorage.setItem(SHOW_REPORT_GENERATOR_EXPORT_PDF_DIALOG, 'true')
                closeDialog()
              }}
            />,
          ],
        })
      )
  }

  return (
    <StyledGenerateReportContainer data-testid={dataTestId.generateReport} feFlexDirection="column">
      <StyledGenerateReportWrap>
        <StyledReportGeneratorBackButton
          feIcon={{
            feIcon: 'arrowLeft',
            position: 'left',
          }}
          feType="secondary"
          data-testid={dataTestId.siteDetailsBackButton}
          onClick={handleBackToOverviewClick}
          feSize="sm"
          backgroundColor="transparent"
        >
          Back to overview
        </StyledReportGeneratorBackButton>

        <ReportGeneratorHeader />
        <StyledReportFilters>
          <ReportFilters />
        </StyledReportFilters>
        <ReportPreview />
      </StyledGenerateReportWrap>
      <StyledGenerateReportFooter feJustifyContent="right">
        <PDFDownloadLink
          document={<Report key={generateUUID()} generatedReportData={generatedReportData} />}
          fileName="site-report.pdf"
        >
          <StyledGenerateReportExportPDFbutton
            feSize="sm"
            disabled={!showPreview}
            onClick={handleExportPdfClick}
            data-testid={dataTestId.generateReportExportButton}
          >
            Export PDF
          </StyledGenerateReportExportPDFbutton>
        </PDFDownloadLink>
      </StyledGenerateReportFooter>
    </StyledGenerateReportContainer>
  )
}
export default GenerateReport
