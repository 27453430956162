import { OverviewResponse } from '@/models/overview/types'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'

interface IOverviewContext {
  overviewData: OverviewResponse['sites']
  setOverviewData: React.Dispatch<React.SetStateAction<IOverviewContext['overviewData']>>
}

export const OverviewContext = createContext<IOverviewContext | null>(null)

export const useOverviewContext = () =>
  useContextWrapper(OverviewContext, {
    contextName: useOverviewContext.name,
    providerName: OverviewContextProvider.name,
  })

export const OverviewContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [overviewData, setOverviewData] = useState<IOverviewContext['overviewData']>([])
  const value = useMemo(
    () => ({
      overviewData,
      setOverviewData,
    }),
    [overviewData]
  )

  return <OverviewContext.Provider value={value}>{children}</OverviewContext.Provider>
}
