import { useRouteError } from 'react-router'

const ReportStatusErrorElement = () => {
  const error = useRouteError()
  console.log('ReportStatusErrorElement!!!!!!!!!!!!!!!!!', error)

  throw error
}

export default ReportStatusErrorElement
