import { ReasonIndicatorData } from '@/models/analysisBacklog/types'
import ReasonIndicatorContainer from '@/modules/analysisBacklog/components/reasonIndicatorContainer'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface AnalysisReasonCellProps {
  isHandled: boolean
  analysisReasons: ReasonIndicatorData[]
}

const AnalysisReasonCell: FC<AnalysisReasonCellProps> = ({ analysisReasons }) => {
  return (
    <Flexbox feFlexWrap="wrap" feGap="xs">
      {analysisReasons.map((reason, i) => (
        <ReasonIndicatorContainer key={i} reason={reason} />
      ))}
    </Flexbox>
  )
}

export default AnalysisReasonCell
