import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { downloadCSV, getCSVFilename } from '@/shared/csvUtils'
import { headerActionPanelConfigSelector, headerCSVDataSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { IconButton, Popup, Text, css, useTooltip } from '@skf-internal/ui-components-react'

/**
 * HeaderExportCSVButton is a button component that triggers the export of CSV data
 * for the associated module when clicked.
 *
 * This component utilizes Redux for state management and a custom tooltip hook to provide
 * user feedback when the button is hovered.
 *
 * It checks the fetch data status to disable the button when data is being loaded
 * and optionally displays an asynchronous loading indicator.
 *
 * The button also shows a tooltip when hovered, indicating that the button's function
 * is to export the CSV.
 *
 * @returns {JSX.Element} A rendered IconButton that exports CSV data and a Popup tooltip.
 */

const HeaderExportCSVButton = () => {
  const [csvHoveredRef, isCsvHovered] = useTooltip()
  const { fetchDataStatus } = useFetchDataContext()
  const { csvDownLoadButtonSize, isAsyncIndicatorShown } = useTypedSelector((store) =>
    headerActionPanelConfigSelector(store)
  )
  const { csvData, moduleName } = useTypedSelector((store) => headerCSVDataSelector(store))

  return (
    <>
      <IconButton
        aria-disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
        feIcon="download"
        as="button"
        aria-label="Export"
        feColor="brand"
        onClick={() => downloadCSV(csvData, getCSVFilename(moduleName))}
        ref={csvHoveredRef}
        disabled={fetchDataStatus === 'loading' && !isAsyncIndicatorShown}
        feSize={csvDownLoadButtonSize}
        data-testid={dataTestId.headerExportCSVButton}
      />
      <Popup isHovered={isCsvHovered} triggerElement={csvHoveredRef.current}>
        <Text
          css={css`
            white-space: nowrap;
          `}
        >
          Export CSV
        </Text>
      </Popup>
    </>
  )
}

export default HeaderExportCSVButton
