import { GetConditionStatusesResponse } from '@/api/paths/analytics/conditionStatuses/types'
import { GetRecommendedActionsDueDatesStatusResponse } from '@/api/paths/analytics/dueDatesStatus/types'
import { GetFaultsResponse } from '@/api/paths/analytics/faults/types'
import { GetRecommendationsResponse } from '@/api/paths/analytics/recommendations/types'
import { UseApiProps } from '@/shared/hooks/useApi'
import { Layout } from 'react-grid-layout'

export type Dashboard = {
  dashboardId: string
  title: string
  isDefault: boolean
  isEditable: boolean
  filters?: unknown[]
}
export type DashboardLayout = {
  x: number
  y: number
  w: number
  h: number
  i: string
}

export type DashboardWidget = {
  id: string
  dashboardId: string
  title: string
  widgetType: string
}

export type DashboardData = Dashboard & { layouts: DashboardLayout[] } & {
  widgets: DashboardWidget[]
}

// export type WidgetType =
//   | 'recommended-actions'
//   | 'asset-health-condition'
//   | 'asset-status-overview'
//   | 'asset-data-collection'
//   | 'asset-open-faults'
//   | 'loader'

export enum WidgetType {
  RECOMMENDED_ACTIONS = 'recommended-actions',
  RA_DUE_DATES_STATUS = 'ra-due-dates-status',
  ASSET_HEALTH_CONDITION = 'asset-health-condition',
  ASSET_STATUS_OVERVIEW = 'asset-status-overview',
  ASSET_DATA_COLLECTION = 'asset-data-collection',
  ASSET_OPEN_FAULTS = 'asset-open-faults',
  LOADER = 'loader',
}

export interface LayoutWithWidget extends Layout {
  widgetId: DashboardWidget['id'] | undefined
}

export type WidgetApiToExecute = {
  retryFn: (
    props?: UseApiProps | undefined
  ) => Promise<
    | void
    | GetConditionStatusesResponse
    | GetRecommendationsResponse
    | GetFaultsResponse
    | GetRecommendedActionsDueDatesStatusResponse
  >
  retryCount: number
}
