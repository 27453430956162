import { getOverviewData } from '@/api/paths/overview'
import { DarwinDetachedModuleError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useOverviewContext } from '@/contexts/overview'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { OverviewResponse } from '@/models/overview/types'

const useGetOverviewData = () => {
  const throwError = useAsyncThrow()
  const { setOverviewData } = useOverviewContext()

  return useApi(() => {
    return getOverviewData({
      onSuccess: async (response: OverviewResponse) => {
        setOverviewData(response.sites)
      },
      onError: async (error: AxiosError) => {
        throwError(new DarwinDetachedModuleError(error))
      },
    })
  })
}

export default useGetOverviewData
