import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { InitialAsset } from '@/models/analysisBacklog/types'
import { FaultState, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import {
  StyledAssetNameAndStatusContainer,
  StyledReportHeaderAnalysisReason,
  StyledReportHeaderButton,
  StyledReportStatusAssetStatusIcon,
  StyledReportStatusHeaderAssetNameLabel,
  StyledReportStatusHeaderBelongToLabel,
  StyledReportStatusHeaderContainer,
  StyledReportStatusHeaderContentContainer,
} from '@/modules/report-status/styled'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { FC, useCallback, useEffect } from 'react'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { ADD_NEW_FAULT } from '@/modules/report-status/reducer/actions.types'
import { useReportStatusContext } from '@/contexts/reportStatus'
import AnalysisReason from '@/modules/analysisBacklog/components/analysisReason'
import { dataTestId } from '@/tests/testid'
import { generateUUID } from '@/shared/utils'
import { asyncPollerSelector } from '@/store/selectors/asyncPollerSelector'

interface ReportStatusHeaderProps {
  assetRow: InitialAsset
}

const ReportStatusHeader: FC<ReportStatusHeaderProps> = ({ assetRow }) => {
  const {
    faultsFormState: { faults, isSubmitting, lastCollectionDate },
    faultsFormDispatch,
  } = useFaultsFormStateContext()

  const { setAnalyticsCancelButtonBehavior } = useReportStatusContext()

  const reportStatusHeaderDispatch = useAppDispatch()

  const addNewFault = useCallback(() => {
    const newFaultDate = new Date()

    faultsFormDispatch({
      type: ADD_NEW_FAULT,
      payload: {
        newFault: {
          id: generateUUID(),
          state: FaultState.NEW,
          createdDate: formatDate(new Date(), DateFormats.AmericanDateFormat),
          collectionDate: lastCollectionDate,
          observation: '',
          recommendedActions: [
            {
              additionalNotes: '',
              workOrder: '',
              dueDate: formatDate(newFaultDate, DateFormats.ISO8601Format),
              createdDate: formatDate(new Date()),
              state: RecommendedActionState.NEW,
              id: generateUUID(),
            },
          ],
          evidences: [],
        },
        lastCollectionDate,
      },
    })
    setAnalyticsCancelButtonBehavior({ isDisabled: false })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetRow])

  const { isAsyncPollerActive } = useTypedSelector((state) => asyncPollerSelector(state))

  useEffect(() => {
    reportStatusHeaderDispatch(
      setModuleHeaderActionPanelData({
        withAsyncIndicator: true,
      })
    )
    return () => {
      setModuleHeaderActionPanelData({
        withAsyncIndicator: false,
      })
    }
  }, [reportStatusHeaderDispatch])

  return (
    <StyledReportStatusHeaderContainer
      feAlignItems="center"
      feJustifyContent="space-between"
      data-testid={dataTestId.formHeader}
    >
      <StyledReportStatusHeaderContentContainer feFlexDirection="column">
        <StyledAssetNameAndStatusContainer feAlignItems="center">
          <StyledReportStatusAssetStatusIcon value={assetRow.status} />
          <StyledReportStatusHeaderBelongToLabel>
            {assetRow.belongsTo.name.trim()}
          </StyledReportStatusHeaderBelongToLabel>
          <div>/</div>
          <StyledReportStatusHeaderAssetNameLabel>{assetRow.name}</StyledReportStatusHeaderAssetNameLabel>
        </StyledAssetNameAndStatusContainer>

        <StyledReportHeaderButton
          feType="secondary"
          feIcon={{ feIcon: 'addPlus', position: 'left' }}
          onClick={() => addNewFault()}
          disabled={isSubmitting || faults?.length >= 5 || isAsyncPollerActive}
          data-testid={dataTestId.formHeaderAddNewFaultButton}
        >
          Add Fault
        </StyledReportHeaderButton>
      </StyledReportStatusHeaderContentContainer>
      <StyledReportHeaderAnalysisReason>
        {!assetRow.isHandled && assetRow.analysisReasons.map((reason, i) => <AnalysisReason key={i} reason={reason} />)}
      </StyledReportHeaderAnalysisReason>
    </StyledReportStatusHeaderContainer>
  )
}

export default ReportStatusHeader
