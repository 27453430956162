import { postFaults } from '@/api/paths/analytics/faults'
import { DarwinWidgetError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useApi } from '@/shared/hooks/useApi'
import { FaultsApiData } from '@/models/widgets/asset-health-fault/types'
import { SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { AxiosError } from 'axios'
import { mapOpenFaultsData, modifyFaultsData } from '../../utils/asset-health-fault'
import { useDashboardContext } from '@/contexts/dashboard'
import { useParams } from 'react-router'
import { WidgetType } from '@/models/dashboard/types'
import { useTypedSelector } from '@/store/store'

const useWidgetFaults = (globalFilter: SelectFilterType) => {
  const { sid: companyId } = useParams()
  const { setWidgetAssetHealthFaultData, setModifiedOpenFaultsData } = useDashboardContext()
  const reportFaultTypes = useTypedSelector((state) => state.reportFaultTypes)

  return useApi(async () => {
    return await postFaults(
      globalFilter,
      {
        onSuccess: async (result) => {
          const modifiedFaultsData = modifyFaultsData(result as unknown as FaultsApiData[])
          if (modifiedFaultsData.length && reportFaultTypes.length) {
            setModifiedOpenFaultsData(modifiedFaultsData)
            setWidgetAssetHealthFaultData(mapOpenFaultsData(modifiedFaultsData, reportFaultTypes) as FaultsApiData[])
          }
        },
        onError: async (error: AxiosError) => {
          return Promise.reject(new DarwinWidgetError(error, WidgetType.ASSET_OPEN_FAULTS))
        },
      },
      { companyId }
    )
  })
}

export default useWidgetFaults
