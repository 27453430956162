import PopoverContent from '@/modules/overview/components/actionMenu/menuPopover'
import { StyledActionColumnPopup, StyledPopover } from '@/modules/overview/styled'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { generateUUID } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { IconButton, useTooltip } from '@skf-internal/ui-components-react'
import { FC, useRef, useState } from 'react'

type ActionMenuProps = {
  siteId: string
}

const ActionMenu: FC<ActionMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)

  const outsideClickRef = useRef<HTMLDivElement>(null)
  useOutsideClick(
    () => {
      setIsOpen(false)
    },
    {
      refs: [outsideClickRef],
    }
  )
  const [clickedRef] = useTooltip()

  return (
    <div>
      <StyledActionColumnPopup isHovered={isOpen} triggerElement={clickedRef.current}>
        <StyledPopover ref={outsideClickRef} aria-labelledby={generateUUID()}>
          <PopoverContent {...props} />
        </StyledPopover>
      </StyledActionColumnPopup>

      <IconButton
        aria-label="Actions"
        as="button"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        ref={clickedRef}
        feIcon="kebabMore"
        feSize="md"
        data-testid={dataTestId.overviewActionMenu}
      />
    </div>
  )
}

export default ActionMenu
