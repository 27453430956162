import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone, FileRejection, ErrorCode } from 'react-dropzone'
import { dataTestId } from '@/tests/testid'
import {
  StyledDashedBorder,
  StyledErrorParagraph,
  StyledIcon,
  StyledInput,
  StyledParagraph,
  StyledUploadIconContainer,
  StyledLabel,
  StyledIsRequired,
} from '@/modules/site-details/styled'
import {
  ACCEPTED_FILE_TYPES,
  MAX_FILE_SIZE,
  ERROR_TIMEOUT,
  MAX_FILE_SIZE_IN_MB,
} from '@/modules/site-details/utils/constants'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { useParams } from 'react-router'
import { EvidenceImageData } from '@/models/reportStatus/evidenceTypes'
import useUploadSiteDetailsLogo from '@/modules/site-details/hooks/useUploadSiteDetailsLogo'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import useTimeout from '@/shared/hooks/useTimeout'
import { convertFileNameExtensionToLowercase, generateUUID } from '@/shared/utils'
import GenericLoader, { GenericLoaderType } from '@/shared/components/GenericLoader'
import { LoadingType } from '@/shared/hooks/useApi'
import { WidgetType } from '@/models/dashboard/types'

const DragAndDropLogo: React.FC = () => {
  const [uploadedFile, setUploadedFile] = useState<EvidenceImageData | null>(null)
  const { sid } = useParams()
  const { execute: uploadLogo, status: uploadLogoStatus } = useUploadSiteDetailsLogo(uploadedFile!)
  const { setIsSiteDetailsEdited, siteDetailsError, setSiteDetailsError } = useSiteDetailsContext()

  const resetSiteDetailsLogoUploadError = () =>
    setSiteDetailsError({
      ...siteDetailsError,
      siteDetailsLogoUploadError: null,
    })

  const { startTimeout: resetError, clearTimeout: clearResetErrorTimeOut } = useTimeout(() => {
    resetSiteDetailsLogoUploadError()
  }, ERROR_TIMEOUT)

  const validator = useCallback((file: File) => {
    if (file.name) {
      const fileExtension = file.name?.split('.').pop()?.toLowerCase()

      if (fileExtension && !ACCEPTED_FILE_TYPES.includes(fileExtension)) {
        return {
          code: ErrorCode.FileInvalidType,
          message: 'Invalid file format. Please upload a JPEG or PNG image.',
        }
      }
      if (file.size > MAX_FILE_SIZE) {
        return {
          code: ErrorCode.FileTooLarge,
          message: `File size exceeds ${MAX_FILE_SIZE_IN_MB}MB. Please upload a smaller image.`,
        }
      }
    }

    return null
  }, [])

  const convertToEvidenceImageData = useCallback(
    async (file: File): Promise<EvidenceImageData> => {
      const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result as string)
          reader.onerror = reject
          reader.readAsDataURL(file)
        })
      }

      const content = await convertFileToBase64(file)

      return {
        imageInfo: {
          fileID: generateUUID(),
          contentType: file.type,
          fileURL: URL.createObjectURL(file),
          fileName: convertFileNameExtensionToLowercase(file.name),
          fileCreatedAt: formatDate(new Date(), DateFormats.AmericanDateFormat),
          comment: '',
          size: file.size,
          content: content,
          originFile: file,
        },
        bucketInfo: {
          category: 'site-logo',
        },
        appInfo: {
          nodeID: sid,
          siteID: sid,
          isUploaded: false,
        },
      } as EvidenceImageData
    },
    [sid]
  )

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (acceptedFiles.length > 0) {
        resetSiteDetailsLogoUploadError()
        const convertedFile = await convertToEvidenceImageData(acceptedFiles[0])
        setUploadedFile(convertedFile)
      } else if (fileRejections.length > 0) {
        setSiteDetailsError({
          ...siteDetailsError,
          siteDetailsLogoUploadError: fileRejections[0].errors[0].message,
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setUploadedFile, convertToEvidenceImageData]
  )

  useDeepCompareEffect(() => {
    if (uploadedFile) {
      uploadLogo({
        loaderType: LoadingType.WIDGET,
        widgetType: WidgetType.LOADER,
      })
      setIsSiteDetailsEdited(true)
    }
  }, [uploadedFile])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    validator,
    multiple: false,
    maxFiles: 1,
  })

  useEffect(() => {
    resetError()

    return clearResetErrorTimeOut
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteDetailsError.siteDetailsLogoUploadError])

  return (
    <>
      <StyledLabel htmlFor="companyLogo">
        Company Logo <StyledIsRequired>*</StyledIsRequired>
      </StyledLabel>
      {uploadLogoStatus === 'loading' ? (
        <StyledDashedBorder {...getRootProps()} data-testid={dataTestId.dragAndDropLogo}>
          <GenericLoader loaderType={GenericLoaderType.WIDGET} />
        </StyledDashedBorder>
      ) : (
        <StyledDashedBorder {...getRootProps()} data-testid={dataTestId.dragAndDropLogo}>
          <StyledUploadIconContainer>
            <StyledIcon feIcon="upload" feColor="brand" feSize="sm" />
            <StyledInput {...getInputProps()} id="companyLogo" name="companyLogo" />
          </StyledUploadIconContainer>
          {isDragActive ? (
            <StyledParagraph>Release to drop the file here</StyledParagraph>
          ) : (
            <StyledParagraph>Drag file here or click to upload</StyledParagraph>
          )}
        </StyledDashedBorder>
      )}
      <StyledParagraph>JPEG or PNG file, up to {MAX_FILE_SIZE_IN_MB} mb</StyledParagraph>
      {siteDetailsError.siteDetailsLogoUploadError && (
        <StyledErrorParagraph>{siteDetailsError.siteDetailsLogoUploadError}</StyledErrorParagraph>
      )}
    </>
  )
}

export default DragAndDropLogo
