import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectErrorData = (state: RootState) => state.errorData

const errorDataSelector = createSelector(_selectErrorData, (errorData) => {
  let result = undefined
  if (errorData && errorData.error) {
    result = errorData.error
  }
  return result
})

export { errorDataSelector }
