import { DELETE_REDIRECT_DATA, PUSH_REDIRECT_DATA } from '@/store/redirectData/actions.types'
import { RedirectData } from '@/store/redirectData/types'

export const pushRedirectData = (payload: RedirectData) => ({
  type: PUSH_REDIRECT_DATA,
  payload,
})

export const deleteRedirectData = (payload: string) => ({
  type: DELETE_REDIRECT_DATA,
  payload,
})
