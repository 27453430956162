import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import toggleGenericConfirmDialogReducer from '@/store/genericConfirmationDialog/reducer'
import errorDataReducer from '@/store/errorHandler/reducer'
import customersListReducer from '@/store/customersList/reducer'
import selectedCustomerReducer from '@/store/selectedCustomer/reducer'
import openGenericToastReducer from '@/store/genericToast/reducer'
import {
  moduleHeaderActionPanelDataReducer,
  moduleHeaderCSVDataReducer,
  moduleHeaderConfigDataReducer,
} from '@/store/moduleHeader/reducer'
import asyncPollerReducer from '@/store/asyncPoller/reducer'
import reducerHeaderLoading from '@/store/loading/reducerHeaderLoading'
import reducerGlobalLoading from '@/store/loading/reducerGlobalLoading'
import reducerWidgetLoading from '@/store/loading/reducerWidget'
import reportFaultTypesReducer from '@/store/reportFaultTypes/reducer'
import manageRedirectDataReducer from '@/store/redirectData/reducer'

const rootReducer = combineReducers({
  errorData: errorDataReducer,
  customersList: customersListReducer,
  selectedCustomer: selectedCustomerReducer,
  genericConfirmDialogData: toggleGenericConfirmDialogReducer,
  genericToastData: openGenericToastReducer,
  moduleHeaderData: combineReducers({
    headerConfig: moduleHeaderConfigDataReducer,
    headerActionPanelConfig: moduleHeaderActionPanelDataReducer,
    headerCSVData: moduleHeaderCSVDataReducer,
  }),
  asyncPoller: asyncPollerReducer,
  headerLoading: reducerHeaderLoading,
  globalLoading: reducerGlobalLoading,
  widgetLoading: reducerWidgetLoading,
  reportFaultTypes: reportFaultTypesReducer,
  redirectData: manageRedirectDataReducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  })
}

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppStore = ReturnType<typeof setupStore>
