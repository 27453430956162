/**
 * Calculates the margin value based on the number of digits in the input.
 *
 * @param {number | string | null} num - The input value which can be a number, string, or null.
 * @returns {number} The calculated gap value. If the input is null or an empty string, it returns 0.5.
 */
const calculateIconMarginValue = (num: number | string | null): number => {
  let result = 0.5

  if (num !== null) {
    const digits = num.toString().length
    result = 0.5 * (digits - 1)
  }
  return result
}

/**
 * Calculates the margin value for the auto diagnostics icon.
 *
 * @param {number | string | null} num - The input value which can be a number, string, or null.
 * @returns {number} - Returns 0 if the input is not null, otherwise returns 0.3.
 */
const calculateAutoDiagnosticsIconMarginValue = (num: number | string | null): number => {
  return num !== null ? 0 : 0.3
}

export { calculateIconMarginValue, calculateAutoDiagnosticsIconMarginValue }
