import { ROUTES } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { deleteRedirectData } from '@/store/redirectData/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { SortingState } from '@tanstack/react-table'
import { MRT_Row, MRT_RowData, MRT_TableInstance } from 'material-react-table'
import { Dispatch, useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

type PreviousDataProps<T extends MRT_RowData, V> = {
  table: MRT_TableInstance<T>
  setCurrentSelectedRow?: (value: React.SetStateAction<T | undefined>) => void
  contextData?: V
  setSorting?: Dispatch<React.SetStateAction<SortingState>>
}

const usePreviousData = <T extends MRT_RowData, V>({
  table,
  contextData,
  setCurrentSelectedRow,
  setSorting,
}: PreviousDataProps<T, V>) => {
  const { sid } = useParams()
  const redirectData = useTypedSelector((state) => state.redirectData)
  const previousPageDataDispatch = useAppDispatch()

  const tableState = table.getState()

  const tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  const tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  const sortedRows = useDeepCompareMemo(
    () => table.getSortedRowModel().rows as MRT_Row<T>[],
    [contextData, tableSorting, tableColumnFilters]
  )

  const previousPageData = useMemo(() => {
    if (sid && redirectData && redirectData[sid]) {
      return redirectData[sid]
    }
  }, [redirectData, sid])

  useEffect(() => {
    if (
      previousPageData &&
      (previousPageData.toModule === previousPageData.fromModule ||
        previousPageData.fromModule === ROUTES.REPORT_STATUS)
    ) {
      const {
        columnFilters,
        sorting,
        tablePaginationState,
        tableScrollOffset,
        tableSelectedRowIndex,
        tableSwitchCurrentState,
        assetId,
      } = previousPageData
      const newTableState = { ...table.getState() }
      if (columnFilters) {
        newTableState.columnFilters = columnFilters
      }

      if (sorting && setSorting) {
        setSorting(sorting)
      }

      table.setState(newTableState)
      if (tableSwitchCurrentState !== undefined) {
        previousPageDataDispatch(
          setModuleHeaderActionPanelData({
            tableStateChangeSwitchCurrentState: tableSwitchCurrentState,
          })
        )
      }
      if (tablePaginationState) {
        setTimeout(
          (tablePaginationState) => {
            table.setPageIndex(tablePaginationState.pageIndex)
          },
          500,
          tablePaginationState
        )
      }
      if (tableScrollOffset !== undefined && tableScrollOffset !== null) {
        setTimeout(
          (tableScrollOffset) => {
            table.refs.tableContainerRef.current?.scrollTo({ top: tableScrollOffset })
          },
          500,
          tableScrollOffset
        )
      }

      if (assetId !== undefined && tableSelectedRowIndex !== undefined) {
        setTimeout(
          (assetId) => {
            const rowToSelect = table.getSortedRowModel().rows.find((item) => {
              if ('assetID' in item.original) {
                return item.original.assetID === assetId
              }
              return item.original.id === assetId
            })
            const nextRowToSelect = table.getSortedRowModel().rows.find((item) => item.index > tableSelectedRowIndex)
            if (setCurrentSelectedRow) {
              if (rowToSelect) {
                if (rowToSelect.original.isHandled || rowToSelect.original.isClosed) {
                  setCurrentSelectedRow(nextRowToSelect?.original)
                } else {
                  setCurrentSelectedRow(rowToSelect.original)
                }
              } else {
                setCurrentSelectedRow(nextRowToSelect?.original)
              }
            }
          },
          750,
          assetId
        )
      }

      if (previousPageData.fromModule === ROUTES.REPORT_STATUS) {
        setTimeout(() => {
          previousPageDataDispatch(deleteRedirectData(sid as string))
        }, 1500)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedRows, sid, previousPageData, table])

  useDeepCompareEffect(() => {
    const tableContainer = table.refs.tableContainerRef.current
    if (tableContainer && table.getSortedRowModel().rows.length > 0) {
      const firstRow = tableContainer.querySelector('tbody tr:first-of-type')
      if (firstRow) {
        if (sortedRows.length > 0 && !previousPageData?.tableSelectedRowIndex) {
          const rowElement = firstRow as HTMLTableRowElement
          setCurrentSelectedRow && setCurrentSelectedRow(sortedRows[0].original)
          rowElement.focus()
        } else if (sortedRows.length > 0 && previousPageData?.tableSelectedRowIndex) {
          const rowElement = tableContainer.querySelector(
            `tbody tr:nth-of-type(${previousPageData.tableSelectedRowIndex})`
          ) as HTMLTableRowElement
          if (rowElement) {
            rowElement.focus()
          }
        }
      }
    }
  }, [contextData])
}

export default usePreviousData
