//================ API RESPONSE TYPES========================
export enum DeviceModelEnum {
  IMxSixteenPlus = 'IMxSixteenPlus',
  IMxSixteenW = 'IMxSixteenW',
  IMxEight = 'IMxEight',
  IMxS = 'IMxS',
  IMxW = 'IMxW',
  IMxP = 'IMxP',
  IMxT = 'IMxT',
  IMxR = 'IMxR',
  IMxKnorr = 'IMxKnorr',
  IMxC = 'IMxC',
  IMxB = 'IMxB',
  IMxRail = 'IMxRail',
}

export enum DeviceStatusEnum {
  Normal = 'normal',
  NotNormal = 'notNormal',
}

export enum DeviceFaultEnum {
  Buffer = 'buffer',
  CableFault = 'cable_fault',
  NoMeasurement24h = 'no_measurement_24h',
  InternalTemperature = 'internal_temperature',
  Invalid = 'invalid',
  ChannelFault = 'channel_fault',
  BrokenConnection = 'broken_connection',
}

export enum ChannelStatusEnum {
  Normal = 'normal',
  NotNormal = 'notNormal',
  ChannelNotConnected = 'channelNotConnected',
  NoSensorData = 'noSensorData',
}

export enum ChannelTypeEnum {
  Digital = 'digital',
  Analog = 'analog',
}

export enum ChannelFaultEnum {
  CableFault = 'cable_fault',
  NoMeasurement24h = 'no_measurement_24h',
  NoMeasurementEver = 'no_measurement_ever',
  BrokenConnection = 'broken_connection',
  Invalid = 'invalid',
}

type ChannelFaultType = {
  fault: ChannelFaultEnum
}

type DeviceFaultType = {
  fault: DeviceFaultEnum
}

type SensorInterface = {
  sensorNumber: number
  sensorName: string
  type: ChannelTypeEnum
  status: ChannelStatusEnum
  lastCommunicationDate?: string | undefined
  functionalLocationName?: string | undefined
  voltage?: number | undefined
  asset?:
    | {
        name: string
        id: string
      }
    | undefined
  faults: ChannelFaultType[]
}

export type ChannelInterface = {
  number: number
  name: string
  type: ChannelTypeEnum
  status: ChannelStatusEnum
  lastCommunicationTime?: string | undefined
  voltage?: number | undefined
  faults: ChannelFaultType[]
  uniquePlaces: UniquePlaces[]
}

type UniquePlaces = {
  functionalLocationName: string
  asset: {
    name: string
    ID: string
  }
}

type DeviceAttributeInterface = {
  unit: string
  value: string
  alarmHighLevel: string
  warningHighLevel: string
}

export type UniqueChannelsAssets = {
  name: string
  ID: string
}

export type DeviceInterface = {
  deviceNumber: number
  deviceID: string
  deviceName: string
  model: DeviceModelEnum
  status: DeviceStatusEnum
  firmwareVersion?: string | undefined
  lastCommunicationDate?: string | undefined
  internalTemp: DeviceAttributeInterface
  usedBufferObject: DeviceAttributeInterface
  faults: DeviceFaultType[]
  sensors: SensorInterface[]
  channels: ChannelInterface[]
  uniqueChannelsAssets: UniqueChannelsAssets[]
}

export type DevicesAndSensorsData = {
  devices: DeviceInterface[]
}

export type DevicesAndSensorsSelectedItemsData = {
  device?: DeviceInterface
  channel?: ChannelInterface
  channelIndex?: number
}

export type DeviceModel = 'IMX-8' | 'IMX-16' | 'invalid'
