export type DateFilter = {
  dateFilterType: string
  endDate: Date | undefined
  startDate: Date | undefined
}

const analysisBacklogCSVFilterTypes = [
  'assetName',
  'analysisReason',
  'status',
  'belongsTo',
  'collectedDate',
  'reportedDate',
]

export { analysisBacklogCSVFilterTypes }
