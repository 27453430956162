import { ROUTES } from '@/shared/constants'
import { openToast } from '@/store/genericToast/actions'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { useNavigate } from 'react-router'

/**
 * Custom hook that provides a function to redirect to a specified module with a selected customer.
 *
 * @returns {Function} A function that redirects to a specified module with a selected customer.
 *
 * @example
 * const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer();
 * redirectToModuleWithSelectedCustomer(ROUTES.MODULE, 'customer-id', { someState: 'value' });
 *
 * @remarks
 * This hook uses the `useTypedSelector` to access the `customersList` from the state,
 * `useAppDispatch` to dispatch actions, and `useNavigate` from `react-router` to navigate.
 *
 * The returned function takes three parameters:
 * - `to` (ROUTES): The route to navigate to.
 * - `sid` (string): The ID of the customer to select.
 * - `state` (Record<string, unknown>, optional): Additional state to pass to the navigation.
 *
 * If the customer with the specified ID is not found in the `customersList`, a toast notification
 * is displayed with an error message, and the redirect is canceled.
 *
 * If the customer is found, the customer is set as the selected customer, and the navigation
 * to the specified route with the customer ID is performed.
 */
const useRedirectToModuleWithSelectedCustomer = () => {
  const customersList = useTypedSelector((state) => state.customersList)
  const overviewCellDispatch = useAppDispatch()
  const navigate = useNavigate()

  const redirectToModuleWithSelectedCustomer = (to: ROUTES, sid: string) => {
    const customer = customersList.find((c) => c.id === sid)
    if (!customer) {
      overviewCellDispatch(
        openToast({
          children: `Can not find customer ${sid}, redirect is canceled`,
          feSeverity: 'error',
        })
      )
      return
    }
    overviewCellDispatch(setSelectedCustomer(customer))
    navigate(`${to}/${sid}`)
  }
  return redirectToModuleWithSelectedCustomer
}

export default useRedirectToModuleWithSelectedCustomer
