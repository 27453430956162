import { DateFormats, formatDate } from '@/shared/dateUtils'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface DateCellProps {
  date: string | null
}

const DateCell: FC<DateCellProps> = ({ date }) => {
  return (
    <Flexbox feAlignItems="center" feJustifyContent="center">
      {date ? formatDate(date, DateFormats.AmericanDateFormat) : '-'}
    </Flexbox>
  )
}

export default DateCell
