import useWindowDimensions from '@/shared/hooks/useWindowDimensions'
import { useMemo } from 'react'

const useGetHeaderCharactersLength = () => {
  const { width } = useWindowDimensions()

  const characterLengths = useMemo(() => {
    if (width <= 1200) {
      return {
        lastCommunicationDate: 20,
        assetName: 10,
      }
    } else if (width <= 1440) {
      return {
        lastCommunicationDate: 20,
        assetName: 16,
      }
    } else if (width <= 1680) {
      return {
        lastCommunicationDate: 30,
        assetName: 17,
      }
    } else if (width <= 1800) {
      return {
        lastCommunicationDate: 30,
        assetName: 17,
      }
    } else {
      return {
        lastCommunicationDate: 30,
        assetName: 17,
      }
    }
  }, [width])

  return characterLengths
}
export default useGetHeaderCharactersLength
