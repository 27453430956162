import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { UnresolvedRecommendationData } from '@/models/unresolvedRecommendations/types'

import { createContext, useMemo, useState } from 'react'

interface IUnresolvedRecommendationsContext {
  unresolvedRecommendations: UnresolvedRecommendationData
  setUnresolvedRecommendations: React.Dispatch<
    React.SetStateAction<IUnresolvedRecommendationsContext['unresolvedRecommendations']>
  >
  tableScrollPosition: number
  setTableScrollPosition: React.Dispatch<React.SetStateAction<IUnresolvedRecommendationsContext['tableScrollPosition']>>
}

export const UnresolvedRecommendationsContext = createContext<IUnresolvedRecommendationsContext | null>(null)

export const useUnresolvedRecommendationsContext = () =>
  useContextWrapper(UnresolvedRecommendationsContext, {
    contextName: useUnresolvedRecommendationsContext.name,
    providerName: UnresolvedRecommendationsContextProvider.name,
  })

export const UnresolvedRecommendationsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [unresolvedRecommendations, setUnresolvedRecommendations] = useState<
    IUnresolvedRecommendationsContext['unresolvedRecommendations']
  >({
    open: [],
    closed: [],
  })
  const [tableScrollPosition, setTableScrollPosition] =
    useState<IUnresolvedRecommendationsContext['tableScrollPosition']>(0)

  const value = useMemo(
    () => ({
      unresolvedRecommendations,
      setUnresolvedRecommendations,
      tableScrollPosition,
      setTableScrollPosition,
    }),
    [unresolvedRecommendations, tableScrollPosition]
  )

  return <UnresolvedRecommendationsContext.Provider value={value}>{children}</UnresolvedRecommendationsContext.Provider>
}
