import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useUnresolvedRecommendationsContext } from '@/contexts/unresolvedRecommendations'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import { exportUnresolvedRecommendationsDataToCSV, mapCSVFaultTypeRaData } from '@/modules/openRecommendations/utils'
import ModuleHeader from '@/shared/components/ModuleHeader'
import useResetTableFilters from '@/shared/components/TableComponents/hooks/useResetTableFilters'
import { getCSVFilename } from '@/shared/csvUtils'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderConfigData,
  setModuleHeaderCSVData,
} from '@/store/moduleHeader/actions'
import { currentCustomerSelector } from '@/store/selectors/selectedCustomerSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { MRT_Row, MRT_TableInstance } from 'material-react-table'
import { FC } from 'react'

interface OpenRecommendationsHeaderProps {
  table: MRT_TableInstance<RecommendedAction>
}
const OpenRecommendationsHeader: FC<OpenRecommendationsHeaderProps> = ({ table }) => {
  const unresolvedRecommendationsDispatch = useAppDispatch()
  const reportFaultTypes = useTypedSelector((state) => state.reportFaultTypes)

  const selectedCustomer = useTypedSelector((state) => currentCustomerSelector(state))
  const { fetchDataStatus } = useFetchDataContext()

  const { unresolvedRecommendations } = useUnresolvedRecommendationsContext()

  const { columnFilters, sorting, pagination, showSkeletons } = table.getState()

  const tableRows = useDeepCompareMemo(
    () => table.getSortedRowModel().rows,
    [unresolvedRecommendations, sorting, pagination, columnFilters, fetchDataStatus, showSkeletons]
  )

  const inLoadingDataMode = useDeepCompareMemo(
    () => tableRows.every((row) => row.original.assetName === null),
    [tableRows]
  )

  const isTableFiltered = useDeepCompareMemo(
    () => columnFilters.length > 0 || sorting.length > 0,
    [columnFilters, sorting]
  )

  const resetTableFilters = useResetTableFilters<RecommendedAction>(table)

  useDeepCompareEffect(() => {
    unresolvedRecommendationsDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        showInfoPanel: false,
        showActionPanel: true,
        showSubtitle: false,
        title: 'Open Recommendations',
      })
    )

    unresolvedRecommendationsDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withClearFiltersButton: true,
        isClearFiltersButtonDisabled: !isTableFiltered,
        clearFiltersButtonFn: resetTableFilters,
        withTableStateChangeSwitch: true,
        tableStateChangeSwitchLabel: 'Show closed',
        withRefreshButton: true,
        withCSVDownloadButton: true,
        isWithUserEvents: true,
      })
    )
  }, [table, isTableFiltered, fetchDataStatus])

  useDeepCompareEffect(() => {
    if (!inLoadingDataMode && selectedCustomer) {
      const filteredRows = mapCSVFaultTypeRaData(
        table.getSortedRowModel().rows as MRT_Row<RecommendedAction>[],
        reportFaultTypes
      ).map((row) => row.original)
      unresolvedRecommendationsDispatch(
        setModuleHeaderCSVData({
          csvData: exportUnresolvedRecommendationsDataToCSV(filteredRows, selectedCustomer.name, columnFilters),
          moduleName: getCSVFilename('Open Recommendations'),
        })
      )
    }
  }, [tableRows, selectedCustomer, columnFilters])

  return <ModuleHeader />
}

export default OpenRecommendationsHeader
