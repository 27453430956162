import { StatusCode } from '@/api/constants'
import { DarwinJWT, PathIdentifiers } from '@/api/types'
import { initializeMsal } from '@/modules/azure-auth/initializeMsal'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { ENV } from '@/shared/constants'
import { escapeRequestDataString, isObject, isString } from '@/shared/utils'
import { SilentRequest } from '@azure/msal-browser'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { jwtDecode } from 'jwt-decode'
import DOMPurify from 'dompurify'

/**
 * Retrieves the base client URL based on the provided path identifier in the URL.
 *
 * This function determines the appropriate base URL for different services based on the path
 * identifier present in the given URL. If the URL matches a specific path identifier, the corresponding
 * environment variable is used to construct the base URL.
 *
 * @param {string | undefined} url - The URL containing the path identifier to determine the base client URL.
 * @returns {string | undefined} - The base client URL corresponding to the path identifier in the URL, or `undefined` if no match is found.
 * @throws {axios.Cancel} - Throws an error if the URL is undefined.
 */
//DO NOT CHANGE THE ORDER OF CONDITIONS LOGIC BELOW!!!!!
const getBaseClientUrl = (url: string | undefined): string | undefined => {
  let result = undefined

  if (url) {
    if (import.meta.env.MODE === ENV.DEV) {
      result = 'http://localhost:6767/api/v1/'
    } else if (url.includes(PathIdentifiers.OverallDashboard)) {
      result = import.meta.env.VITE_DARWIN_OVERVIEW_BASE_URL
    }
    // } else if (url.includes(PathIdentifiers.MockCustomerNote)) {
    //   result = import.meta.env.VITE_DARWIN_MOCK_CUSTOMER_NOTE_SERVICE_BASE_URL
    // } else if (url.includes(PathIdentifiers.CustomerNotes) || url.includes(PathIdentifiers.Reporting)) {
    //   result = import.meta.env.VITE_DARWIN_STATUS_REPORTING_BASE_URL
    else if (url.includes(PathIdentifiers.Reporting)) {
      result = import.meta.env.VITE_DARWIN_STATUS_REPORTING_BASE_URL
    } else if (url.includes(PathIdentifiers.Dashboards)) {
      result = `${import.meta.env.VITE_DASHBOARD_RESOURCE_BASE_URL}`
    } else if (
      url.includes(PathIdentifiers.AssetHealthCondition) ||
      url.includes(PathIdentifiers.RecommendedAction) ||
      url.includes(PathIdentifiers.DueDatesStatus) ||
      url.includes(PathIdentifiers.Reports) ||
      url.includes(PathIdentifiers.OpenFaults)
    ) {
      result = `${import.meta.env.VITE_DARWIN_ANALYTICS_SERVICE_BASE_URL}`
    } else if (url.includes(PathIdentifiers.DevicesAndSensors)) {
      result = import.meta.env.VITE_DARWIN_DEVICES_AND_SENSORS_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.AnalysisBacklog)) {
      result = import.meta.env.VITE_DARWIN_ANALYSIS_BACKLOG_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.Documents)) {
      result = import.meta.env.VITE_DARWIN_THINGS_SERVICE_BASE_URL
    } else if (url.includes(PathIdentifiers.Sites)) {
      result = import.meta.env.VITE_DARWIN_SITE_LIST_BASE_URL
    }
  } else {
    throw new axios.Cancel('Unknown base client found')
  }
  return result
}

/**
 * Handles the successful API response by returning the response object.
 *
 * @param {AxiosResponse<Record<string, unknown>>} response - The response object from the Axios request.
 * @returns {Promise<AxiosResponse<Record<string, unknown>>>} A promise that resolves to the response object.
 */
const apiSuccessResponseMapping = async (response: AxiosResponse<Record<string, unknown>>) => {
  return response
}

/**
 * Maps an Axios error to a specific status code if certain conditions are met.
 *
 * This function checks if the provided Axios error object has a status or response status.
 * If neither is present and the error code indicates a network error, it assigns a specific
 * status code to the error.
 *
 * @param {AxiosError} error - The Axios error object to be mapped.
 * @throws {AxiosError} - Throws the modified or original Axios error.
 */
const apiErrorResponseMapping = (error: AxiosError) => {
  if ((!error.status && !error.response) || (!error.status && error.response && !error.response.status)) {
    if (error.code === 'ERR_NETWORK') {
      error.status = StatusCode.TimeoutGateway
    }
  }
  throw error
}
/**
 * Retrieves the username from the ID token.
 *
 * This function asynchronously fetches the ID token and decodes it to extract the username (email).
 * If the ID token is not available, it throws a `DarwinError` with a status of `BadToken`.
 *
 * @async
 * @function getUsername
 * @returns {Promise<string | null>} A promise that resolves to the username (email) if the ID token is valid, or `null` if not.
 * @throws {DarwinError} Throws an error if no ID token is provided.
 */
const getUsername = async () => {
  const idToken = await getIdToken()
  let userName = null

  if (idToken) {
    userName = jwtDecode<DarwinJWT>(idToken).email
  } else {
    throw new DarwinError({
      status: StatusCode.BadToken,
      message: 'No Id token was provided',
      name: ' Bad Id Token',
      isAxiosError: false,
      toJSON: () => ({}),
    })
  }
  return userName
}

/**
 * Retrieves the ID token for the currently active account using MSAL (Microsoft Authentication Library).
 *
 * @async
 * @function getIdToken
 * @returns {Promise<string | null>} A promise that resolves to the ID token if the account is active, or null if no active account is found.
 * @throws {DarwinError} Throws a DarwinError if there is an error acquiring the token silently.
 *
 * @example
 * // Example usage:
 * getIdToken().then((idToken) => {
 *   if (idToken) {
 *     console.log('ID Token:', idToken);
 *   } else {
 *     console.log('No active account found.');
 *   }
 * }).catch((error) => {
 *   console.error('Error acquiring ID token:', error);
 * });
 */
const getIdToken = async () => {
  const msalInstance = await initializeMsal()
  const account = msalInstance.getActiveAccount() || undefined

  if (account) {
    const request: SilentRequest = {
      scopes: import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(','),
      account,
    }

    const idToken = await msalInstance
      .acquireTokenSilent(request)
      .then((response) => {
        return response.idToken
      })
      .catch((error) => {
        throw new DarwinError(error)
      })

    return idToken
  }

  return null
}

/**
 * Retrieves the authentication token using MSAL (Microsoft Authentication Library).
 *
 * This function initializes the MSAL instance and attempts to acquire an access token
 * silently for the active account. If no active account is found, it returns null.
 *
 * @returns {Promise<string | null>} A promise that resolves to the access token if successful, or null if no active account is found.
 *
 * @throws {DarwinError} Throws a DarwinError if there is an error during the token acquisition process.
 */
const getAuthToken = async () => {
  const msalInstance = await initializeMsal()
  const account = msalInstance.getActiveAccount() || undefined

  if (account) {
    const request: SilentRequest = {
      scopes: import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(','),
      account,
    }

    const accessToken = await msalInstance
      .acquireTokenSilent(request)
      .then((response) => {
        return response.accessToken
      })
      .catch((error) => {
        throw new DarwinError(error)
      })

    return accessToken
  }

  return null
}

/**
 * Transforms the input data by sanitizing any string values to prevent security vulnerabilities such as XSS attacks.
 * It recursively traverses the data structure, sanitizing strings and preserving the structure of arrays and objects.
 *
 * @param {unknown} data - The input data to be sanitized. It can be of any type.
 * @returns {unknown} - The sanitized data with all string values escaped and sanitized.
 *
 * @example
 * // Sanitize a simple string
 * const sanitizedData = securityRequestTransform("some <script>alert('XSS')</script> text");
 * // sanitizedData will be "some &lt;script&gt;alert('XSS')&lt;/script&gt; text"
 *
 * @example
 * // Sanitize an object with nested structures
 * const inputData = {
 *   name: "John <script>alert('XSS')</script> Doe",
 *   tags: ["<img src='x' onerror='alert(1)'>", "safeTag"],
 *   details: {
 *     bio: "Hello <b>world</b>",
 *     age: 30
 *   }
 * };
 * const sanitizedData = securityRequestTransform(inputData);
 * // sanitizedData will have all strings sanitized and the structure preserved
 */
const securityRequestTransform = (data: unknown) => {
  if (data) {
    const deepSearch = (value: unknown): unknown => {
      if (isString(value)) {
        return escapeRequestDataString(DOMPurify.sanitize(value as string))
      } else if (Array.isArray(value)) {
        return value.map((item: unknown) => deepSearch(item))
      } else if (isObject(value)) {
        const result: Record<string, unknown> = {}
        for (const key in value as Record<string, unknown>) {
          result[key] = deepSearch((value as Record<string, unknown>)[key])
        }
        return result
      }
      return value
    }
    data = deepSearch(data)
  }
  return data
}

export {
  getUsername,
  getIdToken,
  getBaseClientUrl,
  apiSuccessResponseMapping,
  apiErrorResponseMapping,
  getAuthToken,
  securityRequestTransform,
}
