import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import { AssetHealthStatusSelectType, AssetHealthStatusType } from '@/models/dashboard/globalFiltering/types'
import {
  AssetReportFilters,
  CriticalityOptionValue,
  ReportTypeString,
  StatusOptionValue,
} from '@/models/reportGenerator/types'
import ReportSelect from '@/modules/report-generator/components/ReportSelect'
import ReportTypeSection from '@/modules/report-generator/components/reportFilters/ReportTypeSection'
import {
  StyledAssetStatusSelectWrap,
  StyledFlexItem,
  StyledIconContainer,
  StyledRegenerateButton,
  StyledReportDateWrap,
  StyledReportDetailsSection,
  StyledReportFiltersContainer,
  StyledReportTypeHeading,
  StyledReportTypeSection,
  StyledSelectFiltersSection,
  StyledSelectSection,
  StyledTotalAssets,
} from '@/modules/report-generator/styled'
import {
  getCriticality,
  mapCriticalityOption,
  mapToSelectItems,
  updateReportType,
} from '@/modules/report-generator/utils'
import {
  assetTypeOptions,
  allAssetTypes,
  criticalityOptions,
  statusOptions,
} from '@/modules/report-generator/utils/constants'
import { addToDate, epochDate, parseDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import { AssetCriticality, AssetStatusString } from '@/shared/models/types'
import { getAssetStatuses, mapAssetStatusesOption, mapIconPropsToAssetStatus } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { DatePicker, Flexbox, Icon } from '@skf-internal/ui-components-react'
import { FC, useCallback } from 'react'

const ReportFilters: FC = () => {
  const { assetReportFilters, setAssetReportFilters, showPreview, setShowPreview } = useReportGeneratorContext()
  const { fetchDataFn: generateReports } = useFetchDataContext()

  const handleAssetStatusSelect = useCallback(
    (assetStatuses: AssetReportFilters['assetStatuses']) => {
      setAssetReportFilters((prevState) => ({ ...prevState, assetStatuses }))
    },
    [setAssetReportFilters]
  )

  const handleAssetCriticalitySelect = useCallback(
    (assetCriticalities: AssetReportFilters['assetCriticalities']) => {
      setAssetReportFilters((prevState) => ({ ...prevState, assetCriticalities }))
    },
    [setAssetReportFilters]
  )

  const handleAssetTypeSelect = useCallback(
    (assetTypes: AssetReportFilters['assetTypes']) => {
      if (assetTypes.includes('all') || assetTypes.length === 0) {
        assetTypes = allAssetTypes
      }
      setAssetReportFilters((prevState) => ({ ...prevState, assetTypes }))
    },
    [setAssetReportFilters]
  )

  const handleReportDateSelect = useCallback(
    (reportDate: Date) => {
      setAssetReportFilters((prevState) => ({ ...prevState, reportDate }))
    },
    [setAssetReportFilters]
  )

  const handleReportType = useDeepCompareCallback(
    (status: ReportTypeString, checked: boolean) => {
      updateReportType(setAssetReportFilters, status, checked)
    },
    [setAssetReportFilters]
  )

  const handleRegenerateClick = async () => {
    setShowPreview(false)
    generateReports()
  }

  return (
    <StyledReportFiltersContainer data-testid={dataTestId.generateReportFilters}>
      <StyledSelectSection>
        <StyledSelectFiltersSection>
          <StyledAssetStatusSelectWrap>
            <ReportSelect<StatusOptionValue, AssetHealthStatusSelectType>
              multiple={false}
              value={assetReportFilters.assetStatuses as AssetHealthStatusType[]}
              transformPassedValueFn={(value) => mapAssetStatusesOption(value as AssetHealthStatusType[])}
              onChange={handleAssetStatusSelect}
              transformSelectedValueFn={(selectedValue) => getAssetStatuses(selectedValue)}
              items={mapToSelectItems(statusOptions)}
              label="Asset status to include"
              data-testid={dataTestId.generateReportFiltersAssetStatusSelect}
            />
          </StyledAssetStatusSelectWrap>
          <StyledFlexItem>
            <ReportSelect<CriticalityOptionValue, AssetCriticality>
              multiple={false}
              value={assetReportFilters.assetCriticalities}
              transformPassedValueFn={(value) => mapCriticalityOption(value)}
              onChange={handleAssetCriticalitySelect}
              transformSelectedValueFn={(selectedValue) => getCriticality(selectedValue)}
              items={mapToSelectItems(criticalityOptions)}
              label="Asset criticality"
              data-testid={dataTestId.generateReportFiltersAssetCriticalitySelect}
            />
          </StyledFlexItem>
          <StyledFlexItem>
            {/* Need to fix below types part in Report status integration  */}
            {/* <ReportSelect<string, string> */}
            <ReportSelect
              multiple={true}
              value={assetReportFilters.assetTypes}
              onChange={handleAssetTypeSelect}
              items={mapToSelectItems(assetTypeOptions)}
              label="Asset Type"
              allValues={allAssetTypes}
              data-testid={dataTestId.generateReportFiltersAssetTypeSelect}
            />
          </StyledFlexItem>
          <StyledReportDateWrap>
            <DatePicker
              feSize={'sm'}
              feLabel="Report date"
              onChange={(date) => handleReportDateSelect(date as Date)}
              value={assetReportFilters.reportDate}
              feMinDate={epochDate().toDate()}
              feMaxDate={parseDate(addToDate(new Date(), 0, 'years'))}
              placeholder={'Select'}
              allowClear={false}
            />
          </StyledReportDateWrap>
        </StyledSelectFiltersSection>
        <StyledReportDetailsSection>
          <StyledTotalAssets data-testid={dataTestId.generateReportFiltersTotalAssetIncluded}>
            Total Assets Included: 0
          </StyledTotalAssets>
          <StyledIconContainer data-testid={dataTestId.generateReportFiltersAssetStatusIcons}>
            {assetReportFilters.assetStatuses.map((iconStatus) => {
              const { feIcon, feColor } = mapIconPropsToAssetStatus(iconStatus as AssetStatusString)
              return <Icon feIcon={feIcon} feColor={feColor} feSize="sm" />
            })}
          </StyledIconContainer>
        </StyledReportDetailsSection>
      </StyledSelectSection>
      <StyledReportTypeSection>
        <StyledReportTypeHeading feFlexDirection="row">Charts to include</StyledReportTypeHeading>
        <Flexbox feGap="lg">
          <ReportTypeSection
            reportTypeStatus={assetReportFilters.reportTypes}
            onChange={handleReportType}
            data-testid={dataTestId.generateReportFiltersReportTypeSection}
          />
          <StyledRegenerateButton
            data-testid={dataTestId.generateReportRegenerateButton}
            disabled={!showPreview}
            onClick={handleRegenerateClick}
          >
            Regenerate
          </StyledRegenerateButton>
        </Flexbox>
      </StyledReportTypeSection>
    </StyledReportFiltersContainer>
  )
}

export default ReportFilters
