import { postConditionalStatuses } from '@/api/paths/analytics/conditionStatuses'
import { DarwinWidgetError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useDashboardContext } from '@/contexts/dashboard'
import { ConditionStatusWidgetDataType } from '@/models/widgets/asset-health-condition/types'
import { SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'
import { generateLast12MonthsData } from '@/modules/dashboard/utils'
import { useParams } from 'react-router'
import { WidgetType } from '@/models/dashboard/types'

const useWidgetConditionalStatuses = (globalFilter: SelectFilterType) => {
  const { sid: companyId } = useParams()
  const { setWidgetAssetHealthConditionData } = useDashboardContext()
  return useApi(async () => {
    return await postConditionalStatuses(
      globalFilter,
      {
        onSuccess: async (result) => {
          let assetHealthConditionDataWidget = result as unknown as ConditionStatusWidgetDataType
          if (assetHealthConditionDataWidget.length === 0) {
            assetHealthConditionDataWidget = generateLast12MonthsData()
          }
          setWidgetAssetHealthConditionData(assetHealthConditionDataWidget)
        },
        onError: async (error: AxiosError) => {
          return Promise.reject(new DarwinWidgetError(error, WidgetType.ASSET_HEALTH_CONDITION))
        },
      },
      { companyId }
    )
  })
}

export default useWidgetConditionalStatuses
