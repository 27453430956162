import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { convertToDisplayValue } from '@/shared/utils'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface ObservationProps {
  observation: string
}

const Observation: FC<ObservationProps> = ({ observation }) => {
  return (
    <Flexbox>
      <TextEllipsis
        type={TextEllipsisType.MultiLine}
        rowsUntilEllipsis={3}
        value={convertToDisplayValue(observation)}
      />
    </Flexbox>
  )
}

export default Observation
