import { FC } from 'react'
import { ConditionStatusWidgetDataType, Severity } from '@/models/widgets/asset-health-condition/types'
import { tableCellFormatter, truncateValue } from '@/modules/dashboard/utils'
import { severityToIconColor, severityToIconName } from '@/modules/dashboard/utils/asset-health-condition'
import { dataTestId } from '@/tests/testid'
import { Icon } from '@skf-internal/ui-components-react'
import {
  StyledAHCTableCell,
  StyledAHCTableHeader,
} from '@/modules/dashboard/components/widgets/asset-health-condition/styled'

interface AHCTableRowProps {
  severity: string
  slices: ConditionStatusWidgetDataType
  showPercentages: boolean
}

const AHCTableRow: FC<AHCTableRowProps> = ({ severity, slices, showPercentages }) => {
  return (
    <tr key={severity} data-testid={dataTestId.assetHealthConditionTrendViewTableRow}>
      <StyledAHCTableHeader>
        <Icon
          feIcon={severityToIconName(severity as Severity)}
          feColor={severityToIconColor(severity as Severity)}
          feSize="sm"
        />
      </StyledAHCTableHeader>
      {slices.map(({ period, data }) => (
        <StyledAHCTableCell
          role="gridcell"
          key={`${period.year}-${period.month}-${severity}`}
          isEmpty={Object.keys(data).length === 0}
          isNonNegative={data[severity] >= 0}
          severity={severity as Severity}
        >
          {data[severity] >= 0 ? truncateValue(tableCellFormatter(data[severity], 1, showPercentages)) : 'NA'}
        </StyledAHCTableCell>
      ))}
    </tr>
  )
}

export default AHCTableRow
