import { getAnalyticsBacklogAssetsNew } from '@/api/paths/analysis'
import { GetAnalysisBacklogAssetsResponseNew } from '@/api/paths/analysis/types'
import {
  // getCustomerNotes,
  // getRecommendationTypes,
  getReportFaultTypes,
  getReportsHistory,
} from '@/api/paths/reportStatus'
import {
  // GetRecommendationTypesResponse,
  GetReportFaultTypesResponse,
  GetReportHistoryResponse,
} from '@/api/paths/reportStatus/types'
import { ApplicationInitialData, ReportStatusLoaderData } from '@/app/routes/types'
import { ResponseAssets } from '@/models/analysisBacklog/types'
import { Customer } from '@/models/customer/types'
import { Site } from '@/models/overview/types'
import { FaultsAndHistory } from '@/models/reportStatus/types'
import { mapAssetFaultsAndHistoryResponse, mapFaultTypes } from '@/modules/report-status/utils'
import { ERR_CANCELED, ROUTES } from '@/shared/constants'
import { STORAGE_ERROR_COUNT, STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getRouteFromPathname, getSidFromPathname } from '@/shared/utils'
import { setSelectedCustomer } from '@/store/selectedCustomer/actions'
import { store } from '@/store/store'
import { AxiosError } from 'axios'
import { getCustomers } from '@/api/paths/customers'
import { DarwinError, DarwinErrorDialog } from '@/shared/components/ErrorBoundary/DarwinError'
import { getAssetRow } from '@/modules/analysisBacklog/utils'

/**
 * Fetches and returns report status loader data for a given asset.
 *
 * @param {string | undefined} assetId - The ID of the asset for which to fetch the report status loader data.
 * @returns {Promise<Partial<ReportStatusLoaderData>>} A promise that resolves to a partial object containing the report status loader data.
 * @throws {DarwinErrorDialog} Throws an error dialog if any of the data fetching promises are rejected (excluding cancellations).
 *
 * The function performs the following steps:
 * 1. Retrieves the selected customer and stored customer ID.
 * 2. Executes multiple asynchronous data fetching functions in parallel.
 * 3. Waits for all data fetching promises to settle.
 * 4. Filters out any rejected promises that are not due to cancellations.
 * 5. If there are any errors, throws a DarwinErrorDialog with the first error reason.
 * 6. If all promises are fulfilled, extracts the resolved values and constructs the result object.
 * 7. Retrieves the asset row based on the fetched assets and asset ID.
 * 8. Clears the error count from session storage if it equals 1.
 */
const getReportStatusLoaderData = async (assetId: string | undefined): Promise<Partial<ReportStatusLoaderData>> => {
  const result: Partial<ReportStatusLoaderData> = {}
  const selectedCustomer = getSelectedCustomer()
  const storedCustomerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))

  const dataToExecute = [
    getAnalyticsBacklogData(
      selectedCustomer && Object.keys(selectedCustomer).length > 0 ? selectedCustomer.id : storedCustomerId
    ),
    getAssetFaultsAndHistory(assetId, storedCustomerId),
    // getReportCustomerNotes(assetId, storedCustomerId),
    // getReportFaultTypesData(),
  ]

  const promises = await Promise.allSettled(dataToExecute)

  const [assets, assetFaultsAndHistory /*customerNotes*/ /*reportFaultTypes*/] = promises

  const errors = promises.filter(
    (promise): promise is PromiseRejectedResult => promise.status === 'rejected' && promise.reason.code !== ERR_CANCELED
  )

  if (errors.length > 0) {
    throw new DarwinErrorDialog(errors[0].reason)
  } else {
    result.assets = (assets as PromiseFulfilledResult<ResponseAssets>).value
    result.assetFaultsAndHistory = (assetFaultsAndHistory as PromiseFulfilledResult<FaultsAndHistory>).value
    // result.customerNotes = (customerNotes as PromiseFulfilledResult<HalCustomerNoteData[]>).value
    // result.reportFaultTypes = (reportFaultTypes as PromiseFulfilledResult<ReturnType<typeof mapFaultTypes>>).value
    result.assetRow = getAssetRow(result.assets, assetId)
    const errorCount = sessionStorage.getItem(STORAGE_ERROR_COUNT)
    if (errorCount && +errorCount === 1) {
      sessionStorage.removeItem(STORAGE_ERROR_COUNT)
    }
  }

  return result
}

/**
 * Fetches and processes the analytics backlog data for a given customer.
 *
 * @param {string} customerId - The ID of the customer for whom to fetch the analytics backlog data.
 * @returns {Promise<GetAnalysisBacklogAssetsResponse>} A promise that resolves to the processed analytics backlog data.
 * @throws {DarwinErrorDialog} Throws an error dialog if there is an error during the data fetching process.
 */
const getAnalyticsBacklogData = async (customerId: string) => {
  const analyticsBacklogDataResponse = (await getAnalyticsBacklogAssetsNew(
    {
      onError: async (error) => {
        return Promise.reject(new DarwinErrorDialog(error))
      },
    },
    { customerId }
  )) as GetAnalysisBacklogAssetsResponseNew
  // return mapAssetsData(analyticsBacklogDataResponse)
  return analyticsBacklogDataResponse
}

/**
 * Retrieves the asset faults and history for a given asset ID and session ID.
 *
 * @param {string | undefined} assetId - The ID of the asset to retrieve faults and history for. Can be undefined.
 * @param {string} sid - The session ID to use for the request.
 * @returns {Promise<GetReportHistoryResponse>} A promise that resolves to the mapped asset faults and history response.
 * @throws {DarwinErrorDialog} Throws an error dialog if the request fails.
 */
const getAssetFaultsAndHistory = async (assetId: string | undefined, sid: string) => {
  const assetFaultsAndHistoryResponse = (await getReportsHistory(
    {
      onError: async (error) => {
        return Promise.reject(new DarwinErrorDialog(error))
      },
    },
    { sid, assetId }
  )) as GetReportHistoryResponse
  return mapAssetFaultsAndHistoryResponse(assetFaultsAndHistoryResponse.data)
}

/**
 * Retrieves customer notes for a given asset and session ID.
 *
 * @param {string | undefined} assetId - The ID of the asset for which to retrieve customer notes. Can be undefined.
 * @param {string} sid - The session ID.
 * @returns {Promise<ResponseCustomerNotes>} A promise that resolves to the mapped customer notes.
 * @throws {DarwinErrorDialog} Throws an error dialog if the request fails.
 */
// const getReportCustomerNotes = async (assetId: string | undefined, sid: string) => {
//   const customerNotesResponse = (await getCustomerNotes(
//     {
//       onError: async (error: AxiosError) => {
//         return Promise.reject(new DarwinErrorDialog(error))
//       },
//     },
//     { sid, assetId, isOrphan: false }
//   )) as ResponseCustomerNotes
//   return mapCustomerNotes(customerNotesResponse)
// }

/**
 * Fetches report fault types data and maps it to the required format.
 *
 * This function makes an asynchronous call to retrieve report fault types data.
 * If an error occurs during the retrieval, it rejects the promise with a `DarwinErrorDialog`.
 * Upon successful retrieval, it maps the fault types data using the `mapFaultTypes` function.
 *
 * @returns {Promise<ReturnType<typeof mapFaultTypes>>} A promise that resolves to the mapped fault types data.
 *
 * @throws {DarwinErrorDialog} Throws an error dialog if the retrieval fails.
 */
const getReportFaultTypesData = async (params: string) => {
  const storedCustomerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))

  const customerId = storedCustomerId ? storedCustomerId : params

  const reportFaultTypesResponse = (await getReportFaultTypes(
    {
      onError: async (error) => {
        return Promise.reject(new DarwinErrorDialog(error))
      },
    },
    {
      customerId,
    }
  )) as GetReportFaultTypesResponse
  return mapFaultTypes(reportFaultTypesResponse)
}

/**
 * Retrieves the selected customer information based on the following criteria:
 *
 * 1. If the `selectedCustomer` is defined and not empty, it is returned.
 * 2. If `selectedCustomer` is empty and a stored customer exists in localStorage, it is returned.
 * 3. If neither `selectedCustomer` nor a stored customer is found, the first customer from `customersList` is returned.
 * 4. If `customersList` is empty, a default customer (presumably `customerListProd[0]`) is returned.
 *
 * @returns {Customer} The selected customer information.
 */
const getSelectedCustomer = (): Customer | undefined => {
  let result: Customer | undefined = undefined
  const { selectedCustomer, customersList } = store.getState()
  const pathCustomerId = getSidFromPathname(location.href)
  const storedCustomerId = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))
  result = customersList.find((customer) => customer.id === pathCustomerId)

  if (
    (!result || Object.keys(result).length === 0) &&
    getRouteFromPathname(location.pathname) === ROUTES.REPORT_STATUS
  ) {
    result = customersList.find((customer) => customer.id === storedCustomerId)
    store.dispatch(setSelectedCustomer(result || selectedCustomer))
  }

  return result || selectedCustomer
}

/**
 * Transforms the API response data into a sorted list of Customer objects.
 * Each customer object combines company and site information, and indicates
 * whether customer notes are enabled for the site.
 *
 * @param {SitesData} data - Array of Site objects containing company and site information
 * @returns {Customer[]} An array of Customer objects sorted alphabetically by name with the following properties:
 *   - id {string|number} - The unique site identifier
 *   - name {string} - Combined company and site name (format: "CompanyName - SiteName")
 *   - customerNotesEnabled {boolean} - Indicates if customer notes are enabled for this site
 */

const createCustomerList = (data: Site[]): Customer[] => {
  return data
    .map((item: Site) => ({
      id: item.siteId,
      name: `${item.companyName} - ${item.siteName}`,
      // customerNotesEnabled: siteIdsWithNotes.includes(item.siteId),
    }))
    .sort((a, b) => a.name.localeCompare(b.name))
}

/**
 * Fetches the application customers.
 *
 * @returns {Promise<any>} A promise that resolves with the customers data or rejects with a DarwinError.
 * @throws {DarwinError} Throws an error if the request fails.
 */
const getApplicationCustomers = async () => {
  return await getCustomers({
    onError: async (error: AxiosError) => {
      return Promise.reject(new DarwinError(error))
    },
  })
}

/**
 * Fetches the initial data required for the application.
 *
 * This function concurrently fetches the application customers and report fault types data.
 * It uses `Promise.allSettled` to handle the promises and filters out any errors that are not
 * related to cancellation. If any non-cancellation errors are found, it throws a `DarwinError`.
 *
 * @returns {Promise<{customersList: Customer[], reportFaultTypes: ReturnType<typeof mapFaultTypes>}>}
 *          An object containing the customers list and report fault types.
 *
 * @throws {DarwinError} If any of the promises are rejected with an error other than cancellation.
 */

const getApplicationInitialData = async (): Promise<ApplicationInitialData> => {
  try {
    const customersList = await getApplicationCustomers()

    if (!customersList || customersList.length === 0) {
      throw new Error('Customers list is empty or not retrieved.')
    }

    const firstCustomerId = customersList[0]?.id
    const reportFaultTypes = await getReportFaultTypesData(firstCustomerId)

    return { customersList, reportFaultTypes }
  } catch (error) {
    throw new DarwinError(error as AxiosError)
  }
}

export { getReportStatusLoaderData, createCustomerList, getApplicationInitialData }
