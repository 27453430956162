import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import { setModuleHeaderActionPanelData } from '@/store/moduleHeader/actions'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { MRT_RowData, MRT_TableInstance } from 'material-react-table'

const useResetTableFilters = <T extends MRT_RowData>(table: MRT_TableInstance<T>) => {
  const resetTableFiltersDispatch = useAppDispatch()

  const headerActionPanelConfig = useTypedSelector((state) => headerActionPanelConfigSelector(state))

  const resetTableFilters = useDeepCompareCallback(() => {
    table.resetColumnFilters()
    table.resetSorting()
    table.resetExpanded()
    resetTableFiltersDispatch(
      setModuleHeaderActionPanelData({
        ...headerActionPanelConfig,
        tableStateChangeSwitchCurrentState: false,
      })
    )
  }, [table, headerActionPanelConfig])

  return resetTableFilters
}

export default useResetTableFilters
