import PdfTemplate from '@/modules/report-generator/components/reports/pdftemplate'
import { useComponentToImage } from '@/modules/report-generator/hooks/useComponentToImage'
import { pdfStylesObj } from '@/modules/report-generator/utils/pdfStyles'
import { dataTestId } from '@/tests/testid'
import { Image, Styles, StyleSheet } from '@react-pdf/renderer'
import { FC, useEffect } from 'react'

const pdfStyles = StyleSheet.create(pdfStylesObj as Styles)

const InfoPagePDFPreview: FC = () => {
  const {
    imageUrl: InfoPageImage,
    generateImage,
    // isGenerating,
    // error,
  } = useComponentToImage(dataTestId.generateReportPDFPreviewInfoPage)

  useEffect(() => {
    generateImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <PdfTemplate>
      <Image
        src={InfoPageImage as string}
        style={pdfStyles.infoPagePreview}
        data-testid={dataTestId.generateReportInfoPageImage}
      />
    </PdfTemplate>
  )
}

export default InfoPagePDFPreview
