import { DeviceStatusEnum } from '@/models/devicesAndSensors/types'
import DeviceStatusCheckbox from '@/shared/components/TableComponents/GenericTableFilter/filterHelpComponents/DeviceStatusCheckbox'
import { FilterContentProps } from '@/shared/components/TableComponents/GenericTableFilter/types'
import { StyledDeviceStatusFilterRoot } from '@/shared/components/TableComponents/styled'
import { dataTestId } from '@/tests/testid'
import { FC, useCallback, useState } from 'react'

/**
 * A component for filtering devices based on their status.

 * @param {FilterContentProps<DeviceStatusEnum[]>} props - The component props.
 * @property {DeviceStatus[]} props.initialValue - The initial selected device statuses.
 * @property {(newFilterValue: DeviceStatusEnum[] | undefined) => void} props.onFilterValueChange - Callback function to handle filter value changes.
 * @returns {JSX.Element} The rendered DeviceStatusFilterContent component.
 */
const DeviceStatusFilterContent: FC<FilterContentProps<DeviceStatusEnum[]>> = ({
  initialValue,
  onFilterValueChange,
}) => {
  const [filteredStatus, setFilteredStatus] = useState<DeviceStatusEnum[]>(initialValue || [])

  const findStatusValue = useCallback(
    (value: DeviceStatusEnum): boolean => filteredStatus.includes(value),
    [filteredStatus]
  )

  const handleOnChange = useCallback(
    (status: DeviceStatusEnum, checked: boolean) => {
      let newFilterValue: DeviceStatusEnum[] | undefined = undefined

      if (checked) {
        newFilterValue = [...filteredStatus, status]
        setFilteredStatus(newFilterValue)
      } else {
        newFilterValue = filteredStatus.filter((v) => v !== status)
        setFilteredStatus(newFilterValue)
      }

      if (newFilterValue.length === 0) {
        newFilterValue = undefined
      }

      onFilterValueChange(newFilterValue)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredStatus]
  )

  return (
    <StyledDeviceStatusFilterRoot
      data-testid={dataTestId.deviceStatusFilterContent}
      feJustifyContent="center"
      feFlexDirection="column"
      feGap="md"
    >
      <DeviceStatusCheckbox
        status={DeviceStatusEnum.NotNormal}
        checked={findStatusValue(DeviceStatusEnum.NotNormal)}
        onChange={handleOnChange}
      />
      <DeviceStatusCheckbox
        status={DeviceStatusEnum.Normal}
        checked={findStatusValue(DeviceStatusEnum.Normal)}
        onChange={handleOnChange}
      />
    </StyledDeviceStatusFilterRoot>
  )
}

export default DeviceStatusFilterContent
