/* eslint-disable @typescript-eslint/ban-ts-comment */

import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import OpenRecommendationAssetName from '@/modules/openRecommendations/components/cells/assetName'
import OpenRecommendationAssetStatus from '@/modules/openRecommendations/components/cells/assetStatus'
import OpenRecommendationBelongsTo from '@/modules/openRecommendations/components/cells/belongsTo'
import OpenRecommendationDate from '@/modules/openRecommendations/components/cells/date'
import DaysInDue from '@/modules/openRecommendations/components/cells/daysInDue'
import OpenRecommendationsFaultType from '@/modules/openRecommendations/components/cells/faultType'
import Observation from '@/modules/openRecommendations/components/cells/observation'
import OpenRecommendedAction from '@/modules/openRecommendations/components/cells/recommendedAction'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { openRecommendationAssetStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'
import TableColumn from '@/shared/components/TableComponents/tableColumn'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useOpenRecommendationsColumns = () => {
  const columns = useMemo<MRT_ColumnDef<RecommendedAction>[]>(
    () => [
      {
        id: 'assetStatus',
        accessorKey: 'assetStatus',
        header: 'Status',
        Cell: ({ cell, row }) => (
          <OpenRecommendationAssetStatus
            openRecommendationOutcome={row.original.raOutcome}
            assetStatus={cell.getValue<RecommendedAction['assetStatus']>()}
          />
        ),
        Header: ({ column }) => <TableColumn column={column} />,
        grow: false,
        size: 150,
        enableSorting: true,
        //@ts-ignore
        sortingFn: (rowA: Row<RecommendedAction>, rowB: Row<RecommendedAction>, columnId: string) =>
          sortingRegistry.status<RecommendedAction>(
            rowA,
            rowB,
            columnId,
            createTableSortingOrderMap(openRecommendationAssetStatusSortingOrder)
          ),

        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.status.filterFn,
        meta: {
          filterType: 'status',
        },
      },
      {
        id: 'assetName',
        accessorKey: 'assetName',
        header: 'Asset',
        Header: ({ column }) => <TableColumn column={column} />,
        Cell: ({ cell }) => <OpenRecommendationAssetName assetName={cell.getValue<string>()} />,
        size: 200,
        grow: false,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'belongsTo',
        header: 'Belongs To',
        accessorKey: 'assetParent',
        Cell: ({ cell }) => <OpenRecommendationBelongsTo belongsTo={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 170,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'faultType',
        header: 'Fault',
        accessorKey: 'faultType',
        grow: false,
        Cell: ({ cell }) => <OpenRecommendationsFaultType faultType={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 150,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'observation',
        header: 'Observation',
        accessorKey: 'observation',
        Cell: ({ cell }) => <Observation observation={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 200,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'recommendedAction',
        header: 'Recommended Action',
        accessorKey: 'recommendedAction',
        Cell: ({ cell }) => <OpenRecommendedAction recommendedAction={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 400,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'raOpenDate',
        header: 'Created',
        accessorKey: 'raOpenDate',
        Cell: ({ cell }) => <OpenRecommendationDate date={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        //@ts-ignore
        sortingFn: sortingRegistry.date<RecommendedAction>,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        meta: {
          filterType: 'date',
        },
        //@ts-ignore
        filterFn: filterRegistry.date.filterFn,
        size: 155,
        grow: false,
      },
      {
        id: 'raDueDate',
        header: 'Due',
        accessorKey: 'raDueDate',
        Cell: ({ cell }) => <OpenRecommendationDate date={cell.getValue<string>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        //@ts-ignore
        sortingFn: sortingRegistry.date<RecommendedAction>,
        enableSorting: true,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        meta: {
          filterType: 'date',
        },
        //@ts-ignore
        filterFn: filterRegistry.date.filterFn,
        size: 145,
        grow: false,
      },
      {
        id: 'raDaysToDue',
        header: 'Days',
        accessorKey: 'raDaysToDue',
        Cell: ({ cell }) => <DaysInDue daysInDue={cell.getValue<number>()} />,
        Header: ({ column }) => <TableColumn column={column} />,
        size: 145,
        grow: false,
        //@ts-ignore
        sortingFn: sortingRegistry.number<RecommendedAction>,
        enableColumnFilter: true,
        enableSorting: true,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'number',
        },
      },
    ],
    []
  )

  return columns
}

export default useOpenRecommendationsColumns
