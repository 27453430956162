import { ENV } from '@/shared/constants'

export const dataTestId: Record<string, string> = {
  appHeader: 'app-header',
  errorFallbackText: 'error-fallback-text',
  customersSelect: 'customer-select',
  genericDialogWithElement: 'generic-dialog-with-element',
  genericDialogWithText: 'generic-dialog-with-text',
  genericDialogInnerElement: 'generic-dialog-inner-element',
  genericConfirmationDialogButton1: 'generic-confirmation-dialog-button-1',
  genericConfirmationDialogButton2: 'generic-confirmation-dialog-button-2',
  badgeComponent: 'badge-component',
  genericToast: 'generic-toast',
  mockSubmitButton: 'mock-submit-button',
  protectedRoutes: 'protected-routes',
  routesErrorBoundary: 'routes-error-boundary',
  eChartsRenderer: 'e-chart-renderer',
  centeredLoader: 'centered-loader',
  automaticDiagnosticsComponent: 'automatic-diagnostics-component',
  automaticDiagnosticsAssetsCountContainer: 'automatic-diagnostics-assets-count-container',
  automaticDiagnosticsAverageBox: 'automatic-diagnostics-average-box',
  automaticDiagnosticsFooter: 'automatic-diagnostics-footer',
  automaticsDiagnosticsMonthlyTrendContainer: 'automatics-diagnostics-monthly-trend-container',
  automaticDiagnosticsExportCSVButton: 'automatic-diagnostics-export-csv-button',
  assetHealthCondition: 'asset-health-condition',
  assetHealthConditionCurrentMonthStatus: 'asset-health-condition-current-month-status',
  assetHealthConditionCurrentMonthStatusContentRow: 'asset-health-condition-current-month-status-content-row',
  assetHealthConditionCurrentMonthStatusPieChart: 'asset-health-condition-current-month-status-pie-cahrt',
  assetHealthConditionTrendView: 'asset-health-condition-trend-view',
  assetHealthConditionTrendViewTable: 'asset-health-condition-trend-view-table',
  assetHealthConditionTrendViewTableRow: 'asset-health-condition-trend-view-table-row',
  assetHealthConditionTrendViewStackedBar: 'asset-health-condition-trend-view-stacked-bar',
  logoutButton: 'logout-button',
  appTabs: 'app-tabs',
  globalFilteringAssetStatusSelect: 'global-filtering-asset-status-select',
  globalFilteringRecommendedActionSelect: 'global-filtering-recommended-action-select',
  globalFilteringFaultTypeSelect: 'global-filtering-fault-type-select',
  moduleHeaderTitle: 'module-header-title',
  backToOverviewButton: 'back-to-overview-button',
  moduleHeaderSubtitle: 'module-header-subtitle',
  headerInfoPanel: 'header-info-panel',
  headerInfoPanelIcon: 'header-info-panel-icon',
  headerActionPanel: 'header-action-panel',
  headerLoader: 'header-loader',
  headerClearFiltersButton: 'header-clear-filters-button',
  headerTableStateChangeSwitch: 'header-table-state-change-switch',
  headerRefreshButton: 'header-refresh-button',
  headerExportCSVButton: 'header-export-csv-button',
  devicesSensorsSideMenu: 'devices-sensors-side-menu',
  deviceSensorsTable: 'devices-sensors-table',
  deviceSensorsModuleHeader: 'devices-sensors-module-header',
  deviceSensorsTableCard: 'devices-sensors-card',
  deviceSensorsTableRow: 'devices-sensors-table-row',
  bearingSpinner: 'bearing-spinner',
  mockComponent: 'mock-component',
  genericGlobalLoader: 'generic-global-loader',
  genericHeaderLoader: 'generic-header-loader',
  genericLoader: 'generic-loader',
  toastContent: 'toast-content',
  errorDisplayBackButton: 'error-display-back-button',
  errorDisplayContainer: 'error-display-container',
  errorDisplayImage: 'error-display-image',
  orphanNotesRoot: 'orphan-notes-root',
  assetHealthFaultWidget: 'asset-health-fault-widget',
  recommendedActions: 'recommended-actions',
  recommendedActionsTrendView: 'recommended-actions-trend-view',
  recommendedActionsTrendViewTable: 'recommended-actions-trend-view-table',
  recommendedActionsTrendViewTableRow: 'recommended-actions-trend-view-table-row',
  recommendedActionsCurrentMonthStatus: 'recommended-actions-current-month-status',
  recommendedActionsCurrentMonthStatusPieChart: 'recommended-actions-current-month-status-pie-chart',
  recommendedActionsCurrentMonthStatusContentRow: 'recommended-actions-current-month-status-content-row',
  reportStatusDialog: 'report-status-dialog',
  reportStatus: 'report-status',
  conflictDialog: 'conflict-dialog',
  conflictDialogCloseButton: 'conflict-dialog-close-button',
  conflictDialogRefreshButton: 'conflict-dialog-refresh-button',
  reportStatusDialogContent: 'report-status-dialog-content',
  reportStatusDialogContentFault: 'report-status-dialog-content-fault',
  reportStatusDialogHeaderAppendButton: 'report-status-dialog-header-append-button',
  reportStatusDialogHeaderRemoveButton: 'report-status-dialog-header-remove-button',
  reportStatusSubmitLoader: 'report-status-submit-loader',
  authConsumer: 'auth-consumer',
  unresolvedRecommendations: 'unresolved-recommendations',
  unresolvedRecommendationsRedirectButton: 'unresolved-recommendations-redirect-button',
  unresolvedRecommendationsStatusCheckbox: 'unresolved-recommendations-status-checkbox',
  unresolvedRecommendationsTableCellContent: 'unresolved-recommendations-table-cell-content',
  siteDetails: 'site-details',
  siteDetailsTechnicalInfo: 'site-details-technical-information',
  siteDetailsContractDetails: 'site-details-contract-details',
  siteDetailsContractDetailsTabs: 'site-details-contract-details-tabs',
  siteDetailsBackButton: 'site-details-back-button',
  siteDetailsCancelButton: 'site-details-cancel-button',
  siteDetailsSaveButton: 'site-details-save-button',
  siteDetailsEditDetails: 'site-details-edit-details',
  siteDetailsEditDetailsForm: 'site-details-edit-details-form',
  siteDetailsEditDetailsView: 'site-details-edit-details-view',
  siteDetailsCustomerLogoCard: 'site-details-customer-logo',
  noRoleMessage: 'no-compass-role-message',
  reportStatusEvidence: 'report-status-evidence',
  evidenceRow: 'evidence-row',
  evidenceUploadedImage: 'evidence-uploaded-image',
  evidenceUploadedImageCard: 'evidence-uploaded-image-card',
  evidenceUploadedImageActions: 'evidence-uploaded-image-actions',
  evidenceUploadedImageCardEditNameIcon: 'evidence-uploaded-image-card-edit-name-icon',
  evidenceUploadedImageCardEditNameInput: 'evidence-uploaded-image-card-edit-name-input',
  evidenceUploadedImageCardEditNameSaveButton: 'evidence-uploaded-image-card-edit-name-save-button',
  evidenceUploadedImageCardDownloadImageIcon: 'evidence-uploaded-image-card-download-image-icon',
  evidenceUploadedImageCardDeleteImageIcon: 'evidence-uploaded-image-card-delete-image-icon',
  evidenceDragAndDropUploader: 'evidence-drag-and-drop-uploader',
  evidenceDragAndDropUploaderInput: 'evidence-drag-and-drop-uploader-input',
  evidencePasteFromClipboardButton: 'evidence-paste-from-clipboard-button',
  statusReportEvidence: 'status-report-evidence',
  statusReportEvidenceImageCard: 'status-report-evidence-image-card',
  evidenceUploadedImageDetails: 'evidence-uploaded-image-details',
  evidenceImagePopupModal: 'evidence-image-popup-modal',
  evidenceModalImage: 'evidence-modal-image',
  evidenceModalImageNameInput: 'evidence-modal-image-name-input',
  evidenceModalImageNameCancelButton: 'evidence-modal-image-name-cancel-button',
  evidenceModalImageNameSaveButton: 'evidence-modal-image-name-save-button',
  errorDescription: 'error-description',
  iconCondensedAsNormal: 'icon-condensed-as-normal',
  condensedImg: 'condensed-img',
  assetStatusIcon: 'asset-status-icon',
  assetAnnualStatusIcon: 'asset-annual-status-icon',
  faultHistoryArrayContainer: 'fault-history-array-container',
  faultHistoryObjectContainer: 'fault-history-object-container',
  markAsNormalContainer: 'mark-as-normal-container',
  normalContainer: 'normal-container-one',
  historyContainer: 'history-container',
  closedRecommendedAction: 'closed-recommended-action',
  correctDiagnosticArea: 'correct-diagnostic-area',
  correctDiagnosticAreaTextArea: 'correct-diagnostic-area-text-area',
  faultComponent: 'fault-component',
  recommendedActionContainer: 'recommended-actions-container',
  // customerNoteCollapse: 'customer-note-collapse',
  faultFormMenu: 'fault-form-menu',
  faultFormRow: 'fault-form-row',
  // mockCustomerNote: 'mock-customer-note',
  faultFormSelect: 'fault-form-select',
  faultFormArea: 'fault-form-area',
  faultFormDatePicker: 'fault-form-date-picker',
  faultFormFooter: 'fault-form-footer',
  faultFormFooterSubmitButton: 'fault-form-footer-submit-button',
  faultFormConfirmDialogCancelButton: 'fault-form-confirm-dialog-cancel-button',
  faultFormConfirmDialogConfirmButton: 'fault-form-confirm-dialog-confirm-button',
  faultFormReconfirmButton: 'fault-form-reconfirm-button',
  faultFormFooterCancelButton: 'fault-form-footer-cancel-button',
  formDatePicker: 'form-date-picker',
  formDatePickerErrorArea: 'form-date-picker-error-area',
  formDatePickerLabel: 'form-date-picker-label',
  tableGenericHeaderColumn: 'table-generic-header-column',
  columnHeaderCustomFilter: 'column-header-custom-filter',
  widgetRefreshButton: 'widget-refresh-button',
  dragAndDropLogo: 'drag-and-drop-logo',
  reasonIndicator: 'reason-indicator',
  reasonIndicatorFilter: 'reason-indicator-filter',
  formMenuIcon: 'form-menu-icon',
  formMenuItems: 'form-menu-items',
  formMenuItemComponent: 'form-menu-item-component',
  formRadioGroup: 'form-radio-group',
  formSelect: 'form-select',
  formSelectErrorArea: 'form-select-error-area',
  formSelectLabel: 'form-select-label',
  formTextArea: 'form-text-area',
  formTextAreaError: 'form-test-area-error',
  formTextAreaLabel: 'form-text-area-label',
  formHeader: 'form-header',
  formHeaderAddNewFaultButton: 'form-header-add-new-fault-button',
  // customerNoteHistoryInfo: 'customer-note-history-info',
  // customerNotesHistory: 'customer-notes-history',
  // sKfCustomerNoteHistory: 'sKf-customer-note-history',
  // halCustomerNoteHistory: 'hal-customer-note-history',
  recommendedActionDateDisplay: 'recommended-action-date-display',
  faultHistory: 'fault-history',
  faultHistoryTypeText: 'fault-history-type-text',
  faultHistoryCollapseIconButton: 'fault-history-collapse-icon-button',
  faultHistoryCollapseContainer: 'fault-history-collapse-container',
  faultHistoryCorrectDiagnosis: 'fault-history-correct-diagnosis',
  faultHistoryExplanation: 'fault-history-explanation',
  faultHistoryRecommendedAction: 'fault-history-recommended-action',
  // faultCustomerNotesHistory: 'fault-customer-notes-history',
  faultHistoryReconfirmSection: 'fault-history-reconfirm-section',
  faultHistoryReconfirmSectionCollapse: 'fault-history-reconfirm-section-collapse',
  faultHistoryReconfirmSectionCollapseButton: 'fault-history-reconfirm-section-collapse-button',
  faultHistoryCollapse: 'fault-history-collapse',
  faultHistoryRow: 'fault-history-row',
  faultHistoryCollapseIcon: 'fault-history-collapse-icon',
  faultHistoryCollapseImage: 'fault-history-collapse-image',
  faultHistoryLinePrefix: 'fault-history-line-prefix',
  recommendedActionHistory: 'recommended-action-history',
  reconfirmHistoryContainer: 'reconfirm-history-container',
  evidenceHistory: 'evidence-history',
  evidenceHistoryContent: 'evidence-history-content',
  customerInfoCard: 'customer-info-card',
  // customerNoteInfo: 'customer-note-info',
  // customerNoteMessage: 'customer-note-message',
  skfInfoCard: 'skf-info-card',
  newNoteNotification: 'new-note-notification',
  // customerNoteCard: 'customer-note-card',
  recommendedActionMenu: 'recommended-action-menu',
  recommendedActionFromMenu: 'recommended-action-from-menu',
  recommendedActionRow: 'recommended-action-row',
  reconfirmButton: 'reconfirm-button',
  reconfirmButtonConfirmDialogCancelButton: 'reconfirm-button-confirm-dialog-cancel-button',
  reconfirmButtonConfirmDialogConfirmButton: 'reconfirm-button-confirm-dialog-confirm-button',
  flexibleCollapse: 'flexible-collapse',
  errorBoundaryComponent: 'error-boundary-component',
  multilineTextEllipsis: 'multiline-text-ellipsis',
  singleLineTextEllipsis: 'single-line-text-ellipsis',
  textEllipsisPopup: 'text-ellipsis-popup',
  appliedFilterPopoverComponent: 'applied-filter-popover-component',
  appliedFilterPopover: 'applied-filter-popover',
  filterStatusCheckbox: 'filter-status-checkbox',
  filterDeviceStatusCheckbox: 'filter-device-status-checkbox',
  analysisReasonContent: 'analysis-reason-content',
  dateFilterContent: 'date-filter-content',
  dateFilterContentDatePicker: 'date-filter-content-date-picker',
  deviceStatusFilterContent: 'device-status-filter-content',
  assetStatusFilterContent: 'asset-status-filter-content',
  numberFilterContent: 'number-filter-content',
  textFilterContent: 'text-filter-content',
  analysisReasonFilterSummary: 'analysis-reason-filter-summary',
  dateFilterSummary: 'date-filter-summary',
  deviceStatusFilterSummary: 'device-status-filter-summary',
  assetStatusFilterSummary: 'asset-status-filter-summary',
  genericTableFilterPopup: 'generic-table-filter-popup',
  genericTableFilterIcon: 'generic-table-filter-icon',
  genericTableFilterPopupClearButton: 'generic-table-filter-popup-clear-button',
  genericTableFilterPopupApplyButton: 'generic-table-filter-popup-apply-button',
  tablePadding: 'table-padding',
  reasonIndicatorCount: 'reason-indicator-count',
  historyRowWrapper: 'history-row-wrapper',
  outsideClickArea: 'outside-click-area',
  headerComponent: 'header-component',
  reasonIndicatorFilterPopover: 'reason-indicator-filter-popover',
  emptyWrapperOrphanNotes: 'empty-data-table-orphan-notes',
  orphanNoteDataName: 'orphan-note-data-name',
  orphanNoteDataValue: 'orphan-note-data-value',
  orphanNoteTableRow: 'orphan-note-table-row',
  orphanNotesTableAction: 'orphan-note-table-action',
  genericConfirmationDialogButton: 'generic-confirmation-dialog-button',
  noteCreationDateColumnHeader: 'note-creation-date-column-header',
  reportGenerator: 'report-generator',
  generateReport: 'generate-report',
  generateReportButton: 'generate-report-button',
  reportGeneratorAssetSelection: 'generate-report-asset-section',
  reportGeneratorModuleHeader: 'report-generator-module-header',
  reportGeneratorConfirmDialogConfirmButton: 'report-generator-confirm-dialog-confirm-button',
  reportGeneratorCompanyLogo: 'report-generator-company-logo',
  generateReportPreview: 'generate-report-preview',
  generateReportPreviewPDFLogo: 'generate-report-preview-empty-logo',
  generateReportClearFilterButton: 'generate-report-clear-filter-button',
  generateReportFilters: 'generate-report-filters',
  generateReportPDFPageHeaderLogo: 'generate-report-pdf-page-header-logo',
  generateReportPreviewPDFViewer: 'generate-report-pdf-preview-viewer',
  generateReportPreviewLoader: 'generate-report-pdf-preview-loader',
  generateReportPDFPreviewCoverPage: 'generate-report-pdf-preview-cover-page',
  generateReportPDFPreviewInfoPage: 'generate-report-pdf-preview-info-page',
  generateReportFiltersCommonSelect: 'generate-report-filters-common-select',
  generateReportFiltersReportDateSelect: 'generate-report-filters-report-date-select',
  generateReportFiltersTotalAssetIncluded: 'generate-report-filters-total-asset-included',
  generateReportFiltersAssetStatusIcons: 'generate-report-filters-asset-status-icons',
  generateReportFiltersReportTypeSection: 'generate-report-filters-report-type-section',
  generateReportFiltersReportType: 'generate-report-filters-report-type',
  generateReportRegenerateButton: 'generate-report-regenerate-button',
  generateReportExportButton: 'generate-report-export-button',
  reportGeneratorCommonSelect: 'report-generator-common-select',
  reportGeneratorCommonCheckbox: 'report-generator-common-checkbox',
  generateReportFiltersAssetStatusSelect: 'generate-report-filters-asset-status-select',
  generateReportFiltersAssetCriticalitySelect: 'generate-report-filters-asset-criticality-select',
  generateReportFiltersAssetTypeSelect: 'generate-report-filters-asset-type-select',
  generateReportCoverPageImage: 'generate-report-cover-page-image',
  generateReportCoverPageLogoImage: 'generate-report-cover-page-logo-image',
  generateReportCoverPageLogo: 'generate-report-cover-page-logo',
  generateReportCoverPageHeader: 'generate-report-cover-page-header',
  generateReportCoverPageLabel1: 'generate-report-cover-page-label-1',
  generateReportCoverPageLabel2: 'generate-report-cover-page-label-2',
  generateReportCoverPageLabel3: 'generate-report-cover-page-label-3',
  generateReportCoverPageLabel4: 'generate-report-cover-page-label-4',
  generateReportCoverPageValue1: 'generate-report-cover-page-value-1',
  generateReportCoverPageValue2: 'generate-report-cover-page-value-2',
  generateReportCoverPageValue3: 'generate-report-cover-page-value-3',
  generateReportCoverPageValue4: 'generate-report-cover-page-value-4',
  generateReportInfoPageImage: 'generate-report-info-page-image',
  devicesSensorsModule: 'devices-sensors-module',
  channelAssetsCell: 'channel-assets-cell',
  channelNameCell: 'channel-name-cell',
  channelsPane: 'channels-pane',
  lastCommunicationCell: 'last-communication-cell',
  genericModuleHeader: 'genericModuleHeader',
  channelDataFault: 'channel-data-fault',
  channelStatusIndicator: 'channel-status-indicator',
  channelDataSection: 'channel-data-section',
  deviceSensorsDataRow: 'device-sensors-data-row',
  deviceDataFault: 'device-data-fault',
  deviceDataSection: 'device-data-section',
  sensorDetailPanel: 'sensor-detail-panel',
  overviewModule: 'overview-module',
  overviewActionMenu: 'overview-action-menu',
  overviewActionMenuPopover: 'overview-action-menu-popover',
  overviewModuleHeader: 'overview-module-header',
  overviewCell: 'overview-cell',
  dueDatesStatus: 'due-dates-status',
  genericActionMenu: 'generic-action-menu',
  priorityCell: 'priority-cell',
  analysisBacklogActionMenu: 'analysis-backlog-action-menu',
  analysisBacklogActionMenuMarkAsNormal: 'analysis-backlog-action-menu-mark-as-normal',
  analysisBacklogActionReportStatus: 'analysis-backlog-action-report-status',
  analysisBacklogPopoverCancelButton: 'analysis-backlog-popover-cancel-button',
  analysisBacklogPopoverConfirmButton: 'analysis-backlog-popover-confirm-button',
  analysisBacklogCopyHierarchyItemButton: 'analysis-backlog-copy-hierarchy-button',
  analysisBacklogCopiedHierarchyItemButtonIcon: 'analysis-backlog-copied-hierarchy-button-icon',
  assetHierarchyNestedList: 'belongs-to-nested-list',
  assetHierarchyNestedListIcon: 'belongs-to-nested-list-icon',
  analysisBacklogPriorityCellContent: 'analysis-backlog-priority-cell-content',
  analysisBacklogPriorityCellInaccurateIcon: 'analysis-backlog-priority-cell-inaccurate-icon',
  priorityCellHandledIcon: 'priority-cell-handled-icon',
  emptyAssetTableState: 'empty-asset-table-state',
  analysisBacklogModule: 'analysis-backlog-module',
  analysisBacklogShowHierarchyButton: 'analysis-backlog-show-hierarchy-button',
  analysisBacklogCloseHierarchyButton: 'analysis-backlog-close-hierarchy-button',
  multiLevelSelect: 'multi-level-select',
  mainOptionList: 'main-option-list',
  subOptionList: 'sub-option-list',
  multiLevelSelectLabel: 'multi-level-select-label',
  multiLevelSelectErrorArea: 'multi-level-error-area',
  selectToggleButton: 'select-toggle-button',
  pdfImage: 'pdf-image',
  pdfTemplate: 'pdf-template',
  pdfView: 'pdf-view',
  pdfText: 'pdf-text',
  pdfSKFLogo: 'pdf-skf-logo',
  pdfPage: 'pdf-page',
  PDFDocument: 'PDF-document',
  faultsRowContainer: 'faults-row-container',
  openRecommendationsModule: 'open-recommendations-module',
  themeConsumer: 'theme-consumer',
  reactNodeMessage: 'react-node-message',
}

/**
 * Checks if the values in the provided object are unique.
 * Throws an error if any duplicate values are found in DEV environment and warning to console in the rest
 *
 * @param {Record<string, string>} obj - The object to check for value uniqueness.
 * @returns {Record<string, string>} - The same object if values are unique.
 * @throws {Error} - If duplicate values are found (only in development environment).
 */

export const checkDataTestValuesUniqueness = <K extends string, V extends string>(
  obj: Record<K, V> = dataTestId as Record<K, V>
): Record<K, V> => {
  const valueSet = new Set<V>()
  for (const key in obj) {
    const value = obj[key]
    if (valueSet.has(value)) {
      if (import.meta.env.MODE === ENV.DEV) {
        throw new Error(`Duplicate value detected: ${value}`)
      } else {
        console.warn(`Duplicate value detected: ${value}`)
      }
    }
    valueSet.add(value)
  }
  return obj
}
