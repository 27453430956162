import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Button } from '@skf-internal/ui-components-react'

/**
 * HeaderClearFiltersButton is a button component that clears filters in the associated module.
 *
 * This component leverages Redux for state management to determine if the button should be
 * disabled and to define the function that clears filters when the button is clicked.
 *
 * The button displays a title that can be customized via the state or defaults to "Clear filters".
 *
 * @returns {JSX.Element} A rendered Button that clears filters when clicked.
 */

const HeaderClearFiltersButton = () => {
  const { isClearFiltersButtonDisabled, clearFiltersButtonFn, clearFilterButtonTitle } = useTypedSelector((store) =>
    headerActionPanelConfigSelector(store)
  )

  return (
    <Button
      data-testid={dataTestId.headerClearFiltersButton}
      disabled={isClearFiltersButtonDisabled}
      feType="secondary"
      feSize="sm"
      onClick={clearFiltersButtonFn}
    >
      {clearFilterButtonTitle || 'Clear filters'}
    </Button>
  )
}

export default HeaderClearFiltersButton
