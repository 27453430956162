import { DevicesAndSensorsData, DevicesAndSensorsSelectedItemsData } from '@/models/devicesAndSensors/types'
import useContextWrapper from '@/shared/hooks/useContextWrapper'

import { createContext, useMemo, useState } from 'react'

interface IDevicesAndSensorsContext {
  devicesAndSensorsData: DevicesAndSensorsData['devices']
  setDevicesAndSensorsData: React.Dispatch<React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsData']>>

  devicesAndSensorsSelectedItemsData: DevicesAndSensorsSelectedItemsData
  setDevicesAndSensorsSelectedItemsData: React.Dispatch<
    React.SetStateAction<IDevicesAndSensorsContext['devicesAndSensorsSelectedItemsData']>
  >
}

export const DevicesAndSensorsContext = createContext<IDevicesAndSensorsContext | null>(null)

export const useDevicesAndSensorsContext = () =>
  useContextWrapper(DevicesAndSensorsContext, {
    contextName: useDevicesAndSensorsContext.name,
    providerName: DevicesAndSensorsContextProvider.name,
  })

export const DevicesAndSensorsContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [devicesAndSensorsData, setDevicesAndSensorsData] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsData']
  >([])

  const [devicesAndSensorsSelectedItemsData, setDevicesAndSensorsSelectedItemsData] = useState<
    IDevicesAndSensorsContext['devicesAndSensorsSelectedItemsData']
  >({
    device: undefined,
    channel: undefined,
    channelIndex: undefined,
  })

  const value = useMemo(
    () => ({
      devicesAndSensorsData,
      setDevicesAndSensorsData,
      devicesAndSensorsSelectedItemsData,
      setDevicesAndSensorsSelectedItemsData,
    }),
    [devicesAndSensorsData, devicesAndSensorsSelectedItemsData]
  )

  return <DevicesAndSensorsContext.Provider value={value}>{children}</DevicesAndSensorsContext.Provider>
}
