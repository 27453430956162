import PriorityUnhandledCellColumn from '@/modules/analysisBacklog/components/cell/priorityCell/priorityCellContent'
import { dataTestId } from '@/tests/testid'
import { Icon } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface PriorityCellProps {
  isHandled: boolean
  isInaccuratePriority: boolean
  priority: number
}

const PriorityCell: FC<PriorityCellProps> = (props) => {
  if (props.isHandled) {
    return <Icon feIcon="asset" feColor="gray" feSize="sm" data-testid={dataTestId.priorityCellHandledIcon} />
  } else {
    return <PriorityUnhandledCellColumn {...props} />
  }
}
export default PriorityCell
