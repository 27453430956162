import {
  CLEAR_MODULE_HEADER_ACTION_PANEL_DATA,
  CLEAR_MODULE_HEADER_CONFIG_DATA,
  CLEAR_MODULE_HEADER_CSV_DATA,
  SET_MODULE_HEADER_ACTION_PANEL_DATA,
  SET_MODULE_HEADER_CONFIG_DATA,
  SET_MODULE_HEADER_CSV_DATA,
} from '@/store/moduleHeader/actions.types'
import {
  ModuleHeaderAction,
  ModuleHeaderActionPanelState,
  ModuleHeaderCSVDataState,
  ModuleHeaderConfigState,
} from '@/store/moduleHeader/types'

export const initialConfigState: ModuleHeaderConfigState = {
  showHeader: false,
  title: '',
  showBackButton: false,
  showInfoPanel: false,
  infoPanelData: [],
  infoPanelElement: undefined,
  showActionPanel: false,
  subtitle: '',
  showSubtitle: false,
}
export const initialActionPanelState: ModuleHeaderActionPanelState = {
  withLoader: false,
  withClearFiltersButton: false,
  clearFilterButtonTitle: '',
  isClearFiltersButtonDisabled: false,
  clearFiltersButtonFn: () => {},
  withTableStateChangeSwitch: false,
  tableStateChangeSwitchCurrentState: false,
  // tableStateChangeSwitchFn: () => {},
  tableStateChangeSwitchLabel: '',
  withCSVDownloadButton: false,
  csvDownLoadButtonSize: 'md',
  withAsyncIndicator: false,
  isAsyncIndicatorShown: false,
  isWithUserEvents: true,
}
export const initialCSVDataState: ModuleHeaderCSVDataState = {
  csvData: '',
  moduleName: '',
}

type ModuleHeaderConfigDataReducer = (
  state: ModuleHeaderConfigState | undefined,
  action: ModuleHeaderAction
) => ModuleHeaderConfigState

type ModuleHeaderActionPanelDataReducer = (
  state: ModuleHeaderActionPanelState | undefined,
  action: ModuleHeaderAction
) => ModuleHeaderActionPanelState

type ModuleHeaderCSVDataReducer = (
  state: ModuleHeaderCSVDataState | undefined,
  action: ModuleHeaderAction
) => ModuleHeaderCSVDataState

const moduleHeaderConfigDataReducer: ModuleHeaderConfigDataReducer = (state = initialConfigState, action) => {
  if (action.type === SET_MODULE_HEADER_CONFIG_DATA) {
    return { ...state, ...action.payload }
  }
  if (action.type === CLEAR_MODULE_HEADER_CONFIG_DATA) {
    return initialConfigState
  }

  return state
}

const moduleHeaderActionPanelDataReducer: ModuleHeaderActionPanelDataReducer = (
  state = initialActionPanelState,
  action
) => {
  if (action.type === SET_MODULE_HEADER_ACTION_PANEL_DATA) {
    return { ...state, ...action.payload }
  }
  if (action.type === CLEAR_MODULE_HEADER_ACTION_PANEL_DATA) {
    return initialActionPanelState
  }

  return state
}

const moduleHeaderCSVDataReducer: ModuleHeaderCSVDataReducer = (state = initialCSVDataState, action) => {
  if (action.type === SET_MODULE_HEADER_CSV_DATA) {
    return { ...state, ...action.payload }
  }
  if (action.type === CLEAR_MODULE_HEADER_CSV_DATA) {
    return initialCSVDataState
  }

  return state
}

export { moduleHeaderConfigDataReducer, moduleHeaderActionPanelDataReducer, moduleHeaderCSVDataReducer }
