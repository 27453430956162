import ReportCommonCheckbox from '@/modules/report-generator/components/ReportCheckbox'
import { FC, useMemo } from 'react'
import { ReportTypeString } from '@/models/reportGenerator/types'
import { reportSections } from '@/modules/report-generator/utils/constants'
import { StyledReportTypeContainer } from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'

const ReportTypeSection: FC<{
  reportTypeStatus: ReportTypeString[]
  onChange: (value: ReportTypeString, status: boolean) => void
}> = ({ reportTypeStatus, onChange }) => {
  const computedDisabledStatuses = useMemo(() => {
    return Object.values(reportSections).reduce<Record<string, boolean>>((acc, section) => {
      acc[section] =
        !reportTypeStatus.includes(reportSections.DETAILED_ASSET_HEALTH as ReportTypeString) &&
        section === reportSections.LAST_MEASUREMENTS
      return acc
    }, {})
  }, [reportTypeStatus])

  return (
    <StyledReportTypeContainer
      data-testid={dataTestId.generateReportFiltersReportType}
      fe-justify-content="space-between"
      fe-flex-direction="row"
      fe-gap="lg"
    >
      {Object.values(reportSections).map((section) => (
        <ReportCommonCheckbox
          key={section}
          status={section as ReportTypeString}
          checked={reportTypeStatus.includes(section as ReportTypeString)}
          disabled={computedDisabledStatuses[section]}
          onChange={(_, checked) => onChange(section as ReportTypeString, checked)}
        />
      ))}
    </StyledReportTypeContainer>
  )
}

export default ReportTypeSection
