import { COLORS, Popup, styled } from '@skf-internal/ui-components-react'

export const StyledPopover = styled.div`
  width: max-content;
  background-color: ${COLORS.white};
  border-radius: 0.125rem;
  font-size: 1rem;
  padding: 0;
`

export const StyledActionColumnPopup = styled(Popup)`
  padding: 0 !important;
`
