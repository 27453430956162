import { StyledLink } from '@/modules/overview/styled'
import { ENV, ROUTES } from '@/shared/constants'
import useRedirectToModuleWithSelectedCustomer from '@/shared/hooks/useRedirectToModuleWithSelectedCustomer'
import { FC } from 'react'

type MenuPopoverContext = {
  siteId: string
}

const PopoverContent: FC<MenuPopoverContext> = ({ siteId }) => {
  const redirectToModuleWithSelectedCustomer = useRedirectToModuleWithSelectedCustomer()

  return (
    <>
      {import.meta.env.MODE !== ENV.PROD && import.meta.env.MODE !== ENV.STAGING && (
        <StyledLink
          as="button"
          type="button"
          feIcon={{ feIcon: 'paper', position: 'left' }}
          feStretch={true}
          onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.REPORT_GENERATOR, siteId)}
        >
          Generate Report
        </StyledLink>
      )}
      <StyledLink
        as="button"
        type="button"
        feIcon={{ feIcon: 'info', position: 'left' }}
        feStretch={true}
        onClick={() => redirectToModuleWithSelectedCustomer(ROUTES.SITE_DETAILS, siteId)}
      >
        Site Details
      </StyledLink>
    </>
  )
}

export default PopoverContent
