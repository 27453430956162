import React from 'react'
import CoverPagePDFPreview from '@/modules/report-generator/components/reports/coverPage/coverPagePDFPreview'
import InfoPagePDFPreview from '@/modules/report-generator/components/reports/infoPage/infoPagePDFPreview'
import { Document } from '@react-pdf/renderer'
import { ReportGeneratorApiResponse } from '@/models/reportGenerator/types'
interface ReportProps {
  generatedReportData: ReportGeneratorApiResponse
}
const Report: React.FC<ReportProps> = ({ generatedReportData }) => (
  <Document pdfVersion="1.7">
    <CoverPagePDFPreview logoUrl={generatedReportData.coverPage.logoFileURL} />
    <InfoPagePDFPreview />
  </Document>
)

export default Report
