import { getMappedValue } from '@/modules/openRecommendations/utils'
import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectReportFaultTypes = (state: RootState) => state.reportFaultTypes
const _selectReportFaultTypeMappedValue = (_: RootState, faultType: string) => faultType

const mappedReportFaultTypeSelector = createSelector(
  _selectReportFaultTypes,
  _selectReportFaultTypeMappedValue,
  (reportFaultTypes, faultType) => getMappedValue(reportFaultTypes, faultType)
)

export { mappedReportFaultTypeSelector }
