import { getReports } from '@/api/paths/reports'
import { useReportGeneratorContext } from '@/contexts/reportGenerator' // Import context for report generation
import { ReportGeneratorApiPayload } from '@/models/reportGenerator/types'
import { DarwinDetachedModuleError } from '@/shared/components/ErrorBoundary/DarwinError'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'

const useGenerateReports = () => {
  const throwError = useAsyncThrow()
  const { assetReportFilters, setGeneratedReportData } = useReportGeneratorContext()
  const { sid } = useParams()

  const payload: ReportGeneratorApiPayload = {
    ...assetReportFilters,
    reportDate: formatDate(assetReportFilters.reportDate, DateFormats.ISO8601Date),
  }
  return useApi(() => {
    return getReports(
      payload,
      {
        onSuccess: async (result) => {
          setGeneratedReportData(result)
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinDetachedModuleError(error))

          //TBD
          /*
            setShowPreview(false)
            reportGeneratorDispatch(
              openToast({
                feSeverity: 'error',
                children: error.message,
              })
            )
          */
        },
      },
      {
        sid,
      }
    )
  })
}

export default useGenerateReports
