import {
  AnalysisReasonFaultData,
  AnalysisReasonType,
  InitialAsset,
  ReasonIndicatorData,
  ResponseAssets,
} from '@/models/analysisBacklog/types'
import { DeviceFaultEnum } from '@/models/devicesAndSensors/types'
import { analysisBacklogCSVFilterTypes, DateFilter } from '@/modules/analysisBacklog/utils/constants'
import { HeaderInfoPanelIconData } from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import { compareDates, DateFormats, formatDate } from '@/shared/dateUtils'
import { capitalizeFirstLetter, toStartCase } from '@/shared/utils'
import { IconProps } from '@skf-internal/ui-components-react'
import { MRT_ColumnFiltersState, MRT_TableInstance } from 'material-react-table'

/**
 * Formats the given array of assets into handled and unhandled categories.
 *
 * @param {InitialAsset[]} data - The array of assets to be formatted.
 * @returns {ResponseAssets} An object containing two arrays: one for handled assets and one for unhandled assets.
 *
 * @typedef {Object} InitialAsset
 * @property {boolean} isHandled - Indicates whether the asset has been handled.
 *
 * @typedef {Object} ResponseAssets
 * @property {InitialAsset[]} handled - The array of assets that have been handled.
 * @property {InitialAsset[]} unhandled - The array of assets that have not been handled.
 */
const formatAssets = (data: InitialAsset[]): ResponseAssets => {
  const handledAssets = data.filter((asset) => asset.isHandled)
  const unhandledAssets = data.filter((asset) => !asset.isHandled)

  return {
    handled: handledAssets,
    unhandled: unhandledAssets,
  }
}

/**
 * Formats the given filters into a record with string values.
 *
 * This function takes a `MRT_ColumnFiltersState` object and converts it into a
 * `Record<string, string>` where each key corresponds to a filter id and each value
 * is a string representation of the filter's value. If a filter's value is an array,
 * it concatenates the array values into a single string separated by periods. For date
 * filters, it formats the start and end dates into a single string.
 *
 * @param {MRT_ColumnFiltersState} filters - The state of the column filters to format.
 * @returns {Record<string, string>} A record where each key is a filter id and each value
 * is a string representation of the filter's value.
 *
 * @example
 * const filters = [
 *   { id: 'asset', value: 'asset1' },
 *   { id: 'analysisReasons', value: ['reason1', 'reason2'] },
 *   { id: 'lastCollectedDateTime', value: { startDate: new Date(), endDate: new Date() } }
 * ];
 * const formattedFilters = formatFilters(filters);
 * // formattedFilters = {
 * //   asset: 'asset1',
 * //   analysisReasons: 'Reason1.Reason2.',
 * //   status: 'none',
 * //   belongsTo: 'none',
 * //   lastCollectedDateTime: 'Start Date: <start_date>.End Date: <end_date>',
 * //   lastReportedDateTime: 'none'
 * // }
 */
const formatFilters = (filters: MRT_ColumnFiltersState): Record<string, string> => {
  const result: Record<string, string> = {
    assetName: 'none',
    analysisReason: 'none',
    status: 'none',
    belongsTo: 'none',
    collectedDate: 'none',
    reportedDate: 'none',
  }
  filters.forEach((filter) => {
    if (filter.value && analysisBacklogCSVFilterTypes.includes(filter.id)) {
      if (Array.isArray(filter.value)) {
        result[filter.id] = filter.value.reduce((result, value) => (result += toStartCase(value) + '.'), '')
      } else if (filter.id === 'collectedDate' || filter.id === 'reportedDate') {
        const dateFilterObject = filter.value as DateFilter
        const startDate = `Start Date: ${dateFilterObject.startDate?.toString() ?? 'none'}`
        const endDate = `End Date: ${dateFilterObject.endDate?.toString() ?? 'none'}`
        result[filter.id] = startDate.concat('.', endDate)
      } else {
        result[filter.id] = filter.value.toString()
      }
    }
  })

  return result
}

/**
 * Determines the count type based on the provided reason.
 *
 * @param {ReasonIndicatorData} reason - The reason indicator data object.
 * @returns {'alarm' | 'alert' | 'none'} - The count type which can be 'alarm', 'alert', or 'none'.
 *
 * The function evaluates the `type` property of the `reason` object and returns a corresponding count type:
 * - For `type` 'band', it returns the `severity` of the reason.
 * - For `type` 'diagnostics', 'protean', or 'ai', it returns 'alarm'.
 * - For `type` 'overall', it returns the `severity` of the reason.
 * - For `type` 'feedback', 'device_fault', 'no_sensor_data', or 'no_data_24h', it returns 'none'.
 */
const getCountType = (reason: ReasonIndicatorData): 'alarm' | 'alert' | 'none' => {
  switch (reason.type) {
    case 'band':
      return reason.severity
    case 'diagnostics':
      return 'alarm'
    case 'protean':
      return 'alarm'
    case 'ai':
      return 'alarm'
    case 'overall':
      return reason.severity
    case 'feedback':
      return 'none'
    case 'device_fault':
      return 'none'
    case 'no_sensor_data':
      return 'none'
    case 'no_data_24h':
      return 'none'
  }
}

/**
 * Constructs a complete reason string based on the provided reason data.
 *
 * This function takes a `ReasonIndicatorData` object and generates a string
 * that combines the reason type and count type (if applicable). The resulting
 * string is converted to start case format.
 *
 * @param {ReasonIndicatorData} reason - The reason data object containing the type and other relevant information.
 * @returns {string} - A formatted string representing the complete reason.
 */
const getCompleteReasonString = (reason: ReasonIndicatorData): string => {
  let completeReasonString
  const countType = getCountType(reason)

  if (countType !== 'none') {
    completeReasonString = [reason.type, countType].join(' ')
  } else {
    completeReasonString = reason.type
  }

  return toStartCase(completeReasonString)
}

/**
 * Calculates the data for the information panel based on the provided asset table.
 *
 * @param {MRT_TableInstance<InitialAsset>} assetTable - The table instance containing asset data.
 * @param {boolean} [isEmptyTable=false] - A flag indicating whether the table is empty.
 * @returns {HeaderInfoPanelIconData[]} An array of objects containing information for the header info panel.
 *
 * The returned array contains objects with the following properties:
 * - `count`: The number of assets matching the criteria.
 * - `icon`: The icon to be displayed.
 * - `iconColor`: The color of the icon.
 * - `hoverText`: The text to be displayed on hover.
 *
 * The function calculates the following information:
 * 1. Number of unhandled assets.
 * 2. Number of assets that have at least one alarm.
 * 3. Number of assets that have at least one alert.
 */
const calculateInfoPanelData = (assetTable: MRT_TableInstance<InitialAsset>, isEmptyTable: boolean = false) => {
  let result: HeaderInfoPanelIconData[] = []
  const assetRows = assetTable.getSortedRowModel().flatRows
  const tableFilters = assetTable.getState().columnFilters

  if (assetRows.length > 0 || tableFilters.length > 0 || isEmptyTable) {
    result = [
      {
        //pending
        count: assetRows.filter((asset) => asset.original.isHandled === false).length,
        icon: 'asset' as IconProps['feIcon'],
        iconColor: 'blue' as IconProps['feColor'],
        hoverText: 'Number of unhandled assets',
      },
      {
        //alarm
        count: assetRows.reduce((acc, asset) => {
          if (!asset.original.isHandled && asset.original.analysisReasons.some((r) => getCountType(r) === 'alarm')) {
            acc += 1
          }
          return acc
        }, 0),
        icon: 'danger' as IconProps['feIcon'],
        iconColor: 'red' as IconProps['feColor'],
        hoverText: 'Number of assets that have at least one alarm',
      },
      {
        //alert
        count: assetRows.reduce((acc, asset) => {
          if (!asset.original.isHandled && asset.original.analysisReasons.some((r) => getCountType(r) === 'alert')) {
            acc += 1
          }
          return acc
        }, 0),
        icon: 'warning' as IconProps['feIcon'],
        iconColor: 'orange' as IconProps['feColor'],
        hoverText: 'Number of assets that have at least one alert',
      },
    ]
  }
  return result
}

/**
 * Formats a given string to be CSV-compatible by escaping necessary characters.
 *
 * This function checks if the input string contains a comma (`,`) or a newline (`\n`).
 * If either is found, the string is wrapped in double quotes (`"`), and any existing
 * double quotes within the string are escaped by doubling them (`""`). Newline characters
 * are replaced with a space.
 *
 * @param data - The input string to be formatted for CSV.
 * @returns The CSV-compatible formatted string.
 */
const formatCsvValues = (data: string): string => {
  if (data.includes(',') || data.includes('\n')) {
    return `"${data.replace(/"/g, '""').replace(/\n/g, ' ')}"`
  }
  return data
}

/**
 * Exports analysis backlog data to a CSV formatted string.
 *
 * @param {InitialAsset[]} data - The array of initial assets to be exported.
 * @param {string} customerName - The name of the customer.
 * @param {MRT_ColumnFiltersState} filters - The filters applied to the data.
 * @returns {string} The CSV formatted string representing the analysis backlog data.
 *
 * The CSV output includes:
 * - Site Name
 * - Dashboard name
 * - Export date
 * - Filters applied
 * - Column headers for the data
 * - Data rows for unhandled and handled assets
 *
 * Each asset's data includes:
 * - Analysis priority
 * - Asset name
 * - Belongs to (name of the entity the asset belongs to)
 * - Collected date
 * - Last reported date
 * - Days since last reported
 * - Asset status
 * - Analysis reasons
 * - Asset criticality
 *
 * The function formats dates and values appropriately for CSV output and handles special cases such as 'never-reported' status.
 */
const exportAnalysisBacklogDataToCsv = (
  data: InitialAsset[],
  customerName: string,
  filters: MRT_ColumnFiltersState
): string => {
  const formattedData = formatAssets(data)
  const formattedFilters = formatFilters(filters)
  return [
    ['sep=,'],
    ['Site Name:', customerName].join(','),
    ['Dashboard:', 'Analysis Backlog'].join(','),
    ['Export date:', formatDate(new Date(), DateFormats.AmericanDateTimeFormat)].join(','),
    [],
    [],
    [],
    [
      'Filters Applied:',
      formattedFilters['assetName'],
      formattedFilters['belongsTo'],
      formattedFilters['collectedDate'],
      formattedFilters['reportedDate'],
      '',
      formattedFilters['status'],
      formattedFilters['analysisReason'],
      '',
    ].join(','),
    [
      'Analysis priority',
      'Asset name',
      'Belongs to',
      'Collected',
      'Last reported',
      'Days since last reported',
      'Asset status',
      'Analysis reasons',
      'Asset criticality',
    ].join(','),
    formattedData.unhandled
      .map((asset) => {
        let lastReportedDateTime = formatDate(0, DateFormats.ISO8601Format)
        if (asset.status !== 'never-reported') {
          const currentUnhandledAsset = asset
          lastReportedDateTime = currentUnhandledAsset.lastReportedDateTime as string
        }
        return [
          asset.priority,
          formatCsvValues(asset.name),
          formatCsvValues(asset.belongsTo.name),
          asset.lastCollectedDateTime ? formatDate(asset.lastCollectedDateTime, DateFormats.AmericanDateFormat) : '-',

          asset.status !== 'never-reported' && lastReportedDateTime && lastReportedDateTime
            ? formatDate(lastReportedDateTime, DateFormats.AmericanDateFormat)
            : '-',

          asset.status !== 'never-reported' && lastReportedDateTime && lastReportedDateTime
            ? compareDates(
                formatDate(new Date(), DateFormats.AmericanDateFormat),
                formatDate(lastReportedDateTime, DateFormats.AmericanDateFormat)
              )
            : '-',
          typeof asset.status === 'string'
            ? capitalizeFirstLetter(asset.status.replaceAll('-', ' '))
            : 'status unknown',
          asset.analysisReasons.map((reason) => `${getCompleteReasonString(reason)} (${reason.count})`).join(' | '),
          asset.criticality,
        ].join(',')
      })
      .join('\n'),
    formattedData.handled
      .map((asset) =>
        [
          'handled',
          asset.name,
          asset.belongsTo.name,
          asset.lastCollectedDateTime ? formatDate(asset.lastCollectedDateTime, DateFormats.AmericanDateFormat) : '-',
          asset.lastReportedDateTime ? formatDate(asset.lastReportedDateTime, DateFormats.AmericanDateFormat) : '-',
          asset.lastReportedDateTime
            ? compareDates(
                formatDate(new Date(), DateFormats.AmericanDateFormat),
                formatDate(asset.lastReportedDateTime, DateFormats.AmericanDateFormat)
              )
            : '-',
          capitalizeFirstLetter(asset.status.replaceAll('-', ' ')),
          '',
          asset.criticality,
        ].join(',')
      )
      .join('\n'),
  ].join('\n')
}

/**
 * Filters the provided faulted data by the specified type.
 *
 * @param {AnalysisReasonFaultData[] | undefined} faultedData - The array of faulted data to filter. If undefined, an empty array is returned.
 * @param {AnalysisReasonType} type - The type of analysis reason to filter by. Currently supports 'no_data_24h'.
 * @returns {AnalysisReasonFaultData[]} - The filtered array of faulted data.
 *
 * @example
 * const faultedData = [
 *   { faults: [DeviceFaultEnum.NoMeasurement24h, DeviceFaultEnum.OtherFault] },
 *   { faults: [DeviceFaultEnum.NoMeasurement24h] }
 * ];
 * const type = 'no_data_24h';
 * const result = filterFaultedDataByType(faultedData, type);
 * // result will be:
 * // [
 * //   { faults: [DeviceFaultEnum.NoMeasurement24h] },
 * //   { faults: [DeviceFaultEnum.NoMeasurement24h] }
 * // ]
 */
const filterFaultedDataByType = (
  faultedData: AnalysisReasonFaultData[] | undefined,
  type: AnalysisReasonType
): AnalysisReasonFaultData[] => {
  if (!faultedData) return []

  const filteredFaults = faultedData.reduce((acc, data) => {
    if (type === 'no_data_24h' && data.faults) {
      const faults = data.faults.filter((fault) => fault === DeviceFaultEnum.NoMeasurement24h)
      data.faults = faults
    } else if (type === 'device_fault' && data.faults) {
      const faults = data.faults.filter((fault) => fault !== DeviceFaultEnum.NoMeasurement24h)
      data.faults = faults
    }
    acc.push(data)
    return acc
  }, [] as AnalysisReasonFaultData[])

  return filteredFaults
}

/**
 * Retrieves an asset row from the provided assets based on the given asset ID.
 *
 * @param {ResponseAssets} assets - The collection of assets, containing both handled and unhandled assets.
 * @param {string | undefined} assetId - The ID of the asset to find. If undefined, the function returns undefined.
 * @returns {AssetRow | undefined} - The asset row with the matching ID, or undefined if no match is found or if assetId is undefined.
 */
const getAssetRow = (assets: ResponseAssets, assetId: string | undefined) => {
  return assetId ? [...assets.unhandled, ...assets.handled].find((assetRow) => assetRow.id === assetId) : undefined
}

export { calculateInfoPanelData, exportAnalysisBacklogDataToCsv, filterFaultedDataByType, getAssetRow }
