import imx8Image from '../images/imx8.webp'
import imx16Image from '../images/imx16.webp'
import { FC } from 'react'
import { DeviceModel } from '@/models/devicesAndSensors/types'
import { StyledImage } from '@/modules/devicesSensors/styled'

const SensorImage: FC<{ model: DeviceModel }> = ({ model }) => {
  return <StyledImage src={model === 'IMX-16' ? imx16Image : imx8Image} alt={model} />
}

export default SensorImage
