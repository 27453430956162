import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { DeviceInterface } from '@/models/devicesAndSensors/types'
import useResetTableFilters from '@/shared/components/TableComponents/hooks/useResetTableFilters'
import { exportDevicesAndSensorsDataToCSV } from '@/modules/devicesSensors/utils'
import ModuleHeader from '@/shared/components/ModuleHeader'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import {
  setModuleHeaderActionPanelData,
  setModuleHeaderCSVData,
  setModuleHeaderConfigData,
} from '@/store/moduleHeader/actions'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { MRT_TableInstance } from 'material-react-table'
import { FC } from 'react'
import { currentCustomerSelector } from '@/store/selectors/selectedCustomerSelector'

interface DevicesSensorsHeaderProps {
  table: MRT_TableInstance<DeviceInterface>
}

const DevicesSensorsHeader: FC<DevicesSensorsHeaderProps> = ({ table }) => {
  const devicesAndSensorsDispatch = useAppDispatch()
  const selectedCustomer = useTypedSelector((state) => currentCustomerSelector(state))
  const { fetchDataStatus } = useFetchDataContext()

  const { columnFilters, sorting, showSkeletons } = table.getState()
  const tableRows = useDeepCompareMemo(
    () => table.getFilteredRowModel().rows,
    [sorting, columnFilters, showSkeletons, showSkeletons, fetchDataStatus]
  )
  const inLoadingDataMode = useDeepCompareMemo(() => {
    return tableRows.every((row) => row.original.deviceNumber === null)
  }, [tableRows.length])

  const isTableFiltered = columnFilters.length > 0
  const isTableSorted = sorting.length > 0
  const shouldClearFilters = !(isTableFiltered || isTableSorted)

  const resetTableFilters = useResetTableFilters<DeviceInterface>(table)

  useDeepCompareEffect(() => {
    devicesAndSensorsDispatch(
      setModuleHeaderConfigData({
        showHeader: true,
        title: 'Devices and Sensors',
        showSubtitle: true,
        subtitle: 'IMX-8 / 16 Information',
        showInfoPanel: false,
        showActionPanel: true,
      })
    )
    devicesAndSensorsDispatch(
      setModuleHeaderActionPanelData({
        withLoader: true,
        withClearFiltersButton: true,
        isClearFiltersButtonDisabled: shouldClearFilters,
        clearFiltersButtonFn: resetTableFilters,
        withRefreshButton: true,
        withCSVDownloadButton: true,
        withTableStateChangeSwitch: false,
        isWithUserEvents: !showSkeletons,
      })
    )
  }, [showSkeletons, shouldClearFilters])

  useDeepCompareEffect(() => {
    if (tableRows.length > 0 && !inLoadingDataMode && selectedCustomer) {
      devicesAndSensorsDispatch(
        setModuleHeaderCSVData({
          csvData: exportDevicesAndSensorsDataToCSV(
            tableRows.map((row) => {
              const rowData = row.original
              const channels = rowData.channels || []
              const statusFilter = columnFilters.find((f) => f.id === 'status')?.value as string[]

              const filteredChannels = statusFilter
                ? channels.filter((c) => statusFilter.some((f) => f === c.status))
                : channels

              const rowWithFilteredChannels = structuredClone(rowData)
              rowWithFilteredChannels.channels = filteredChannels
              return rowWithFilteredChannels
            }),
            selectedCustomer.name,
            columnFilters
          ),
          moduleName: 'Devices and Sensors',
        })
      )
    }
  }, [inLoadingDataMode, selectedCustomer, columnFilters, tableRows.length])

  return <ModuleHeader />
}
export default DevicesSensorsHeader
