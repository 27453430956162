import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectAsyncPollerData = (state: RootState) => state.asyncPoller

const asyncPollerSelector = createSelector(_selectAsyncPollerData, (asyncPollerData) => ({
  ...asyncPollerData,
}))

export { asyncPollerSelector }
