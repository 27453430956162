import { ChannelStatusEnum, DeviceStatusEnum } from '@/models/devicesAndSensors/types'
import { channelStatusIcon, channelStatusString } from '@/modules/devicesSensors/utils/constants'
import { Icon, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface StatusCell {
  sensorStatus: ChannelStatusEnum | DeviceStatusEnum
}

const StatusCell: FC<StatusCell> = ({ sensorStatus }) => {
  const { icon, color } = channelStatusIcon[sensorStatus]
  const statusString = channelStatusString[sensorStatus]

  const [hoverRef, isHovered] = useTooltip()

  return (
    <>
      <div ref={hoverRef} style={{ padding: '0 2rem' }}>
        <Icon feIcon={icon} feColor={color} feSize="xs" />
      </div>

      <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
        {statusString}
      </Tooltip>
    </>
  )
}

export default StatusCell
