import { StyledBelongsToText } from '@/modules/analysisBacklog/styled'
import { FC } from 'react'

interface BelongsToCellProps {
  belongsTo: string
}

export const BelongsToCell: FC<BelongsToCellProps> = ({ belongsTo }) => {
  return <StyledBelongsToText>{belongsTo}</StyledBelongsToText>
}

export default BelongsToCell
