import networkClient from '@/api/NetworkClient'
import {
  // CreateHalMockCustomerRequest,
  // CreateHalMockCustomerResponse,
  // CreateHalMockCustomerResult,
  // GetRecommendationTypesRequest,
  // GetRecommendationTypesResponse,
  // GetRecommendationTypesResult,
  // GetReportCustomerNotesRequest,
  // GetReportCustomerNotesResponse,
  // GetReportCustomerNotesResult,
  GetReportFaultTypesRequest,
  GetReportFaultTypesResponse,
  GetReportFaultTypesResult,
  GetReportHistoryRequest,
  GetReportHistoryResponse,
  GetReportHistoryResult,
  PostReconfirmReportRequest,
  PostReconfirmReportResponse,
  PostReconfirmReportResult,
  PublishReportRequest,
  PublishReportResponse,
  PublishReportResult,
} from '@/api/paths/reportStatus/types'
import { PATHS } from '@/api/types'
import { AxiosError } from 'axios'

const getReportFaultTypes = async (
  handlers: GetReportFaultTypesResult,
  params: Record<string, string | undefined>
): Promise<GetReportFaultTypesResponse | void> => {
  try {
    const response = await networkClient.get<GetReportFaultTypesRequest, GetReportFaultTypesResponse>(
      PATHS.GET_REPORT_FAULT_TYPES,
      { params }
    )
    return response.data
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

const getReportsHistory = async (
  handlers: GetReportHistoryResult,
  params: Record<string, string | undefined>
): Promise<GetReportHistoryResponse | void> => {
  try {
    const response = await networkClient.get<GetReportHistoryRequest, GetReportHistoryResponse>(
      PATHS.GET_REPORT_HISTORY,
      { params }
    )
    handlers.onSuccess && handlers.onSuccess(response)
    return response
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

// const getCustomerNotes = async (
//   handlers: GetReportCustomerNotesResult,
//   params: Record<string, string | boolean | undefined>
// ): Promise<GetReportCustomerNotesResult | void> => {
//   try {
//     const response = await networkClient.get<GetReportCustomerNotesRequest, GetReportCustomerNotesResponse>(
//       PATHS.GET_CUSTOMER_NOTES,
//       { params }
//     )
//     handlers.onSuccess && handlers.onSuccess(response.data)
//     return response.data
//   } catch (error) {
//     handlers.onError && (await handlers.onError(error as AxiosError))
//   }
// }

const publishReport = async (
  request: PublishReportRequest,
  params: Record<string, string>
): Promise<PublishReportResult | void> => {
  const response = await networkClient.post<PublishReportRequest, PublishReportResponse>(
    PATHS.POST_PUBLISH_REPORT,
    request,
    { params }
  )
  return response.data
}

const reconfirmReport = async (
  handlers: PostReconfirmReportResult,
  params: Record<string, string | undefined>
): Promise<PostReconfirmReportResponse | void> => {
  try {
    const response = await networkClient.post<PostReconfirmReportRequest, PostReconfirmReportResponse>(
      PATHS.POST_RECONFIRM_REPORT,
      undefined,
      { params }
    )
    handlers.onSuccess && handlers.onSuccess(response.data)
    return response.data
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

// const saveHalMockCustomerNote = async (
//   handlers: CreateHalMockCustomerResult,
//   request: CreateHalMockCustomerRequest
// ): Promise<CreateHalMockCustomerResponse | void> => {
//   try {
//     await networkClient.post<CreateHalMockCustomerRequest, CreateHalMockCustomerResponse>(
//       PATHS.POST_MOCK_CUSTOMER_NOTE,
//       request
//     )
//     handlers.onSuccess && handlers.onSuccess()
//   } catch (error) {
//     handlers.onError && (await handlers.onError(error as AxiosError))
//   }
// }

// const getRecommendationTypes = async (
//   handlers: GetRecommendationTypesResult
// ): Promise<GetRecommendationTypesResult | void> => {
//   try {
//     await getAccessToken()
//     const response = await networkClient.get<GetRecommendationTypesRequest, GetRecommendationTypesResponse>(
//       PATHS.GET_RECOMMENDATION_TYPES
//     )
//     handlers.onSuccess && handlers.onSuccess(response.data)
//     return response.data
//   } catch (error) {
//     handlers.onError && (await handlers.onError(error as AxiosError))
//   }
// }

export {
  getReportFaultTypes,
  getReportsHistory,
  // getCustomerNotes,
  publishReport,
  reconfirmReport /*getRecommendationTypes*/,
  // saveHalMockCustomerNote,
}
