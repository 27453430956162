import { RedirectDataAction, RedirectDataState } from '@/store/redirectData/types'

export const initialState: RedirectDataState = {}

type RedirectDataReducer = (state: RedirectDataState | undefined, action: RedirectDataAction) => RedirectDataState

const manageRedirectDataReducer: RedirectDataReducer = (state = initialState, action) => {
  if (action.type === 'PUSH_REDIRECT_DATA') {
    const { sid, ...restPayload } = action.payload
    return {
      ...state,
      [sid]: restPayload,
    }
  }

  if (action.type === 'DELETE_REDIRECT_DATA') {
    const { [action.payload]: _, ...restState } = state
    return restState
  }

  return state
}

export default manageRedirectDataReducer
