import { FC } from 'react'
import { Flexbox, Heading, Text } from '@skf-internal/ui-components-react'
import { dataTestId } from '@/tests/testid'
import {
  StyledPDFPreviewHiddenComponentWrapper,
  StyledReportInfoPageAssetStatusIconContainer,
  StyledReportInfoPageContainer,
  StyledReportInfoPageContainerRow,
  StyledReportInfoPageLastMeasurementReportContainer,
  StyledReportInfoPageLastMeasurementReportRow,
  StyledReportInfoPageLegendContainer,
  StyledReportInfoPageLegendHeader,
  StyledReportInfoPageLegendHeading,
  StyledReportInfoPageLegendRow,
  StyledStripeBox,
} from '@/modules/report-generator/styled'
import AssetStatusIcon from '@/shared/components/AssetStatusIcon'

const InfoPage: FC = () => {
  return (
    <StyledPDFPreviewHiddenComponentWrapper id={dataTestId.generateReportPDFPreviewInfoPage}>
      <StyledReportInfoPageContainer feAlignItems="start" feFlexDirection="column" feJustifyContent="flex-start">
        <Flexbox feFlexDirection="row">
          <div>
            <Heading as="h1">Information Page</Heading>
          </div>
        </Flexbox>

        <StyledReportInfoPageContainerRow feFlexDirection="row">
          <div>
            <Heading as="h3">Explanation of Summary Charts:</Heading>
            <p>
              <Text feFontSize="sm">
                A complete overview of the assets in the program and their current status, as well as a historical trend
                of asset status over time. Asset status is color-coded to indicate the urgency of recommended actions
                from the SKF analyst. If no machine data was collected, the asset retains the last status from the
                previous reporting period. A summary of open recommended actions and overview of different identified
                faults is presented to provide insight about the type and amount of different actions which should be
                taken to bring assets into their optimum operating condition.
              </Text>
            </p>
          </div>
        </StyledReportInfoPageContainerRow>
        <StyledReportInfoPageContainerRow feFlexDirection="row">
          <div>
            <Heading as="h3">Explanation of Summarized Asset Health Report:</Heading>
            <p>
              <Text feFontSize="sm">
                For each asset in the program, this report provides a historical summary of the asset’s health status
                along with high level insights about the asset’s current status.
              </Text>
            </p>
          </div>
        </StyledReportInfoPageContainerRow>
        <StyledReportInfoPageContainerRow feFlexDirection="row">
          <div>
            <Heading as="h3">Explanation of Detailed Asset Health Report:</Heading>
            <p>
              <Text feFontSize="sm">
                A comprehensive report details the current condition of each asset, including observations and recent
                trends. For any asset requiring action, the SKF analyst provides detailed recommended actions and
                priority.
              </Text>
            </p>
          </div>
        </StyledReportInfoPageContainerRow>
        <StyledReportInfoPageLastMeasurementReportContainer feFlexDirection="column" feGap="xxs">
          <Heading as="h3">Explanation of Last Measurement Report:</Heading>
          <Text feFontSize="sm">
            This report lists the current measurements and readings obtained by asset at each measurement point.
          </Text>
          <StyledReportInfoPageLastMeasurementReportRow feFlexDirection="row">
            <Text feFontSize="sm">Velocity = in/s, mm/s</Text>
          </StyledReportInfoPageLastMeasurementReportRow>
          <StyledReportInfoPageLastMeasurementReportRow feFlexDirection="row">
            <Text feFontSize="sm">
              Acceleration = g, in/s<sup>2</sup>, m/s<sup>2</sup>
            </Text>
          </StyledReportInfoPageLastMeasurementReportRow>
          <StyledReportInfoPageLastMeasurementReportRow feFlexDirection="row">
            <Text feFontSize="sm">Enveloped Acceleration = gE</Text>
          </StyledReportInfoPageLastMeasurementReportRow>
        </StyledReportInfoPageLastMeasurementReportContainer>
        <StyledReportInfoPageContainerRow feFlexDirection="row">
          <div>
            <Heading as="h3">Recommended Actions:</Heading>
            <p>
              <Text feFontSize="sm">
                A list of specific actions suggested by SKF to repair and extend the life of the asset.
              </Text>
            </p>
          </div>
        </StyledReportInfoPageContainerRow>
        <StyledReportInfoPageContainerRow feFlexDirection="row">
          <StyledReportInfoPageLegendContainer feFlexDirection="column" style={{ height: '33rem' }}>
            <StyledReportInfoPageLegendHeading as="h3">Legend:</StyledReportInfoPageLegendHeading>
            <StyledReportInfoPageContainerRow feFlexDirection="column" feJustifyContent="justify-content">
              <StyledReportInfoPageLegendHeader feFlexDirection="row" feJustifyContent="justify-content" feGap="xl">
                <StyledReportInfoPageContainerRow>
                  <Text feFontSize="sm"> Asset Status Definition</Text>
                </StyledReportInfoPageContainerRow>
              </StyledReportInfoPageLegendHeader>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="center">
                  <Flexbox feFlexDirection="column">
                    <AssetStatusIcon value="never-reported" />
                  </Flexbox>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">Never Reported</Heading>
                    <Text feFontSize="sm">No status has ever been reported by an analyst.</Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="center">
                  <Flexbox feFlexDirection="column">
                    <AssetStatusIcon value="normal" />
                  </Flexbox>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">Normal</Heading>
                    <Text feFontSize="sm">The asset is operating in a healthy way as expected.</Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="center">
                  <StyledReportInfoPageAssetStatusIconContainer feFlexDirection="column">
                    <AssetStatusIcon value="acceptable" />
                  </StyledReportInfoPageAssetStatusIconContainer>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">Acceptable</Heading>
                    <Text feFontSize="sm">
                      There may be early indicators although the asset is currently still healthy.
                    </Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="center">
                  <StyledReportInfoPageAssetStatusIconContainer feFlexDirection="column">
                    <AssetStatusIcon value="unsatisfactory" />
                  </StyledReportInfoPageAssetStatusIconContainer>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">Unsatisfactory</Heading>
                    <Text feFontSize="sm">
                      The asset shows signs of damage and some action should be taken to correct the issue.
                    </Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="center">
                  <StyledReportInfoPageAssetStatusIconContainer feFlexDirection="column">
                    <AssetStatusIcon value="unacceptable" />
                  </StyledReportInfoPageAssetStatusIconContainer>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">Unacceptable</Heading>
                    <Text feFontSize="sm">
                      The asset shows signs of damage and urgently needs some corrective action.
                    </Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="center">
                  <StyledReportInfoPageAssetStatusIconContainer feFlexDirection="column">
                    <AssetStatusIcon value="severe" />
                  </StyledReportInfoPageAssetStatusIconContainer>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">Severe</Heading>
                    <Text feFontSize="sm">
                      The asset is at risk of eminent failure and needs corrective action immediately.
                    </Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
              <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="xl">
                <StyledReportInfoPageLegendRow feFlexDirection="row" feGap="md" feAlignItems="end">
                  <Flexbox feFlexDirection="column">
                    <StyledStripeBox />
                  </Flexbox>
                  <Flexbox feFlexDirection="column">
                    <Heading as="h3">No Report in the Month</Heading>
                    <Text feFontSize="sm">
                      This asset did not receive a status report in the month shown. Its status is assumed to be
                      unchanged from the prior month.
                    </Text>
                  </Flexbox>
                </StyledReportInfoPageLegendRow>
              </StyledReportInfoPageLegendRow>
            </StyledReportInfoPageContainerRow>
          </StyledReportInfoPageLegendContainer>
        </StyledReportInfoPageContainerRow>
      </StyledReportInfoPageContainer>
    </StyledPDFPreviewHiddenComponentWrapper>
  )
}

export default InfoPage
