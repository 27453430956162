import { FC } from 'react'
import { Flexbox, Heading, Text } from '@skf-internal/ui-components-react'
import {
  StyledPDFPreviewHiddenComponentWrapper,
  StyledReportCoverPageContainer,
  StyledReportCoverPageContainerRow,
  StyledReportGeneratorLabel,
} from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import { DateFormats, formatDate } from '@/shared/dateUtils'
import { ReportGeneratorApiResponse } from '@/models/reportGenerator/types'

interface CoverPageProps {
  coverPageDetails: ReportGeneratorApiResponse['coverPage']
}

const CoverPage: FC<CoverPageProps> = ({ coverPageDetails }) => {
  const { assetReportFilters } = useReportGeneratorContext()

  return (
    <StyledPDFPreviewHiddenComponentWrapper id={dataTestId.generateReportPDFPreviewCoverPage}>
      <StyledReportCoverPageContainer feAlignItems="center" feFlexDirection="column" feJustifyContent="center">
        <StyledReportCoverPageContainerRow feAlignItems="center" feFlexDirection="row" feJustifyContent="center">
          <Heading as="h2" data-testid={dataTestId.generateReportCoverPageHeader}>
            Asset Health Report
          </Heading>
        </StyledReportCoverPageContainerRow>
        <StyledReportCoverPageContainerRow
          feAlignItems="baseline"
          feFlexDirection="row"
          feJustifyContent="space-between"
        >
          <Flexbox>
            <Text feFontSize="xxl" data-testid={dataTestId.generateReportCoverPageLabel1}>
              Prepared for:
            </Text>
          </Flexbox>
          <Flexbox>
            <StyledReportGeneratorLabel data-testid={dataTestId.generateReportCoverPageValue1}>
              {coverPageDetails.companyName}
            </StyledReportGeneratorLabel>
          </Flexbox>
        </StyledReportCoverPageContainerRow>
        <StyledReportCoverPageContainerRow
          feAlignItems="baseline"
          feFlexDirection="row"
          feJustifyContent="space-between"
        >
          <Flexbox>
            <Text feFontSize="xxl" data-testid={dataTestId.generateReportCoverPageLabel2}>
              Site:
            </Text>
          </Flexbox>
          <Flexbox>
            <StyledReportGeneratorLabel data-testid={dataTestId.generateReportCoverPageValue2}>
              {coverPageDetails.siteName}
            </StyledReportGeneratorLabel>
          </Flexbox>
        </StyledReportCoverPageContainerRow>
        <StyledReportCoverPageContainerRow
          feAlignItems="baseline"
          feFlexDirection="row"
          feJustifyContent="space-between"
        >
          <Flexbox>
            <Text feFontSize="xxl" data-testid={dataTestId.generateReportCoverPageLabel3}>
              Report created by:
            </Text>
          </Flexbox>
          <Flexbox>
            <StyledReportGeneratorLabel data-testid={dataTestId.generateReportCoverPageValue3}>
              {coverPageDetails.createdUser}
            </StyledReportGeneratorLabel>
          </Flexbox>
        </StyledReportCoverPageContainerRow>
        <StyledReportCoverPageContainerRow
          feAlignItems="baseline"
          feFlexDirection="row"
          feJustifyContent="space-between"
        >
          <Flexbox>
            <Text feFontSize="xxl" data-testid={dataTestId.generateReportCoverPageLabel4}>
              Report date:
            </Text>
          </Flexbox>
          <Flexbox>
            <StyledReportGeneratorLabel data-testid={dataTestId.generateReportCoverPageValue4}>
              {formatDate(assetReportFilters.reportDate, DateFormats.LongMonthDayYear)}
            </StyledReportGeneratorLabel>
          </Flexbox>
        </StyledReportCoverPageContainerRow>
      </StyledReportCoverPageContainer>
    </StyledPDFPreviewHiddenComponentWrapper>
  )
}

export default CoverPage
