import useOverviewColumns from '@/modules/overview/columns/useOverviewColumns'
import { useMaterialReactTable } from 'material-react-table'
import {
  overviewTableBodyCellProps,
  overviewTableBodyRowProps,
  overviewTableContainerProps,
  overviewTableHeadCellProps,
  overviewTableHeadRowProps,
  overviewTablePaperProps,
  overviewTableProps,
} from '@/modules/overview/styled/tableSx'
import ActionMenu from '@/modules/overview/components/actionMenu'
import { useOverviewContext } from '@/contexts/overview'

const useOverviewTable = () => {
  const columns = useOverviewColumns()
  const { overviewData } = useOverviewContext()

  const overviewTable = useMaterialReactTable({
    columns,
    data: overviewData,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    enableSorting: false,
    enableFilters: false,
    enableRowActions: true,
    positionActionsColumn: 'last',
    state: {
      showSkeletons: overviewData.length === 0,
    },
    renderRowActions: ({ row }) => {
      return <ActionMenu siteId={row.original.siteId} />
    },
    renderEmptyRowsFallback: () => <div />,
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
      },
    },
    muiTableHeadRowProps: overviewTableHeadRowProps,
    muiTableBodyRowProps: overviewTableBodyRowProps,
    muiTablePaperProps: overviewTablePaperProps,
    muiTableProps: overviewTableProps,
    muiTableContainerProps: overviewTableContainerProps,
    defaultColumn: {
      muiTableBodyCellProps: overviewTableBodyCellProps,
      muiTableHeadCellProps: overviewTableHeadCellProps,
    },
  })
  return overviewTable
}

export default useOverviewTable
