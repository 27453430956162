import { SiteDetailsData, UpdateSiteDetailsRequestType } from '@/models/siteDetails/types'
import { updateSiteDetails } from '@/api/paths/sites/siteDetails'
import { useSiteDetailsContext } from '@/contexts/siteDetails'
import { LoadingType, useApi } from '@/shared/hooks/useApi'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'

const useUpdateSiteDetails = (SiteDetailsData: SiteDetailsData) => {
  const { siteDetailsError, setSiteDetailsError, setIsSiteDetailsEdited } = useSiteDetailsContext()
  const { fetchDataFn } = useFetchDataContext()

  const { sid } = useParams()
  return useApi(() => {
    const uploadRequest = {
      siteAddress: SiteDetailsData.siteAddress,
      logo: {
        fileId: SiteDetailsData.logo?.fileId,
      },
    } as UpdateSiteDetailsRequestType

    return updateSiteDetails(
      uploadRequest,
      {
        onSuccess: async () => {
          fetchDataFn({ loaderType: LoadingType.GLOBAL })
          setIsSiteDetailsEdited(false)
        },
        onError: async (error: AxiosError) => {
          setSiteDetailsError({
            ...siteDetailsError,
            updateSiteDetailsError: error.message,
          })
        },
      },
      {
        sid,
      }
    )
  })
}

export default useUpdateSiteDetails
