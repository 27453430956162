import { OverviewResponse } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import { overviewCellTooltips } from '@/modules/overview/utils/constants'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useOpenRecommendationData = () => {
  const openRecommendation = useMemo<MRT_ColumnDef<OverviewResponse['sites'][number]>[]>(
    () => [
      {
        accessorKey: 'unresolvedRecommendations.open',
        id: 'openRecommendationsData',
        header: '',
        size: 20,
        Cell: ({ cell, row }) =>
          OverviewCell({
            siteId: row.original.siteId,
            value: cell.getValue<string>(),
            routeTo: ROUTES.OPEN_RECOMMENDATIONS,
            message: overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips],
          }),
      },
    ],
    []
  )
  return openRecommendation
}

export default useOpenRecommendationData
