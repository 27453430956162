import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectGenericConfirmationDialog = (state: RootState) => state.genericConfirmDialogData

const genericConfirmationDialogSelector = createSelector(
  _selectGenericConfirmationDialog,
  (genericConfirmDialogData) => ({ ...genericConfirmDialogData })
)

export { genericConfirmationDialogSelector }
