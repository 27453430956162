import { AssetColors } from '@/modules/dashboard/styled/colors'
import {
  AssetHealthFault,
  FaultStatusType,
  FaultsApiData,
  RecommendedAction,
  Faults,
} from '@/models/widgets/asset-health-fault/types'
import { formatDate, DateFormats } from '@/shared/dateUtils'
import { FaultTypeSelectOption, Action } from '@/models/dashboard/globalFiltering/types'
import { getMappedValue } from '@/modules/unresolved-recommendation/utils'
import { FaultTypes } from '@/models/reportStatus/types'
import { extractDateFromTimestamp } from '@/shared/utils'

const getStatusType = (status: string): FaultStatusType => {
  switch (status) {
    case 'severe':
      return 'severe'
    case 'unacceptable':
      return 'unacceptable'
    case 'unsatisfactory':
      return 'unsatisfactory'
    case 'normal':
      return 'normal'
    case 'acceptable':
      return 'acceptable'
    default:
      return 'normal' // Default to Normal if status is unknown
  }
}

const modifyFaultsData = (originalData: FaultsApiData[]): FaultsApiData[] => {
  return originalData.map(({ faultType, count, assets }) => {
    const assetHealthFaults: AssetHealthFault[] = assets.map((asset) => {
      const status = getStatusType(asset.status)
      const lastFaultCreatedDate = asset.lastFaultCreatedDate
        ? formatDate(asset.lastFaultCreatedDate, DateFormats.AmericanDateFormat)
        : '-'
      const faults: Faults[] = asset.faults.map((fault) => {
        const recommendedActions: RecommendedAction[] = fault.recommendedActions.map((action) => {
          return {
            ...action,
            openDate: action.openDate ? formatDate(action.openDate, DateFormats.AmericanDateFormat) : '-',
            dueDate: action.dueDate
              ? formatDate(extractDateFromTimestamp(action.dueDate), DateFormats.AmericanDateFormat)
              : '-',
          }
        })
        return {
          ...fault,
          recommendedActions,
        }
      })
      return {
        ...asset,
        status,
        lastFaultCreatedDate,
        faults,
      }
    })

    return {
      faultType,
      count,
      assets: assetHealthFaults,
    }
  })
}

const machineHealthFaultColor = (type: FaultStatusType) => {
  switch (type) {
    case 'severe':
      return AssetColors.severe
    case 'unacceptable':
      return AssetColors.unacceptable
    case 'unsatisfactory':
      return AssetColors.unsatisfactory
    case 'normal':
      return AssetColors.normal
    case 'acceptable':
      return AssetColors.acceptable
  }
}

const withOpacity = (color: string, opacity: number) => {
  return color.replaceAll('rgb', 'rgba').replaceAll(')', ', ' + opacity + ')')
}

const mapOpenFaultsData = (
  openFaults: FaultsApiData[],
  faultTypes: FaultTypes,
  type?: Action.FaultTypeSelectOptions
): FaultsApiData[] | FaultTypeSelectOption[] => {
  if (type === Action.FaultTypeSelectOptions) {
    const mappedOpenFaults = openFaults.map(({ faultType, count }) => ({
      faultType,
      title: getMappedValue(faultTypes, faultType),
      count,
    }))

    const existingFaultTypes = new Set(mappedOpenFaults.map((f) => f.faultType))

    const remainingFaultTypes = faultTypes.flatMap(({ code, text, subTypes }) => {
      if (subTypes.length > 0) {
        return subTypes
          .filter(({ code: subCode }) => !existingFaultTypes.has(subCode))
          .map(({ code: subCode, en }) => ({
            faultType: subCode,
            title: en,
            count: 0,
          }))
      }

      if (!existingFaultTypes.has(code)) {
        return [
          {
            faultType: code,
            title: text,
            count: 0,
          },
        ]
      }

      return []
    })

    return [...mappedOpenFaults, ...remainingFaultTypes]
  }

  return openFaults.map((faults) => ({
    ...faults,
    faultType: getMappedValue(faultTypes, faults.faultType),
  }))
}

const updateFaultTypeSelectOptions = (
  faultOptions: FaultTypeSelectOption[] = [],
  modifiedOpenFaultsData: FaultsApiData[] = []
) => {
  let countUpdated = false
  const faultTypeMap = new Map(faultOptions?.map((item) => [item.faultType, item]))
  modifiedOpenFaultsData?.forEach((modifiedItem) => {
    const faultTypeItem = faultTypeMap.get(modifiedItem.faultType)
    if (faultTypeItem && faultTypeItem.count !== modifiedItem.count) {
      faultTypeItem.count = modifiedItem.count
      countUpdated = true
    }
  })
  if (countUpdated) {
    return Array.from(faultTypeMap.values())
  } else return []
}

export { modifyFaultsData, machineHealthFaultColor, withOpacity, mapOpenFaultsData, updateFaultTypeSelectOptions }
