const overviewCellTooltips = {
  '1-3-days': 'Assets pending analysis for 1-3 days',
  '3-7-days': 'Assets pending analysis for 3-7 days',
  '>7-days': 'Assets pending analysis for 7+ days',
  timeBetweenData: 'Avg. days an asset is pending analysis',
  orphanNotesData: 'Orphan notes pending acknowledgment',
  openRecommendationsData: 'Count of open recommendations',
  overdueRecommendationsData: 'Count of overdue recommendations',
  sensorsData: 'Count of faulted sensors',
  devicesData: 'Count of faulted devices',
  autoDiagnosticsData: 'Percentage of automatically diagnosed assets',
}

export { overviewCellTooltips }
