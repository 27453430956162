import { Dashboard, DashboardLayout, DashboardWidget } from '@/models/dashboard/types'
import { ConditionStatusWidgetDataType } from '@/models/widgets/asset-health-condition/types'
import { FaultsApiData } from '@/models/widgets/asset-health-fault/types'
import { RecommendedActionsDueDatesStatusType, RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import { FaultTypeSelectOption, SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'
import { DarwinWidgetError } from '@/shared/components/ErrorBoundary/DarwinError'
import { getEnumKeys } from '@/shared/utils'
import { AssetStatus, RecommendedActionStatus } from '@/shared/models/types'

export interface IDashboardContext {
  dashboards: Dashboard[]
  setDashboards: React.Dispatch<React.SetStateAction<IDashboardContext['dashboards']>>
  selectedDashboard: Dashboard
  setSelectedDashboard: React.Dispatch<React.SetStateAction<IDashboardContext['selectedDashboard']>>
  selectedDashboardLayouts: DashboardLayout[]
  setSelectedDashboardLayouts: React.Dispatch<React.SetStateAction<IDashboardContext['selectedDashboardLayouts']>>
  selectedDashboardWidgets: DashboardWidget[]
  setSelectedDashboardWidgets: React.Dispatch<React.SetStateAction<IDashboardContext['selectedDashboardWidgets']>>
  faultTypeSelectOptions: FaultTypeSelectOption[]
  setFaultTypeSelectOptions: React.Dispatch<React.SetStateAction<IDashboardContext['faultTypeSelectOptions']>>
  dashboardFilters: SelectFilterType
  setDashboardFilters: React.Dispatch<React.SetStateAction<IDashboardContext['dashboardFilters']>>
  widgetAssetHealthConditionData: ConditionStatusWidgetDataType
  setWidgetAssetHealthConditionData: React.Dispatch<
    React.SetStateAction<IDashboardContext['widgetAssetHealthConditionData']>
  >
  widgetAssetHealthFaultData: FaultsApiData[]
  setWidgetAssetHealthFaultData: React.Dispatch<React.SetStateAction<IDashboardContext['widgetAssetHealthFaultData']>>
  widgetRecommendedActions: RAwidgetDataType
  setWidgetRecommendedActions: React.Dispatch<React.SetStateAction<IDashboardContext['widgetRecommendedActions']>>
  recommendedActionsDueDatesStatus: RecommendedActionsDueDatesStatusType[]
  setRecommendedActionsDueDatesStatus: React.Dispatch<
    React.SetStateAction<IDashboardContext['recommendedActionsDueDatesStatus']>
  >
  modifiedOpenFaultsData: FaultsApiData[]
  setModifiedOpenFaultsData: React.Dispatch<React.SetStateAction<IDashboardContext['modifiedOpenFaultsData']>>
  widgetErrors: DarwinWidgetError[]
  setWidgetErrors: React.Dispatch<React.SetStateAction<IDashboardContext['widgetErrors']>>
}

export const DashboardContext = createContext<IDashboardContext | null>(null)

export const useDashboardContext = () =>
  useContextWrapper(DashboardContext, {
    contextName: useDashboardContext.name,
    providerName: DashboardContextProvider.name,
  })

export const DashboardContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [selectedDashboard, setSelectedDashboard] = useState<IDashboardContext['selectedDashboard']>({} as Dashboard)

  const [dashboards, setDashboards] = useState<IDashboardContext['dashboards']>([])
  const [faultTypeSelectOptions, setFaultTypeSelectOptions] = useState<FaultTypeSelectOption[]>([])

  const [selectedDashboardLayouts, setSelectedDashboardLayouts] = useState<
    IDashboardContext['selectedDashboardLayouts']
  >([])

  const [selectedDashboardWidgets, setSelectedDashboardWidgets] = useState<
    IDashboardContext['selectedDashboardWidgets']
  >([])

  const [dashboardFilters, setDashboardFilters] = useState<IDashboardContext['dashboardFilters']>({
    raStatus: getEnumKeys(RecommendedActionStatus, ['completed', 'rejected']),
    faultType: [],
    assetStatus: getEnumKeys(AssetStatus, ['never-reported']),
  })

  const [widgetAssetHealthConditionData, setWidgetAssetHealthConditionData] = useState<
    IDashboardContext['widgetAssetHealthConditionData']
  >([])

  const [widgetAssetHealthFaultData, setWidgetAssetHealthFaultData] = useState<
    IDashboardContext['widgetAssetHealthFaultData']
  >([])

  const [widgetRecommendedActions, setWidgetRecommendedActions] = useState<
    IDashboardContext['widgetRecommendedActions']
  >([])

  const [recommendedActionsDueDatesStatus, setRecommendedActionsDueDatesStatus] = useState<
    IDashboardContext['recommendedActionsDueDatesStatus']
  >([])

  const [modifiedOpenFaultsData, setModifiedOpenFaultsData] = useState<IDashboardContext['modifiedOpenFaultsData']>([])

  const [widgetErrors, setWidgetErrors] = useState<DarwinWidgetError[]>([])

  const value = useMemo(
    () => ({
      selectedDashboard,
      setSelectedDashboard,
      dashboards,
      setDashboards,
      selectedDashboardLayouts,
      setSelectedDashboardLayouts,
      selectedDashboardWidgets,
      setSelectedDashboardWidgets,
      faultTypeSelectOptions,
      setFaultTypeSelectOptions,
      dashboardFilters,
      setDashboardFilters,
      widgetAssetHealthConditionData,
      setWidgetAssetHealthConditionData,
      widgetAssetHealthFaultData,
      setWidgetAssetHealthFaultData,
      widgetRecommendedActions,
      setWidgetRecommendedActions,
      recommendedActionsDueDatesStatus,
      setRecommendedActionsDueDatesStatus,
      modifiedOpenFaultsData,
      setModifiedOpenFaultsData,
      widgetErrors,
      setWidgetErrors,
    }),
    [
      selectedDashboard,
      dashboards,
      selectedDashboardLayouts,
      selectedDashboardWidgets,
      faultTypeSelectOptions,
      dashboardFilters,
      widgetAssetHealthConditionData,
      widgetAssetHealthFaultData,
      widgetRecommendedActions,
      recommendedActionsDueDatesStatus,
      modifiedOpenFaultsData,
      widgetErrors,
    ]
  )

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}
