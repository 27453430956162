/* eslint-disable @typescript-eslint/ban-ts-comment */
import { InitialAsset } from '@/models/analysisBacklog/types'
import AnalysisReasonCell from '@/modules/analysisBacklog/components/cell/analysisReason'
import AssetNameCell from '@/modules/analysisBacklog/components/cell/assetName'
import AssetStatusCell from '@/modules/analysisBacklog/components/cell/assetStatus'
import BelongsToCell from '@/modules/analysisBacklog/components/cell/belongsTo'
import DateCell from '@/modules/analysisBacklog/components/cell/date'
import PriorityCell from '@/modules/analysisBacklog/components/cell/priorityCell'
import { filterRegistry } from '@/shared/components/TableComponents/GenericTableFilter/constants'
import { createTableSortingOrderMap, sortingRegistry } from '@/shared/components/TableComponents/sortingFunction'
import { analysisBacklogAssetStatusSortingOrder } from '@/shared/components/TableComponents/sortingFunction/constants'
import TableColumn from '@/shared/components/TableComponents/tableColumn'
import { AssetStatusString } from '@/shared/models/types'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useAnalysisBacklogColumns = () => {
  const columns = useMemo<MRT_ColumnDef<InitialAsset>[]>(
    () => [
      {
        id: 'priority',
        accessorKey: 'priority',
        header: '',
        Cell: ({ row }) => <PriorityCell {...row.original} />,
        enableColumnFilter: false,
        grow: false,
        size: 50,
      },
      {
        id: 'assetName',
        accessorKey: 'name',
        header: 'Asset Name',
        Header: ({ column }) => <TableColumn column={column} />,
        Cell: ({ cell, row }) => (
          <AssetNameCell assetName={cell.getValue<string>()} hierarchy={row.original.belongsTo.hierarchyPath} />
        ),
        size: 210,
        grow: false,
        enableColumnFilter: true,
        enableFilterMatchHighlighting: false,
        enableColumnFilterModes: false,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'belongsTo',
        accessorKey: 'belongsTo.name',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Belongs to',
        Cell: ({ cell }) => {
          return <BelongsToCell belongsTo={cell.getValue<string>()} />
        },
        size: 210,
        grow: false,
        enableColumnFilter: true,
        //@ts-ignore
        filterFn: filterRegistry.text.filterFn,
        meta: {
          filterType: 'text',
        },
      },
      {
        id: 'collectedDate',
        accessorKey: 'lastCollectedDateTime',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Collected',
        Cell: ({ cell }) => <DateCell date={cell.getValue<string | null>()} />,
        size: 170,
        grow: false,
        enableColumnFilter: true,
        //@ts-ignore
        sortingFn: sortingRegistry.date<InitialAsset>,
        //@ts-ignore
        filterFn: filterRegistry.date.filterFn,
        meta: {
          filterType: 'date',
        },
      },
      {
        id: 'reportedDate',
        accessorKey: 'lastReportedDateTime',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Reported',
        Cell: ({ cell }) => <DateCell date={cell.getValue<string>()} />,
        size: 170,
        enableColumnFilter: true,
        grow: false,
        //@ts-ignore
        sortingFn: sortingRegistry.date<InitialAsset>,
        //@ts-ignore
        filterFn: filterRegistry.date.filterFn,
        meta: {
          filterType: 'date',
        },
      },
      {
        id: 'status',
        accessorKey: 'status',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Status',
        Cell: ({ cell }) => <AssetStatusCell assetStatus={cell.getValue<AssetStatusString>()} />,
        size: 150,
        grow: false,
        enableColumnFilter: true,
        //@ts-ignore
        sortingFn: (rowA: Row<InitialAsset>, rowB: Row<InitialAsset>, columnId: string) =>
          sortingRegistry.status<InitialAsset>(
            rowA,
            rowB,
            columnId,
            createTableSortingOrderMap(analysisBacklogAssetStatusSortingOrder)
          ),
        //@ts-ignore
        filterFn: filterRegistry.status.filterFn,
        meta: {
          filterType: 'status',
        },
      },

      {
        id: 'analysisReason',
        accessorKey: 'analysisReasons',
        Header: ({ column }) => <TableColumn column={column} />,
        header: 'Analysis Reasons',
        Cell: ({ row }) =>
          row.original.analysisReasons && !row.original.isHandled && <AnalysisReasonCell {...row.original} />,
        enableColumnFilter: true,
        enableSorting: false,
        //@ts-ignore
        filterFn: filterRegistry.analysis_reason.filterFn,
        meta: {
          filterType: 'analysis_reason',
        },
        minSize: 225,
        maxSize: 500,
      },
    ],
    []
  )

  return columns
}

export default useAnalysisBacklogColumns
