import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectCurrentCustomer = (state: RootState) => state.selectedCustomer

const currentCustomerSelector = createSelector(_selectCurrentCustomer, (selectedCustomer) => {
  let result = undefined
  if (selectedCustomer && Object.keys(selectedCustomer).length > 0) {
    result = selectedCustomer
  }
  return result
})

export { currentCustomerSelector }
