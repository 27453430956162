import { RecommendedAction, ClosedRaOutcome } from '@/models/unresolvedRecommendations/types'
import AssetStatusIcon from '@/shared/components/AssetStatusIcon'
import { AssetStatusString } from '@/shared/models/types'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationAssetStatusProps {
  assetStatus: RecommendedAction['assetStatus']
  openRecommendationOutcome?: RecommendedAction['raOutcome'] | null
}

const OpenRecommendationAssetStatus: FC<OpenRecommendationAssetStatusProps> = ({
  openRecommendationOutcome,
  assetStatus,
}) => {
  return (
    <Flexbox>
      {openRecommendationOutcome ? (
        ClosedRaOutcome[openRecommendationOutcome]
      ) : (
        <AssetStatusIcon value={assetStatus as AssetStatusString} />
      )}
    </Flexbox>
  )
}

export default OpenRecommendationAssetStatus
