import PdfTemplate from '@/modules/report-generator/components/reports/pdftemplate'
import { useComponentToImage } from '@/modules/report-generator/hooks/useComponentToImage'
import { pdfStylesObj } from '@/modules/report-generator/utils/pdfStyles'
import { dataTestId } from '@/tests/testid'
import { Image, Styles, StyleSheet } from '@react-pdf/renderer'
import { FC, useEffect } from 'react'
import placeholderLogoSVG from '@/modules/site-details/assets/attachment_failed_4x.webp'

interface CoverPagePDFPreviewProps {
  logoUrl: string
}

const CoverPagePDFPreview: FC<CoverPagePDFPreviewProps> = ({ logoUrl = placeholderLogoSVG }) => {
  const {
    imageUrl: coverPageImage,
    generateImage,
    // isGenerating,
    // error,
  } = useComponentToImage(dataTestId.generateReportPDFPreviewCoverPage)
  const pdfStyles = StyleSheet.create(pdfStylesObj as Styles)

  useEffect(() => {
    generateImage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PdfTemplate>
      <Image
        src={logoUrl}
        style={pdfStyles.coverPagePreviewLogo}
        data-testid={dataTestId.generateReportCoverPageLogoImage}
      />
      <Image
        src={coverPageImage as string}
        style={pdfStyles.coverPagePreview}
        data-testid={dataTestId.generateReportCoverPageImage}
      />
    </PdfTemplate>
  )
}

export default CoverPagePDFPreview
