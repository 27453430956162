import { COLORS } from '@skf-internal/ui-components-react'

// PDF Styles
export const pdfStylesObj = {
  page: {
    padding: '10mm',
    backgroundColor: COLORS.white,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
    fontFamily: 'Oswald',
  },
  section: {
    marginBottom: 10,
  },
  divider: {
    width: '100%',
    border: `1px ${COLORS.gray400}`,
    height: '1px',
    margin: 1,
    backgroundColor: COLORS.gray400,
    opacity: 0.1,
  },
  pageHeaderContainer: {
    marginBottom: -7,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    Height: 'auto',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
    marginTop: 15,
    fontFamily: 'Oswald',
  },
  pageHeader: {
    color: ' #0F58D6',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '36px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  innerComponent: {
    padding: 10,
  },
  logo: {
    width: 150,
    height: 'auto',
    marginBottom: 20,
    alignSelf: 'center',
  },
  pageHeaderLogo: {
    width: 50,
    height: 'auto',
    marginBottom: 20,
    alignSelf: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  coverPagePreview: {
    width: 250,
    height: 180,
    alignSelf: 'center',
  },
  coverPagePreviewLogo: {
    marginTop: 40,
    paddingBottom: 25,
    marginBottom: -25,
    width: 137,
    height: 105,
    alignSelf: 'center',
  },
  infoPagePreview: {
    width: 530,
    height: 700,
    marginLeft: -8,
  },
}
