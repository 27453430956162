import { FC } from 'react'
import { useMsal } from '@azure/msal-react'
import { StyledDescriptionError, StyledErrorContainer, StyledGoBackButton, StyledTitleError } from './styled'
import ErrorImg from './assets/errorImg.webp'
import NoAccess from './assets/noAccess.webp'
import { dataTestId } from '@/tests/testid'
import { Image } from '@skf-internal/ui-components-react'
import { useDispatch } from 'react-redux'
import { clearError } from '@/store/errorHandler/actions'
import { ROUTES } from '@/shared/constants'
import { STORAGE_ERROR_PATH_NAME } from '@/shared/localStorageUtils'
import { TypeAccess } from '@/models/app/types'

/**
 * ErrorDisplay component displays error messages based on the type of access.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.text - The error message to display.
 * @param {TypeAccess} props.type - The type of access error ('access' or 'no-access').
 * @returns {JSX.Element} The rendered ErrorDisplay component.
 *
 * @example
 * <ErrorDisplay text="Unauthorized access" type="no-access" />
 */

interface ErrorDisplay {
  text: string
  type: TypeAccess
  hasRole: boolean
}

export const ErrorDisplay: FC<ErrorDisplay> = ({ text, type, hasRole }) => {
  const dispatchError = useDispatch()
  const { instance: msalInstance } = useMsal()
  const scopes = import.meta.env.VITE_AZURE_SILENCE_REQUEST_SCOPES.split(',')
  /**
   * Handles user sign-in using MSAL login redirect.
   *
   * @async
   * @function signIn
   * @returns {Promise<void>}
   */

  const signIn = async () => {
    const storedUrl = sessionStorage.getItem(STORAGE_ERROR_PATH_NAME)
    dispatchError(clearError())
    await msalInstance.loginRedirect({
      scopes,
      redirectStartPage: storedUrl || ROUTES.HOME,
    })
  }
  return (
    <>
      <StyledErrorContainer data-testid={dataTestId.errorDisplayContainer}>
        <Image src={type === 'access' ? ErrorImg : NoAccess} data-testid={dataTestId.errorDisplayImage} />
        <div>
          <StyledTitleError as="h1">{text}</StyledTitleError>
          {type === 'access' && (
            <StyledDescriptionError data-testid={dataTestId.errorDescription}>
              The server experienced an error and was unable to fulfill your request. If the issue continues, please
              report it, including this error message and the query that led to it.
            </StyledDescriptionError>
          )}
          {type === 'no-access' && !hasRole && (
            <StyledDescriptionError data-testid={dataTestId.noRoleMessage}>
              General error. Please contact the Compass team.
            </StyledDescriptionError>
          )}
          {type === 'no-access' && hasRole && (
            <StyledGoBackButton
              data-testid={dataTestId.errorDisplayBackButton}
              feIcon={{ position: 'left', feIcon: 'arrowLeft' }}
              as="button"
              onClick={() => signIn()}
            >
              Sign in
            </StyledGoBackButton>
          )}
        </div>
      </StyledErrorContainer>
    </>
  )
}
