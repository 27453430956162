import { createTheme, ThemeProvider } from '@mui/material'
import { FC, PropsWithChildren } from 'react'

const ThemeConsumer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ThemeProvider
      theme={createTheme({
        typography: {
          fontFamily: 'SKFSans, Arial, sans-serif',
          fontSize: 16,
        },
      })}
    >
      {children}
    </ThemeProvider>
  )
}

export default ThemeConsumer
