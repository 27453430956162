import { StyledPDFPreviewHiddenComponentWrapper } from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'
import { Logo } from '@skf-internal/ui-components-react'
import { FC } from 'react'

const PDFTemplateHeaderLogo: FC = () => {
  return (
    <StyledPDFPreviewHiddenComponentWrapper id={dataTestId.generateReportPDFPageHeaderLogo}>
      <Logo />
    </StyledPDFPreviewHiddenComponentWrapper>
  )
}

export default PDFTemplateHeaderLogo
