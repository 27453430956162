import { convertToDisplayValue } from '@/shared/utils'
import { mappedReportFaultTypeSelector } from '@/store/selectors/reportFaultTypesSelector'
import { useTypedSelector } from '@/store/store'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationsFaultTypeProps {
  faultType: string
}

const OpenRecommendationsFaultType: FC<OpenRecommendationsFaultTypeProps> = ({ faultType }) => {
  const mappedReportFaultType = useTypedSelector((state) => mappedReportFaultTypeSelector(state, faultType))

  return <Flexbox>{convertToDisplayValue(mappedReportFaultType)}</Flexbox>
}
export default OpenRecommendationsFaultType
