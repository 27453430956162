import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectModuleHeaderData = (state: RootState) => state.moduleHeaderData

const headerConfigSelector = createSelector(
  _selectModuleHeaderData,
  (moduleHeaderData) => moduleHeaderData.headerConfig
)

const headerActionPanelConfigSelector = createSelector(
  _selectModuleHeaderData,
  (moduleHeaderData) => moduleHeaderData.headerActionPanelConfig
)

const headerCSVDataSelector = createSelector(
  _selectModuleHeaderData,
  (moduleHeaderData) => moduleHeaderData.headerCSVData
)

export { headerConfigSelector, headerActionPanelConfigSelector, headerCSVDataSelector }
