import { FC, useCallback, useRef } from 'react'
import { StyledLabel, StyledMainOption, StyledMainOptions, StyledSelectIcon } from '../../styled'
import { Flexbox, Icon } from '@skf-internal/ui-components-react'
import { dataTestId } from '@/tests/testid'
import { FaultTypes } from './types'
import SubOptionsList from '@/modules/report-status/components/multiLevelSelect/subOptionsList'
import { isOptionSelected } from '../../utils/formUtils'

interface MainOptionsListProps {
  options: FaultTypes[]
  hoveredMainOption: string | null
  selectedMainOption: string | undefined
  selectedSubOption: string | undefined
  isMainOptionsVisible: boolean
  setHoveredMainOption: (value: string | null) => void
  handleMainOptionClick: (value: string) => void
  handleSubOptionClick: (value: string) => void
}

/**
 * A component that displays a list of main options, each of which may contain sub-options.
 * @param {MainOptionsListProps} props - The props for the component.
 * @returns {JSX.Element | null} The rendered component or null if main options are not visible.
 */

const MainOptionsList: FC<MainOptionsListProps> = ({
  options,
  hoveredMainOption,
  selectedMainOption,
  selectedSubOption,
  isMainOptionsVisible,
  setHoveredMainOption,
  handleMainOptionClick,
  handleSubOptionClick,
}) => {
  const leaveTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = useCallback(
    (value: string) => {
      if (leaveTimeoutRef.current) {
        clearTimeout(leaveTimeoutRef.current)
      }
      setHoveredMainOption(value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleMouseLeave = useCallback(() => {
    leaveTimeoutRef.current = setTimeout(() => {
      setHoveredMainOption(null)
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isMainOptionsVisible) return null
  return (
    <StyledMainOptions data-testid={dataTestId.mainOptionList}>
      {options.map((option) => (
        <StyledMainOption
          key={option.value}
          selected={isOptionSelected(option, selectedMainOption, selectedSubOption)}
          onMouseEnter={() => handleMouseEnter(option.value)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleMainOptionClick(option.value)}
          hasSubOption={option.subTypes?.some((sub) => sub.en)}
        >
          <Flexbox feAlignItems="center" feJustifyContent="space-between">
            {isOptionSelected(option, selectedMainOption, selectedSubOption) && (
              <StyledSelectIcon feIcon="check" feColor="gray" feSize="sm" />
            )}
            <StyledLabel>{option.label}</StyledLabel>
          </Flexbox>
          {option.subTypes?.length > 0 && <Icon feIcon="chevronRight" feColor="gray" feSize="sm" />}
          <SubOptionsList
            subOptions={option.subTypes}
            hoveredMainOption={hoveredMainOption}
            selectedSubOption={selectedSubOption}
            option={option}
            handleSubOptionClick={handleSubOptionClick}
          />
        </StyledMainOption>
      ))}
    </StyledMainOptions>
  )
}

export default MainOptionsList
