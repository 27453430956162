import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { RecommendedActionStatusType, SelectFilterType } from '@/models/dashboard/globalFiltering/types'
import { Dashboard } from '@/models/dashboard/types'
import { ConditionStatusWidgetDataType } from '@/models/widgets/asset-health-condition/types'
import {
  severities as allAssetStatusValues,
  globalFiltersRecommendationStatuses,
} from '@/modules/dashboard/utils/constants'
import { CallbackDataParams } from 'echarts/types/dist/shared'
import { Dispatch, SetStateAction, useMemo } from 'react'

const getDefaultDashboardToShow = (dashboards: Dashboard[] = []): Dashboard => {
  const result = dashboards.find((dashboard) => dashboard.isDefault)

  if (result) {
    return result
  } else {
    throw new DarwinError({
      message: 'No dashboards were returned or no default dashboard was found',
      status: 422,
      isAxiosError: false,
      name: '',
      toJSON: () => ({}),
    })
  }
}

const tableCellFormatter = (value: number, decimals: number, showPercentages: boolean) => {
  if (typeof value === 'string') parseInt(value)
  const factor = Math.pow(10, decimals)
  return Math.round(value * factor) / factor + (showPercentages ? '%' : '')
}

const clearDashboardGlobalFilters = (
  allFaultTypeValues: string[],
  setDashboardFilters: Dispatch<SetStateAction<SelectFilterType>>
) => {
  const clearedFilters = {
    raStatus: globalFiltersRecommendationStatuses as RecommendedActionStatusType[],
    faultType: allFaultTypeValues,
    assetStatus: allAssetStatusValues,
  } as SelectFilterType
  setDashboardFilters(clearedFilters)
}

const useNoDataInAllMonths = (slices: ConditionStatusWidgetDataType): boolean => {
  return useMemo(() => {
    return slices.every((item) => Object.keys(item.data).length === 0)
  }, [slices])
}

const generateLast12MonthsData = (): ConditionStatusWidgetDataType => {
  const currentDate = new Date()
  const data: ConditionStatusWidgetDataType = []

  for (let i = 0; i < 12; i++) {
    const month = currentDate.getMonth() + 1 // getMonth() returns 0-based month
    const year = currentDate.getFullYear()
    data.unshift({
      period: { year, month },
      data: {},
    })

    // Move to the previous month
    currentDate.setMonth(currentDate.getMonth() - 1)
  }

  return data
}

const truncateValue = (str: string | number, maxLength: number = 5): string => {
  const stringValue = String(str)
  return stringValue.length > maxLength ? stringValue.slice(0, maxLength) : stringValue
}

const truncateChartAxisValue = (value: number | string) => {
  const stringValue = String(value)
  return stringValue.length > 5 ? stringValue.slice(0, 5) + '...' : stringValue
}

const pieChartTooltipFormatter = (params: CallbackDataParams | CallbackDataParams[], showPercentages: boolean) => {
  const singleParam = Array.isArray(params) ? params[0] : params
  const label = singleParam.name.charAt(0).toUpperCase() + singleParam.name.slice(1)

  return showPercentages
    ? `${label} : ${singleParam.percent}%`
    : `${label} : ${singleParam.value} (${singleParam.percent}%)`
}

export {
  clearDashboardGlobalFilters,
  generateLast12MonthsData,
  getDefaultDashboardToShow,
  pieChartTooltipFormatter,
  tableCellFormatter,
  truncateChartAxisValue,
  truncateValue,
  useNoDataInAllMonths,
}
