import { AxiosError } from 'axios'
import networkClient from '@/api/NetworkClient'
import { PATHS } from '@/api/types'
import { GetReportsRequest, GetReportsResponse, GetReportsResult } from '@/api/paths/reports/types'

const getReports = async (
  request: GetReportsRequest,
  handlers: GetReportsResult,
  params: Record<string, string | undefined>
): Promise<GetReportsResponse | void> => {
  try {
    const response = await networkClient.post<GetReportsRequest, GetReportsResponse>(PATHS.GET_REPORTS, request, {
      params,
    })
    handlers.onSuccess && (await handlers.onSuccess(response.data))
  } catch (error) {
    handlers.onError && (await handlers.onError(error as AxiosError))
  }
}

export { getReports }
