import { useCallback, useState } from 'react'
import html2canvas, { Options } from 'html2canvas'

/**
 * Configuration options for the useComponentToImage hook.
 * Extends html2canvas options and includes additional customization options.
 */
interface UseComponentToImageConfig extends Partial<Options> {
  /** Background color for the captured image. Defaults to white. */
  backgroundColor?: string

  /**
   * Custom width for the captured image in pixels.
   * If not provided, uses the element's clientWidth.
   */
  width?: number

  /**
   * Custom height for the captured image in pixels.
   * If not provided, uses the element's clientHeight.
   */
  height?: number
}

/**
 * Custom hook to capture a DOM element as an image.
 *
 * @param elementId - The ID of the DOM element to capture.
 * @param config - Configuration options for the capture process, including:
 *   - backgroundColor: Background color for the captured image (defaults to white)
 *   - width: Custom width for the captured image in pixels
 *   - height: Custom height for the captured image in pixels
 *   - Additional html2canvas options are also supported
 * @returns An object containing:
 *   - imageUrl: The generated image URL (data URL format)
 *   - isGenerating: Boolean indicating if image generation is in progress
 *   - error: Any error that occurred during generation
 *   - generateImage: Function to trigger the image generation
 */
export const useComponentToImage = (elementId: string, config: UseComponentToImageConfig = {}) => {
  // State to hold the generated image URL
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  // State to track if the image generation process is ongoing
  const [isGenerating, setIsGenerating] = useState(false)
  // State to hold any errors encountered during the process
  const [error, setError] = useState<Error | null>(null)

  /**
   * Generates an image from the specified DOM element.
   * Uses html2canvas to create a canvas and convert it to a PNG data URL.
   *
   * @returns A promise resolving to the image URL or null if an error occurs.
   */
  const generateImage = useCallback(async () => {
    const element = document.getElementById(elementId)
    if (!element) {
      setError(new Error(`Element with id "${elementId}" not found`))
      return null
    }

    setIsGenerating(true)
    setError(null)

    try {
      const canvas = await html2canvas(element, {
        scale: config.scale || 2,
        backgroundColor: config.backgroundColor || '#ffffff',
        logging: false,
        useCORS: true,
        width: config.width || element.clientWidth,
        height: config.height || element.clientHeight,
        ...config,
      })

      const url = canvas.toDataURL('image/png')
      setImageUrl(url)
      return url
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to generate image'))
      return null
    } finally {
      // Ensure this runs regardless of success or failure
      setIsGenerating(false)
    }
  }, [elementId, config])

  return { imageUrl, isGenerating, error, generateImage }
}
