import React, { useEffect } from 'react'
import { SiteDetailsProvider } from '@/contexts/siteDetails'
import ContractDetails from '@/modules/site-details/components/contract-details'
import TechnicalInformation from '@/modules/site-details/components/technical-information'
import useGetSiteDetails from '@/modules/site-details/hooks/useGetSiteDetails'
import { StyledSiteDetailsContainer, StyledSiteDetailsParentContainer } from '@/modules/site-details/styled'
import { LoadingType } from '@/shared/hooks/useApi'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { stopLoading } from '@/store/loading/action'
import { useAppDispatch } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { useParams } from 'react-router'
import useCleanupStorage from '@/shared/hooks/useCleanupStorage'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'

const SiteDetails: React.FC = () => {
  return (
    <SiteDetailsProvider>
      <StyledSiteDetailsContainer data-testid={dataTestId.siteDetails}>
        <InnerSiteDetails />
      </StyledSiteDetailsContainer>
    </SiteDetailsProvider>
  )
}

const InnerSiteDetails: React.FC = () => {
  const { execute: getSiteDetails, status: getSitesDetailsStatus } = useGetSiteDetails()

  const siteDetailsDispatch = useAppDispatch()
  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()
  const { sid } = useParams()

  useEffect(() => {
    getSiteDetails({ loaderType: LoadingType.GLOBAL })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid])

  useDeepCompareEffect(() => {
    setFetchDataStatus(getSitesDetailsStatus)
  }, [getSitesDetailsStatus])

  useEffect(() => {
    setFetchDataFn(() => getSiteDetails)
  }, [getSiteDetails, setFetchDataFn])

  useEffect(() => {
    if (getSitesDetailsStatus === 'success') {
      siteDetailsDispatch(stopLoading())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSitesDetailsStatus])

  useCleanupStorage()

  return (
    <StyledSiteDetailsParentContainer feJustifyContent="space-between" feFlexDirection="row">
      <TechnicalInformation />
      <ContractDetails />
    </StyledSiteDetailsParentContainer>
  )
}

export default SiteDetails
