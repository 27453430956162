import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectGenericToastData = (state: RootState) => state.genericToastData

const genericToastDataSelector = createSelector(_selectGenericToastData, (genericToastData) => ({
  ...genericToastData,
}))

export { genericToastDataSelector }
