import { ReportStatusContextProvider } from '@/contexts/reportStatus'
import { dataTestId } from '@/tests/testid'
import { Outlet } from 'react-router'

const ReportStatus = () => {
  return (
    <ReportStatusContextProvider>
      <Outlet />
      <div data-testid={dataTestId.reportStatus} />
    </ReportStatusContextProvider>
  )
}

export default ReportStatus
