import { OverviewResponse } from '@/models/overview/types'
import useAnalysisBacklogLevels from '@/modules/overview/columns/thirdLevel/useAnalysisBacklogLevels'
// import useAnalysisBacklogOrphanNotes from '@/modules/overview/columns/thirdLevel/useAnalysisBacklogOrphanNotes'
import useAnalysisBacklogTimeBetween from '@/modules/overview/columns/thirdLevel/useAnalysisBacklogTimeBetween'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef } from 'material-react-table'

const useAnalysisBacklog = () => {
  const analysisBacklogLevelsColumns = useAnalysisBacklogLevels()
  const analysisBacklogTimeBetweenColumn = useAnalysisBacklogTimeBetween()
  // const analysisBacklogOrphanNotesColumn = useAnalysisBacklogOrphanNotes()
  const analysisBacklogColumns = useDeepCompareMemo<MRT_ColumnDef<OverviewResponse['sites'][number]>[]>(
    () => [
      {
        id: 'pendingAnalysis',
        header: 'Pending Analysis',
        columns: analysisBacklogLevelsColumns,
      },

      {
        id: 'timeBetween',
        header: 'Time Between',
        columns: analysisBacklogTimeBetweenColumn,
      },
      // {
      //   id: 'orphanNotes',
      //   header: 'Orphan Notes',
      //   size: 15,
      //   columns: analysisBacklogOrphanNotesColumn,
      // },
    ],
    [analysisBacklogLevelsColumns, analysisBacklogTimeBetweenColumn /*analysisBacklogOrphanNotesColumn*/]
  )
  return analysisBacklogColumns
}

export default useAnalysisBacklog
