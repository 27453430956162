import { AssetReportFilters, ReportGeneratorApiResponse } from '@/models/reportGenerator/types'
import {
  allAssetTypes,
  assetCriticalities,
  initialGeneratedReportsData,
} from '@/modules/report-generator/utils/constants'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { AssetStatus } from '@/shared/models/types'
import { getEnumKeys } from '@/shared/utils'
import { createContext, useMemo, useState } from 'react'

export interface IReportGeneratorContext {
  generatedReportData: ReportGeneratorApiResponse
  setGeneratedReportData: React.Dispatch<React.SetStateAction<IReportGeneratorContext['generatedReportData']>>
  showPreview: boolean
  setShowPreview: React.Dispatch<React.SetStateAction<IReportGeneratorContext['showPreview']>>
  assetReportFilters: AssetReportFilters
  setAssetReportFilters: React.Dispatch<React.SetStateAction<IReportGeneratorContext['assetReportFilters']>>
  isReportFiltersApplied: boolean
  setIsReportFiltersApplied: React.Dispatch<React.SetStateAction<IReportGeneratorContext['isReportFiltersApplied']>>
  reportGeneratorLogoUrl: string | null
  setReportGeneratorLogoUrl: React.Dispatch<React.SetStateAction<IReportGeneratorContext['reportGeneratorLogoUrl']>>
}
export const ReportGeneratorContext = createContext<IReportGeneratorContext | null>(null)

export const useReportGeneratorContext = () =>
  useContextWrapper(ReportGeneratorContext, {
    contextName: useReportGeneratorContext.name,
    providerName: ReportGeneratorContextProvider.name,
  })

export const ReportGeneratorContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [assetReportFilters, setAssetReportFilters] = useState<IReportGeneratorContext['assetReportFilters']>({
    assets: [],
    assetStatuses: getEnumKeys(AssetStatus, ['never-reported']),
    assetCriticalities: [...assetCriticalities],
    assetTypes: allAssetTypes,
    reportTypes: [],
    reportDate: new Date(),
  })
  const [generatedReportData, setGeneratedReportData] = useState<IReportGeneratorContext['generatedReportData']>(
    () => initialGeneratedReportsData
  )
  const [reportGeneratorLogoUrl, setReportGeneratorLogoUrl] =
    useState<IReportGeneratorContext['reportGeneratorLogoUrl']>(null)
  const [isReportFiltersApplied, setIsReportFiltersApplied] =
    useState<IReportGeneratorContext['isReportFiltersApplied']>(false)

  const [showPreview, setShowPreview] = useState<IReportGeneratorContext['showPreview']>(false)

  const value = useMemo(
    () => ({
      generatedReportData,
      assetReportFilters,
      setAssetReportFilters,
      isReportFiltersApplied,
      setIsReportFiltersApplied,
      reportGeneratorLogoUrl,
      setReportGeneratorLogoUrl,
      setGeneratedReportData,
      showPreview,
      setShowPreview,
    }),
    [assetReportFilters, generatedReportData, isReportFiltersApplied, reportGeneratorLogoUrl, showPreview]
  )

  return <ReportGeneratorContext.Provider value={value}>{children}</ReportGeneratorContext.Provider>
}
