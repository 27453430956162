import { FC } from 'react'
import { RaWidgetClosedStatusTypes, RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import { tableCellFormatter, truncateValue } from '@/modules/dashboard/utils'
import { dataTestId } from '@/tests/testid'
import { StyledRATableCell, StyledRATableHeader } from '../../styled'
import { RecommendedActionStatus, RecommendedActionStatusString } from '@/shared/models/types'

interface RATableRowProps {
  status: string
  slices: RAwidgetDataType
  showPercentages: boolean
}

const RATableRow: FC<RATableRowProps> = ({ status, slices, showPercentages }) => {
  return (
    <tr data-testid={dataTestId.recommendedActionsTrendViewTableRow}>
      <StyledRATableHeader status={status as RecommendedActionStatusString}>
        <h4>{RecommendedActionStatus[status as keyof typeof RecommendedActionStatus]}</h4>
      </StyledRATableHeader>
      {slices.map(({ period, data }) => {
        const hasData = Object.keys(data).length > 0
        const value =
          status === 'completed' || status === 'rejected' ? data[RaWidgetClosedStatusTypes[status]] : data[status]
        return (
          <StyledRATableCell
            key={`${period.year}-${period.month}-${status}`}
            hasData={hasData}
            status={status as RecommendedActionStatusString}
            role="gridcell"
          >
            {value >= 0 ? truncateValue(tableCellFormatter(value, 1, showPercentages)) : 'NA'}
          </StyledRATableCell>
        )
      })}
    </tr>
  )
}

export default RATableRow
