import { ResponseAssets } from '@/models/analysisBacklog/types'
import useContextWrapper from '@/shared/hooks/useContextWrapper'
import { createContext, useMemo, useState } from 'react'

interface IAnalysisBacklogContext {
  tableScrollPosition: number
  setTableScrollPosition: React.Dispatch<React.SetStateAction<IAnalysisBacklogContext['tableScrollPosition']>>
  analysisBacklogData: ResponseAssets
  setAnalysisBacklogData: React.Dispatch<React.SetStateAction<IAnalysisBacklogContext['analysisBacklogData']>>
}

export const AnalysisBacklogContext = createContext<IAnalysisBacklogContext | null>(null)

export const useAnalysisBacklogContext = () =>
  useContextWrapper(AnalysisBacklogContext, {
    contextName: useAnalysisBacklogContext.name,
    providerName: AnalysisBacklogContextProvider.name,
  })

export const AnalysisBacklogContextProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [tableScrollPosition, setTableScrollPosition] = useState<IAnalysisBacklogContext['tableScrollPosition']>(0)
  const [analysisBacklogData, setAnalysisBacklogData] = useState<IAnalysisBacklogContext['analysisBacklogData']>({
    unhandled: [],
    handled: [],
  })

  const value = useMemo(
    () => ({
      tableScrollPosition,
      setTableScrollPosition,
      analysisBacklogData,
      setAnalysisBacklogData,
    }),
    [tableScrollPosition, analysisBacklogData]
  )

  return <AnalysisBacklogContext.Provider value={value}>{children}</AnalysisBacklogContext.Provider>
}
