import { Boldness } from '@/shared/constants'
import { COLORS, SPACINGS, styled } from '@skf-internal/ui-components-react'

export const StyledMultilineContainer = styled.div<{
  rowsUntilEllipsis: number
}>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ rowsUntilEllipsis }) => rowsUntilEllipsis};
  -webkit-box-orient: vertical;
`

export const StyledMultilineLabel = styled.span`
  font-weight: ${Boldness.Bold};
`

export const StyledMultilineValueContainer = styled.div`
  width: 100%;
`

export const StyledMultilineValue = styled.div`
  width: fit-content;
`

export const StyledTextEllipsisTooltip = styled.div`
  background-color: ${COLORS.white};
  font-size: 0.8rem;
  max-width: 19rem;
  padding: ${SPACINGS.sm};
  border-radius: 0.1rem;
  z-index: 9999999;
  word-wrap: break-word;
  overflow-wrap: break-word;
`

export const StyledSingleLineContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StyledSingleLineContentWrapper = styled.div<{
  fontSizeInRem: string
}>`
  width: fit-content;
  margin: 0.4rem 0;
  font-size: ${(props) => props.fontSizeInRem};
`
