import { markReportAsNormal } from '@/api/paths/analysis'
import { useApi } from '@/shared/hooks/useApi'
import { startAsyncPoller } from '@/store/asyncPoller/actions'
import { openToast } from '@/store/genericToast/actions'
import { useAppDispatch } from '@/store/store'
import { AxiosError } from 'axios'

const useMarkReportAsNormal = (assetId: string, sid: string) => {
  const markReportAsNormalDispatch = useAppDispatch()

  return useApi(() => {
    return markReportAsNormal(
      {
        onSuccess: async () => {
          markReportAsNormalDispatch(
            startAsyncPoller({
              pollerInterval: 2000,
              pollerTimeout: 20000,
              toastMessages: {
                startPollingMessage: 'Processing mark as normal request',
                timeoutPollingMessage:
                  'Mark as normal request timed out. Please refresh the page and if this repeats, contact support.',
                failedPollingMessage: 'Mark as normal action failed. Please contact support.',
              },
            })
          )
        },
        onError: async (error: AxiosError) => {
          markReportAsNormalDispatch(
            openToast({
              children: error.message,
              feSeverity: 'error',
            })
          )
        },
      },
      { sid, assetId }
    )
  })
}

export default useMarkReportAsNormal
