import { ActionMenuProvider } from '@/contexts/moduleContexts/actionMenu'
import AnalysisBacklogPopoverContent from '@/modules/analysisBacklog/components/cell/actionMenu/analysisBacklogPopoverContent'
import { AnalysisBacklogMenuProps } from '@/modules/analysisBacklog/components/cell/actionMenu/types'
import ActionMenu from '@/shared/components/actionMenu'
import { FC } from 'react'

const AnalysisBacklogActionMenu: FC<AnalysisBacklogMenuProps> = (props) => {
  return (
    <ActionMenuProvider>
      <ActionMenu PopoverContent={<AnalysisBacklogPopoverContent {...props} />} />
    </ActionMenuProvider>
  )
}
export default AnalysisBacklogActionMenu
