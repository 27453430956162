import { ReportStatusLoaderData } from '@/app/routes/types'
import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { ResponseAssets } from '@/models/analysisBacklog/types'
import useGetAnalyticsBacklogDataNew from '@/modules/analysisBacklog/hooks/useGetAnalyticsBacklogDataNew'
import FaultsContainer from '@/modules/report-status/components/fault'
import FaultFormFooter from '@/modules/report-status/components/footer'
import ReportStatusHeader from '@/modules/report-status/components/header'
import History from '@/modules/report-status/components/history'
import {
  StyledReportStatusDialogContainer,
  StyledReportStatusDialogHistoryContainer,
  StyledReportStatusDialogLoader,
  StyledReportStatusDialogLoaderBackground,
  StyledReportStatusDialogLoaderContainer,
} from '@/modules/report-status/styled'
import { getEntityId } from '@/modules/report-status/utils/formUtils'
import AsyncPoller from '@/shared/components/AsyncPoller'
import { ENV } from '@/shared/constants'
import useDeepCompareEffect from '@/shared/hooks/useDeepCompareEffect'
import { isEqual } from '@/shared/utils'
import { stopAsyncPoller } from '@/store/asyncPoller/actions'
import { closeToast, openToast } from '@/store/genericToast/actions'
import { stopLoading } from '@/store/loading/action'
import { asyncPollerSelector } from '@/store/selectors/asyncPollerSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Spacer } from '@skf-internal/ui-components-react'
import { Fragment } from 'react'
import { useLoaderData } from 'react-router'

const ReportStatusDialogContent = () => {
  const { assetRow, assets, assetFaultsAndHistory } = useLoaderData() as ReportStatusLoaderData

  const { faultsFormState } = useFaultsFormStateContext()

  if (import.meta.env.MODE !== ENV.PROD) {
    console.log('!!!!!!!!!!!!!!!!!faultsFormState', faultsFormState)
  }

  const { isAsyncPollerActive, toastMessages } = useTypedSelector((state) => asyncPollerSelector(state))

  const {
    reportStatusDialogData: { closeDialog },
    setAssetsData,
  } = useReportStatusContext()

  const reportStatusDialogContextDispatch = useAppDispatch()

  const { setFetchDataFn, setFetchDataStatus } = useFetchDataContext()

  useDeepCompareEffect(() => {
    if (assets) {
      setAssetsData(assets)
    }
  }, [assets])

  useDeepCompareEffect(() => {
    if (assetRow) {
      reportStatusDialogContextDispatch(stopLoading())
    }
  }, [assetRow])

  const { execute: executeAnalyticsBacklogData, status: executeAnalyticsBacklogDataStatus } =
    useGetAnalyticsBacklogDataNew(
      async (result) => {
        if (isAsyncPollerActive) {
          //const mappedAssetsData = mapAssetsData(result)
          if (!isEqual(assets, result)) {
            reportStatusDialogContextDispatch(stopAsyncPoller())
            setAssetsData(result)
            reportStatusDialogContextDispatch(closeToast())
            closeDialog && closeDialog(undefined, result)
          }
        }
      },
      async () => {
        if (isAsyncPollerActive) {
          //reportStatusDialogContextDispatch(setModuleHeaderActionPanelData({ isAsyncIndicatorShown: false }))
          reportStatusDialogContextDispatch(stopAsyncPoller())
          reportStatusDialogContextDispatch(
            openToast({
              children: toastMessages.failedPollingMessage,
              feSeverity: 'error',
              timeout: 3,
            })
          )
        }
      }
    )

  useDeepCompareEffect(() => {
    setFetchDataFn(() => executeAnalyticsBacklogData)
  }, [executeAnalyticsBacklogData])

  useDeepCompareEffect(() => {
    setFetchDataStatus(executeAnalyticsBacklogDataStatus)
  }, [executeAnalyticsBacklogDataStatus])

  return (
    assetRow &&
    assetFaultsAndHistory && (
      <StyledReportStatusDialogContainer data-testid={dataTestId.reportStatusDialogContent}>
        <ReportStatusHeader assetRow={assetRow} />
        <StyledReportStatusDialogHistoryContainer>
          <StyledReportStatusDialogLoaderContainer>
            {isAsyncPollerActive && (
              <StyledReportStatusDialogLoaderBackground>
                <StyledReportStatusDialogLoader data-testid={dataTestId.reportStatusSubmitLoader} />
              </StyledReportStatusDialogLoaderBackground>
            )}
            {faultsFormState.faults.map((fault) => {
              return (
                <Fragment key={getEntityId(fault)}>
                  <FaultsContainer fault={fault} />
                </Fragment>
              )
            })}
          </StyledReportStatusDialogLoaderContainer>
          <Spacer />
          <History assetId={assetRow.id} />
        </StyledReportStatusDialogHistoryContainer>
        <FaultFormFooter assetRow={assetRow} isFormValid={!!faultsFormState.isValid} />
        <AsyncPoller<ResponseAssets> pollerFn={executeAnalyticsBacklogData} />
      </StyledReportStatusDialogContainer>
    )
  )
}

export default ReportStatusDialogContent
