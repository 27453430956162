import { ReportStatusLoaderData } from '@/app/routes/types'
import { AssetHistorySnapshot } from '@/models/reportStatus/types'
import { RecommendedActionDateDisplay } from '@/modules/report-status/components/history/DateDisplay'
import EvidenceHistory from '@/modules/report-status/components/history/evidenceHistory'
import RecommendedActionHistory from '@/modules/report-status/components/history/RecommendedActionHistory'
import {
  StyledFaultHistoryCollapseContainer,
  StyledFaultHistoryCollapseInnerContainer,
  StyledFaultHistoryCollectionDateRow,
  StyledFaultHistoryCollectionDateRowChildren,
  StyledFaultHistoryContainer,
  StyledFaultHistoryEvidenceRow,
  StyledFaultHistoryMainContainer,
  StyledFaultHistoryObservation,
  StyledFaultHistoryOpenIconButton,
  StyledFaultHistoryRecommendedActionRow,
  StyledFaultHistoryTopContainer,
  StyledFaultHistoryTypeText,
} from '@/modules/report-status/styled'
import AssetStatusIcon from '@/shared/components/AssetStatusIcon'
import TextEllipsis, { TextEllipsisType } from '@/shared/components/TextEllipsis'
import { addToDate, DateFormats, formatDate, parseDate } from '@/shared/dateUtils'
import useDeepCompareCallback from '@/shared/hooks/useDeepCompareCallback'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { uniqueIdPrefixable } from '@/shared/utils'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { IconButton } from '@skf-internal/ui-components-react'
import { FC, useMemo, useState } from 'react'
import { useLoaderData } from 'react-router'

const stateLabel: Record<'new' | 'open' | 'closed', string> = {
  new: 'Created',
  open: 'Open',
  closed: 'Closed',
}

const FaultHistory: FC<{
  fault: AssetHistorySnapshot['faults'][number]
  assetId: string
  // reportTimestamp: string
  // userName: string
}> = ({ fault /*reportTimestamp, userName*/ }) => {
  const { assetFaultsAndHistory /*customerNotes: customerNotesData*/ } = useLoaderData() as ReportStatusLoaderData

  const [open, setOpen] = useState(false)
  const { history, openFaults } = assetFaultsAndHistory
  // const { assetId } = useParams()

  const {
    // selectedCustomer: { customerNotesEnabled },
    reportFaultTypes,
  } = useTypedSelector((state) => state)

  const getFaultTypeString = useDeepCompareCallback(() => {
    if (reportFaultTypes.length === 0) {
      return ''
    }
    const parentType = reportFaultTypes.find(
      (type) => type.code === fault.fault || type.subTypes.some((sub) => sub.code === fault.fault)
    )

    if (!parentType) {
      return ''
    }
    const subType = parentType.subTypes.find((sub) => sub.code === fault.fault)

    if (subType) {
      return `${fault.id} ${subType.en}`
    }

    return `${fault.id} ${parentType.text}`
  }, [fault, reportFaultTypes])

  const recommendedActionIds = useMemo(
    () => fault.recommendedActions.map((_) => uniqueIdPrefixable('recAction_')),
    [fault]
  )

  // const validSnapshots = useDeepCompareMemo(
  //   () =>
  //     getReversedHistory(history).filter((snapshot) => {
  //       return snapshot.timestamp <= reportTimestamp
  //     }),
  //   [history, reportTimestamp]
  // )

  // const customerNotes: CustomerNotesMessage[] = useDeepCompareMemo(
  //   () =>
  //     validSnapshots.flatMap((snapshot) => {
  //       return snapshot.faults
  //         .filter((f) => f.id === fault.id)
  //         .map((snapshotFault) => formatSkfCustomerNote(snapshotFault, snapshot.timestamp, snapshot.username))
  //     }),
  //   [fault, validSnapshots]
  // )

  // const validFaultsWithCustomerNotes = useDeepCompareMemo(() => {
  //   return getFaults(assetFaultsAndHistory, /*customerNotesData, assetId*/).find(
  //     (openFault) => openFault.fault.id === fault.id
  //   )
  // }, [assetFaultsAndHistory, assetId, /*customerNotesData,*/ fault])

  const formattedCollectionDate = useDeepCompareMemo(() => {
    const parsedCollectionDate = parseDate(fault.collectionDate)
    return formatDate(
      addToDate(fault.collectionDate, parsedCollectionDate.getTimezoneOffset(), 'minutes'),
      DateFormats.AmericanDateFormat
    )
  }, [])

  // useDeepCompareEffect(() => {
  //   if (validFaultsWithCustomerNotes) {
  //     validFaultsWithCustomerNotes.metadata.customerNotes.forEach((customerNote) => {
  //       if (
  //         isBeforeOrSame(
  //           formatDate(customerNote.noteDate, DateFormats.AmericanDateTimeFormat),
  //           formatDate(reportTimestamp, DateFormats.AmericanDateTimeFormat)
  //         ) &&
  //         customerNote.acknowledgment === true
  //       ) {
  //         customerNotes.push(customerNote)
  //       }
  //     })
  //   }
  // }, [customerNotes, reportTimestamp, validFaultsWithCustomerNotes])

  if (history === undefined || openFaults === undefined) {
    return null
  }

  return (
    <StyledFaultHistoryMainContainer
      feFlexDirection="column"
      feJustifyContent="space-between"
      data-testid={dataTestId.faultHistory}
    >
      <StyledFaultHistoryTopContainer feFlexDirection="row" feJustifyContent="space-between">
        <StyledFaultHistoryContainer feAlignItems="center" feGap="xs">
          <AssetStatusIcon value={fault.status} />
          <StyledFaultHistoryTypeText
            data-testid={dataTestId.faultHistoryTypeText}
            rowsUntilEllipsis={1}
            value={getFaultTypeString()}
            type={TextEllipsisType.MultiLine}
          />
        </StyledFaultHistoryContainer>
        <StyledFaultHistoryObservation feFlexDirection="column" feGap="xs" feJustifyContent="left">
          <TextEllipsis
            type={TextEllipsisType.MultiLine}
            label="Observation"
            value={fault.observation}
            rowsUntilEllipsis={3}
          />
        </StyledFaultHistoryObservation>
        <StyledFaultHistoryOpenIconButton feAlignItems="center" feGap="sm" feJustifyContent="end">
          <span>
            <b>{stateLabel[fault.state]}</b>
          </span>
          <IconButton
            as="button"
            type="button"
            feSize="md"
            aria-label={open ? 'Open' : 'Close'}
            onClick={() => setOpen((o) => !o)}
            feIcon={open ? 'chevronUp' : 'chevronDown'}
            data-testid={dataTestId.faultHistoryCollapseIconButton}
          />
        </StyledFaultHistoryOpenIconButton>
      </StyledFaultHistoryTopContainer>
      {open && (
        <StyledFaultHistoryCollapseContainer
          feFlexDirection="column"
          feJustifyContent="space-between"
          data-testid={dataTestId.faultHistoryCollapseContainer}
        >
          <StyledFaultHistoryCollapseInnerContainer feFlexDirection="column" feJustifyContent="space-between">
            <StyledFaultHistoryCollectionDateRow feFlexDirection="row" feJustifyContent="flex-start">
              <StyledFaultHistoryCollectionDateRowChildren>
                <RecommendedActionDateDisplay label="Collection date" value={formattedCollectionDate} />
              </StyledFaultHistoryCollectionDateRowChildren>
              <StyledFaultHistoryCollectionDateRowChildren>
                {fault.state === 'closed' && open && (
                  <TextEllipsis
                    type={TextEllipsisType.MultiLine}
                    label="Was there a correct diagnosis?"
                    value={fault.correctDiagnostic ? 'Yes' : 'No'}
                    rowsUntilEllipsis={0}
                    data-testid={dataTestId.faultHistoryCorrectDiagnosis}
                  />
                )}
              </StyledFaultHistoryCollectionDateRowChildren>

              <StyledFaultHistoryCollectionDateRowChildren>
                {fault.state === 'closed' && open && !fault.correctDiagnostic && (
                  <TextEllipsis
                    type={TextEllipsisType.MultiLine}
                    label="Explanation"
                    value={fault.explanation}
                    rowsUntilEllipsis={3}
                    data-testid={dataTestId.faultHistoryExplanation}
                  />
                )}
              </StyledFaultHistoryCollectionDateRowChildren>
            </StyledFaultHistoryCollectionDateRow>

            <StyledFaultHistoryEvidenceRow feFlexDirection="column" feJustifyContent="space-around">
              <EvidenceHistory evidences={fault.evidences} faultId={fault.id} />
            </StyledFaultHistoryEvidenceRow>

            <StyledFaultHistoryRecommendedActionRow feFlexDirection="column" feJustifyContent="space-around">
              {fault.recommendedActions.map((recommendedAction, index) => (
                <RecommendedActionHistory
                  data-testid={dataTestId.faultHistoryRecommendedAction}
                  key={recommendedActionIds[index]}
                  recommendedAction={recommendedAction}
                />
              ))}
            </StyledFaultHistoryRecommendedActionRow>
          </StyledFaultHistoryCollapseInnerContainer>
          {/* {customerNotesEnabled && customerNotes.length > 0 && (
            <StyledFaultHistoryCustomerNotesRow feFlexDirection="row" feJustifyContent="space-between">
              <StyledFaultHistoryCollapse feTitle={'Customer Notes'} feTitleAs="h2">
                {orderBy(customerNotes, ['noteDate'], ['desc']).map((customerNote, i) => (
                  <CustomerNotesHistory
                    data-testid={dataTestId.faultCustomerNotesHistory}
                    key={i}
                    customerNote={customerNote}
                    userName={userName}
                  />
                ))}
              </StyledFaultHistoryCollapse>
            </StyledFaultHistoryCustomerNotesRow>
          )} */}
        </StyledFaultHistoryCollapseContainer>
      )}
    </StyledFaultHistoryMainContainer>
  )
}
export default FaultHistory
