import { getSiteDetails } from '@/api/paths/sites/siteDetails'
import { DarwinDetachedModuleError } from '@/shared/components/ErrorBoundary/DarwinError'
import { SiteDetailsData } from '@/models/siteDetails/types'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router'
import { IReportGeneratorContext, useReportGeneratorContext } from '@/contexts/reportGenerator'

const useGetReportGeneratorSiteDetails = () => {
  const throwError = useAsyncThrow()
  const { setReportGeneratorLogoUrl } = useReportGeneratorContext()
  const { sid } = useParams()
  return useApi(() => {
    return getSiteDetails(
      {
        onSuccess: async (result: SiteDetailsData) => {
          setReportGeneratorLogoUrl(result?.logo?.fileURL as IReportGeneratorContext['reportGeneratorLogoUrl'])
        },
        onError: async (error: AxiosError) => {
          throwError(new DarwinDetachedModuleError(error))
        },
      },
      {
        sid,
      }
    )
  })
}

export default useGetReportGeneratorSiteDetails
