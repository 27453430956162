import { StyledPriorityColumnIcon } from '@/modules/analysisBacklog/styled'
import { dataTestId } from '@/tests/testid'
import { Flexbox, Tooltip, useTooltip } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface PriorityUnhandledCellColumnProps {
  isInaccuratePriority: boolean
  priority: number
}

const PriorityUnhandledCellColumn: FC<PriorityUnhandledCellColumnProps> = ({ isInaccuratePriority, priority }) => {
  const [hoverRef, isHovered] = useTooltip()

  return (
    <Flexbox
      feJustifyContent="center"
      feAlignItems="center"
      data-testid={dataTestId.analysisBacklogPriorityCellContent}
    >
      {isInaccuratePriority && (
        <>
          <StyledPriorityColumnIcon
            feIcon="exclamation"
            feSize="md"
            as="button"
            aria-label="Invalid Input"
            ref={hoverRef}
            data-testid={dataTestId.analysisBacklogPriorityCellInaccurateIcon}
          />
          <Tooltip isHovered={isHovered} triggerElement={hoverRef.current}>
            Invalid Input
          </Tooltip>
        </>
      )}
      {priority}
    </Flexbox>
  )
}

export default PriorityUnhandledCellColumn
