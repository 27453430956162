import { SET_REPORT_FAULT_TYPES } from '@/store/reportFaultTypes/actions.types'
import { ReportFaultTypesAction, ReportFaultTypesState } from '@/store/reportFaultTypes/types'

export const initialState: ReportFaultTypesState = []

export type ReportFaultTypesReducer = (
  state: ReportFaultTypesState | undefined,
  action: ReportFaultTypesAction
) => ReportFaultTypesState

const reportFaultTypesReducer: ReportFaultTypesReducer = (state = initialState, action) => {
  if (action.type === SET_REPORT_FAULT_TYPES) {
    return action.payload
  }

  return state
}

export default reportFaultTypesReducer
