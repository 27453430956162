import { Select, SelectItem } from '@skf-internal/ui-components-react'
import { FC, useMemo } from 'react'
import { css } from '@emotion/react'
import { AssetHealthStatusType, AssetHealthStatusSelectType } from '@/models/dashboard/globalFiltering/types'
import { dataTestId } from '@/tests/testid'
import { getAssetStatuses, mapAssetStatusesOption } from '@/shared/utils'

const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Hide normal', value: 'hideNormal' },
  {
    label: 'Unsatisfactory, unacceptable, severe',
    value: 'unsatisfactoryUnacceptableSevere',
  },
  { label: 'Unsatisfactory, severe', value: 'unsatisfactorySevere' },
  { label: 'Severe', value: 'severe' },
] as const

type StatusOptionValue = (typeof statusOptions)[number]['value']
interface IStatusSelect {
  className?: string
  value: AssetHealthStatusSelectType[]
  onChange: (value: AssetHealthStatusType[]) => void
}

const StatusSelect: FC<IStatusSelect> = ({ className, value, onChange }) => {
  const options = useMemo<SelectItem<StatusOptionValue>[]>(
    () =>
      statusOptions.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    []
  )

  return (
    <Select<StatusOptionValue>
      data-testid={dataTestId.globalFilteringAssetStatusSelect}
      css={css`
        * {
          flex-wrap: wrap;
        }

        label {
          font-weight: 700;
        }

        span {
          font-weight: 400;
        }

        width: 300px;
      `}
      feSize="sm"
      className={className}
      multiple={false}
      value={mapAssetStatusesOption(value as AssetHealthStatusType[])}
      onChange={(selectedValue: StatusOptionValue) => onChange(getAssetStatuses(selectedValue as StatusOptionValue))}
      feItems={options}
      feLabel="Asset status"
    />
  )
}
export default StatusSelect
