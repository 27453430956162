import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectIsGlobalLoaderActive = (state: RootState) => state.globalLoading.isGlobalLoading
const _selectIsHeaderLoaderActive = (state: RootState) => state.headerLoading.isHeaderLoading
const _selectWidgetTypes = (state: RootState) => state.widgetLoading.widgetTypes

const loadersSelector = createSelector(
  [_selectIsGlobalLoaderActive, _selectIsHeaderLoaderActive, _selectWidgetTypes],
  (isGlobalLoading, isHeaderLoading, widgetTypes) => ({
    isGlobalLoading,
    isHeaderLoading,
    widgetTypes,
  })
)

export { loadersSelector }
