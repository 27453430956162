import { useFetchDataContext } from '@/contexts/common/fetchDataContext'
import { useUnresolvedRecommendationsContext } from '@/contexts/unresolvedRecommendations'
import { RecommendedAction } from '@/models/unresolvedRecommendations/types'
import EmptyAssetTableState from '@/modules/analysisBacklog/components/emptyState'
import useOpenRecommendationsColumns from '@/modules/openRecommendations/columns/useOpenRecommendationsColumns'
import { RedirectButton } from '@/modules/openRecommendations/components/cells/redirectButton'
import {
  openRecommendationsTableBodyCellProps,
  openRecommendationsTableBodyRowProps,
  openRecommendationsTableContainerProps,
  openRecommendationsTableHeadCellProps,
  openRecommendationsTableHeaderProps,
  openRecommendationsTablePaperProps,
} from '@/modules/openRecommendations/styled/tableSx'
import usePreviousData from '@/shared/components/TableComponents/hooks/usePreviousData'
import useTableIcons from '@/shared/components/TableComponents/useTableIcons'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { createCustomSortHandler } from '@/shared/utils'
import { headerActionPanelConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import {
  MRT_ColumnFiltersState,
  MRT_Row,
  MRT_SortingState,
  MRT_TableOptions,
  useMaterialReactTable,
} from 'material-react-table'
import { useCallback, useMemo, useState } from 'react'

const useOpenRecommendationsTable = () => {
  const { unresolvedRecommendations } = useUnresolvedRecommendationsContext()
  const { fetchDataStatus } = useFetchDataContext()

  const [sorting, setSorting] = useState<MRT_SortingState>([])
  const [currentSelectedRow, setCurrentSelectedRow] = useState<RecommendedAction | undefined>(undefined)

  const sortingChangeHandler = useMemo(() => createCustomSortHandler(3), [])

  const { tableStateChangeSwitchCurrentState: showClosed } = useTypedSelector((state) =>
    headerActionPanelConfigSelector(state)
  )
  const tableIcons = useTableIcons()

  const openRecommendationColumns = useOpenRecommendationsColumns()

  const tableData = useDeepCompareMemo(() => {
    const openFormatData = unresolvedRecommendations.open as unknown as RecommendedAction[]
    const closedFormatData = unresolvedRecommendations.closed as unknown as RecommendedAction[]

    return showClosed ? [...openFormatData, ...closedFormatData] : openFormatData
  }, [unresolvedRecommendations, showClosed, fetchDataStatus])

  const showSkeletons = useDeepCompareMemo(
    () => tableData.length === 0 || fetchDataStatus === 'loading',
    [tableData, fetchDataStatus]
  )

  let sortedRows: MRT_Row<RecommendedAction>[] = []
  let tableColumnFilters: MRT_ColumnFiltersState = []
  let tableSorting: MRT_SortingState = []

  const openRecommendationsTable = useMaterialReactTable({
    columns: openRecommendationColumns,
    data: tableData,
    enableRowVirtualization: false,
    enableTopToolbar: false,
    enablePagination: tableData.length !== 0,
    enableBottomToolbar: true,
    enableColumnActions: false,
    enableStickyHeader: true,
    enableMultiSort: true,
    maxMultiSortColCount: 3,
    enableColumnFilters: true,
    columnFilterDisplayMode: 'custom',
    enableKeyboardShortcuts: false,
    initialState: { pagination: { pageIndex: 0, pageSize: 100 } },
    isMultiSortEvent: () => true,
    onSortingChange: (updater) => {
      setSorting(sortingChangeHandler(updater))
    },
    state: {
      showSkeletons,
      sorting,
    },
    renderEmptyRowsFallback: useCallback<
      Required<MRT_TableOptions<RecommendedAction>>['renderEmptyRowsFallback'] //TS needed to get the correct type of the inner arrow function below
    >(({ table }) => <EmptyAssetTableState<RecommendedAction> table={table} />, []),
    icons: tableIcons,
    muiTableHeadProps: openRecommendationsTableHeaderProps,
    muiTablePaperProps: openRecommendationsTablePaperProps,
    muiTableContainerProps: openRecommendationsTableContainerProps,
    muiSkeletonProps: {
      height: 50,
    },
    defaultColumn: {
      muiTableBodyCellProps: openRecommendationsTableBodyCellProps,
      muiTableHeadCellProps: openRecommendationsTableHeadCellProps,
    },
    muiTableBodyRowProps: ({ row }) => {
      const rowIndex: number = sortedRows.findIndex((item) => item.id === row.id)

      const nextKeydownIndex: number = sortedRows.findIndex(
        (item) => item.original.assetID === currentSelectedRow?.assetID
      )

      const isSelected = nextKeydownIndex === row.index

      return {
        ...openRecommendationsTableBodyRowProps(false, rowIndex, isSelected, showSkeletons),
        tabIndex: 0,
        //onKeyDown: (e: React.KeyboardEvent<HTMLTableRowElement>) => onDeviceTableRowKeyDown(e, nextKeydownIndex),
        onClick: () => setCurrentSelectedRow(row.original),
      }
    },
    muiPaginationProps: {
      color: 'primary',
      shape: 'rounded',
      showRowsPerPage: false,
      variant: 'text',
      onClick: () => {
        openRecommendationsTable.refs.tableContainerRef.current?.scrollTo({ top: 0 })
      },
    },
    paginationDisplayMode: 'pages',
    enableRowActions: true,
    positionActionsColumn: 'last',
    layoutMode: 'grid',
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: '',
        size: 50,
        grow: false,
      },
    },
    renderRowActions: useCallback<
      Required<MRT_TableOptions<RecommendedAction>>['renderDetailPanel'] //TS needed to get the correct type of the inner arrow function below
    >(({ row: { original, index }, table }) => {
      return <RedirectButton assetId={original.assetID} currentSelectedRowIndex={index} table={table} />
    }, []),
  })

  const tableState = openRecommendationsTable.getState()

  tableSorting = useDeepCompareMemo(() => tableState.sorting, [tableState])
  tableColumnFilters = useDeepCompareMemo(() => tableState.columnFilters, [tableState])

  sortedRows = useDeepCompareMemo(
    () => openRecommendationsTable.getSortedRowModel().rows as MRT_Row<RecommendedAction>[],
    [tableData, tableSorting, tableColumnFilters]
  )

  // const onDeviceTableRowKeyDown = useDeepCompareCallback(
  //   (event: React.KeyboardEvent<HTMLTableRowElement>, rowIndex: number) => {
  //     if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
  //       if (event.key === 'ArrowUp' && rowIndex === 0) return
  //       if (event.key === 'ArrowDown' && rowIndex === sortedRows.length - 1) return

  //       if (event.key === 'ArrowDown') {
  //         setCurrentSelectedRow(sortedRows[rowIndex + 1].original)
  //       }
  //       if (event.key === 'ArrowUp') {
  //         setCurrentSelectedRow(sortedRows[rowIndex - 1].original)
  //       }
  //     }
  //   },
  //   [unresolvedRecommendations, tableSorting, tableColumnFilters]
  // )

  usePreviousData<RecommendedAction, RecommendedAction[]>({
    table: openRecommendationsTable,
    setCurrentSelectedRow,
    contextData: tableData,
  })

  return openRecommendationsTable
}

export default useOpenRecommendationsTable
