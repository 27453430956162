import { useActionMenu } from '@/contexts/moduleContexts/actionMenu'
import { StyledActionColumnPopup, StyledPopover } from '@/shared/components/actionMenu/styled'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { generateUUID } from '@/shared/utils'
import { dataTestId } from '@/tests/testid'
import { IconButton, useTooltip } from '@skf-internal/ui-components-react'
import { FC, ReactNode, useRef } from 'react'

type ActionMenuProps = {
  PopoverContent: ReactNode
}

const ActionMenu: FC<ActionMenuProps> = ({ PopoverContent }) => {
  const { isOpen, setIsOpen } = useActionMenu()

  const outsideClickRef = useRef<HTMLDivElement>(null)
  useOutsideClick(
    () => {
      setIsOpen(false)
    },
    {
      refs: [outsideClickRef],
    }
  )
  const [clickedRef] = useTooltip()

  return (
    <div>
      <StyledActionColumnPopup isHovered={isOpen} triggerElement={clickedRef.current}>
        <StyledPopover ref={outsideClickRef} aria-labelledby={generateUUID()}>
          {PopoverContent}
        </StyledPopover>
      </StyledActionColumnPopup>

      <IconButton
        aria-label="Actions"
        as="button"
        type="button"
        onClick={() => setIsOpen((prev) => !prev)}
        ref={clickedRef}
        feIcon="kebabMore"
        feSize="md"
        data-testid={dataTestId.genericActionMenu}
      />
    </div>
  )
}

export default ActionMenu
