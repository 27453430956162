import { getUnresolvedRecommendations } from '@/api/paths/unresolvedRecommendations'
import { DarwinError } from '@/shared/components/ErrorBoundary/DarwinError'
import { useUnresolvedRecommendationsContext } from '@/contexts/unresolvedRecommendations'
import { useApi } from '@/shared/hooks/useApi'
import { useAsyncThrow } from '@/shared/hooks/useAsyncThrow'
import { UnresolvedRecommendationData } from '@/models/unresolvedRecommendations/types'
import { useParams } from 'react-router'

const useGetOpenRecommendations = () => {
  const { sid } = useParams()

  const { setUnresolvedRecommendations } = useUnresolvedRecommendationsContext()

  const throwError = useAsyncThrow()

  return useApi(async () =>
    getUnresolvedRecommendations(
      {
        onSuccess: async (result: UnresolvedRecommendationData) => {
          setUnresolvedRecommendations(result)
        },
        onError: async (error) => {
          throwError(new DarwinError(error))
        },
      },
      {
        sid,
      }
    )
  )
}

export default useGetOpenRecommendations
