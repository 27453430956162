import { Slice, YearMonth } from '../asset-data-collection/types'

export type RecommendedActionsStatusCounts = {
  pending: number
  created: number
  completed: number
  rejected: number
}
export type RAstatusOptionalProps = {
  [key: string]: number
}

export type ModifiedDataType = {
  [key: string]: number[]
}

export enum RaWidgetClosedStatusTypes {
  completed = 'closed_completed',
  rejected = 'closed_rejected',
}

export type DueDatesStatusCounts = {
  normal: number | null
  acceptable: number | null
  unsatisfactory: number | null
  unacceptable: number | null
  severe: number | null
}

export type RecommendedActionsDueDatesStatusType = {
  period: string
  data: DueDatesStatusCounts
}

export enum DueDatesStatusPeriod {
  overdueOverAMonth = 'Over a month',
  overdueUnderAMonth = 'Under a month',
  upcomingOverdueUnderAMonth = 'Within a month',
  upcomingOverdueOverAMonth = '1+ month',
}

export type EChartsFormatterParams = {
  dataIndex: number
  value: number
  seriesName: string
  name: string
  data: number | null
  seriesType: string
}

export type RAwidgetDataType = Slice<RAstatusOptionalProps, YearMonth>[]
