import { EChartsOption } from 'echarts'
import { FC, useMemo } from 'react'
import { ReactEChartsRenderer } from '../../../common/e-charts-renderer'
import { ConditionStatusWidgetDataType, Padding, Severity } from '@/models/widgets/asset-health-condition/types'
import { generateOptions, useCalculateAHCYAxisMaxLabel } from '@/modules/dashboard/utils/asset-health-condition'
import { formatDate } from '@/shared/dateUtils'

interface AssetHealthConditionStackedBarProps {
  slices: ConditionStatusWidgetDataType
  noDataInAllMonth: boolean
  chartPadding: Padding
  showPercentages: boolean
}

const AssetHealthConditionStackedBar: FC<AssetHealthConditionStackedBarProps> = ({
  slices,
  chartPadding,
  noDataInAllMonth,
  showPercentages,
}) => {
  const data = useMemo(() => {
    const data: Record<Severity, number[]> = {
      severe: [],
      unacceptable: [],
      unsatisfactory: [],
      acceptable: [],
      normal: [],
    }

    slices.forEach((slice, index) => {
      data.normal[index] = slice.data.normal
      data.acceptable[index] = slice.data.acceptable
      data.unsatisfactory[index] = slice.data.unsatisfactory
      data.unacceptable[index] = slice.data.unacceptable
      data.severe[index] = slice.data.severe
    })

    return data
  }, [slices])

  const yAxisMaxVal = useCalculateAHCYAxisMaxLabel(slices)

  const columns = useMemo(
    () =>
      slices.map((slice) => {
        // Create a new Date and set year/month safely
        const date = new Date()
        date.setFullYear(slice.period.year, slice.period.month - 1, 1) // Month is 0-based

        // Generate label with formatted date and year
        const label = `${formatDate(date, 'MMM').toLocaleUpperCase()}\n{year|(${slice.period.year})}`
        return label
      }),
    [slices]
  )

  const noDataMonthColumns = useMemo(() => {
    if (!noDataInAllMonth) {
      return slices
        .map((item, index) => (Object.keys(item.data).length === 0 ? columns[index] : null))
        .filter((column) => column !== null) as string[]
    }
    return []
  }, [slices, columns, noDataInAllMonth])

  const option = useMemo<EChartsOption>(() => {
    return generateOptions(
      data,
      columns,
      chartPadding,
      noDataMonthColumns,
      noDataInAllMonth,
      yAxisMaxVal,
      showPercentages
    )
  }, [data, columns, chartPadding, noDataMonthColumns, noDataInAllMonth, yAxisMaxVal, showPercentages])

  return <ReactEChartsRenderer option={option} />
}

export default AssetHealthConditionStackedBar
