import { AssetStatusString, RecommendedActionStatusString } from '@/shared/models/types'

export type GlobalFilters = {
  raStatus: RecommendedActionStatusType[]
  assetStatus: AssetHealthStatusType[]
  faultType: string[]
}

export type FaultTypeSelectOption = {
  faultType: string
  title: string
  count?: number
}

export enum Action {
  FaultTypeSelectOptions = 'FAULT_TYPE_SELECT_OPTIONS',
}

export type RecommendedActionStatusType = Exclude<RecommendedActionStatusString, ['completed', 'rejected']>
export type RecommendedActionStatusSelectType = 'all' | RecommendedActionStatusType
export type AssetHealthStatusType = Exclude<AssetStatusString, 'never-reported'>
export type AssetHealthStatusSelectType = 'all' | AssetHealthStatusType
export type SelectFilterType = {
  raStatus: RecommendedActionStatusSelectType[]
  assetStatus: AssetHealthStatusSelectType[]
  faultType: string[]
}
