import HeaderInfoPanelIcon from '@/shared/components/ModuleHeader/infoPanel/HeaderInfoPanelIcon'
import { generateUUID } from '@/shared/utils'
import { headerConfigSelector } from '@/store/selectors/moduleHeaderSelector'
import { useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Flexbox, SPACINGS, css } from '@skf-internal/ui-components-react'

/**
 * HeaderInfoPanel is a component that displays an information panel in the module header.
 *
 * This panel consists of a set of icons defined in the module state and can optionally
 * include a custom element. The icons are rendered dynamically based on the `infoPanelData`
 * from the Redux store.
 *
 * Each icon is represented by the `HeaderInfoPanelIcon` component, which receives props
 * based on the icon data.
 *
 * @returns {JSX.Element} A rendered Flexbox containing icons and an optional custom element.
 */

const HeaderInfoPanel = () => {
  const { infoPanelData, infoPanelElement: CustomElement } = useTypedSelector((store) => headerConfigSelector(store))

  return (
    <Flexbox
      feAlignItems="baseline"
      css={css`
        gap: ${SPACINGS.md};
      `}
      data-testid={dataTestId.headerInfoPanel}
    >
      {infoPanelData?.map((iconData) => <HeaderInfoPanelIcon key={generateUUID()} {...iconData} />)}
      {CustomElement || <div />}
    </Flexbox>
  )
}

export default HeaderInfoPanel
