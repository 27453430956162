import ReportStatusErrorElement from '@/app/routes/ReportStatusErrorElement'
import ProtectedRoutes from '@/app/routes/ProtectedRoutes'
import { RoutesErrorBoundary } from '@/app/routes/RoutesErrorBoundary'
import { getApplicationInitialData, getReportStatusLoaderData } from '@/app/routes/routesUtils'
import { ReportStatusLoaderData } from '@/app/routes/types'
import AutomaticDiagnostics from '@/modules/automatic-diagnostics'
import Dashboard from '@/modules/dashboard'
import { SelectedDashboard } from '@/modules/dashboard/components/SelectedDashboard'
// import { OrphanNotes } from '@/modules/orphan-notes'
import Overview from '@/modules/overview'
import ReportGenerator from '@/modules/report-generator'
import ReportStatus from '@/modules/report-status'
import ReportStatusDialog from '@/modules/report-status/components/reportStatusDialog'
import SiteDetails from '@/modules/site-details'
import { ErrorNotFound } from '@/shared/components/ErrorNotFound'
import GenericError from '@/shared/components/GenericError'
import { ROUTES } from '@/shared/constants'
import { startGlobalLoading } from '@/store/loading/action'
import { store } from '@/store/store'
import { LoaderFunctionArgs, Navigate, RouteObject } from 'react-router'
import DevicesAndSensors from '@/modules/devicesSensors'
import AnalysisBacklog from '@/modules/analysisBacklog'
import OpenRecommendations from '@/modules/openRecommendations'

const routesConfig = (): RouteObject[] => [
  {
    path: ROUTES.HOME,
    element: <ProtectedRoutes />,
    errorElement: <RoutesErrorBoundary />,
    loader: async () => {
      return await getApplicationInitialData()
    },
    children: [
      {
        path: ROUTES.HOME,
        element: <Navigate to={ROUTES.OVERVIEW} />,
      },
      // {
      //   path: `${ROUTES.ANALYSIS_BACKLOG}/:sid`,
      //   element: <AnalysisBacklog />,
      // },
      {
        path: `${ROUTES.ANALYSIS_BACKLOG}/:sid`,
        element: <AnalysisBacklog />,
      },
      {
        path: ROUTES.REPORT_STATUS,
        element: <ReportStatus />,
        children: [
          {
            path: ':assetId',
            element: <ReportStatusDialog />,
            errorElement: <ReportStatusErrorElement />,
            loader: async ({
              params: { assetId },
            }: LoaderFunctionArgs): Promise<void | Partial<ReportStatusLoaderData>> => {
              store.dispatch(startGlobalLoading())
              return getReportStatusLoaderData(assetId)
            },
          },
        ],
      },
      {
        path: `${ROUTES.DASHBOARD}/:sid`,
        element: <Dashboard />,
        // loader: async (): Promise<FaultTypes> => {
        //   let result = [] as FaultTypes
        //   const faultTypes = (await getReportFaultTypes({
        //     onError: async (error) => {
        //       asyncThrow(new DarwinError(error))
        //     },
        //   })) as GetReportFaultTypesResponse
        //   if (faultTypes) {
        //     result = faultTypes.types.map((type) => {
        //       return {
        //         code: type['code'],
        //         text: type['en'],
        //       }
        //     })
        //   }
        //   return result
        // },
        // action: async ({ request }: ActionFunctionArgs) => {
        //   const form = await request.formData()
        //   const title = form.get('dashboard-title') as string
        //   const newDashboardId = await createDashboard(
        //     { title },
        //     {
        //       onError: async (error) => {
        //         throwError(error)
        //       },
        //     }
        //   )
        //   return newDashboardId
        // },
        children: [
          {
            path: '',
            element: <SelectedDashboard />,
          },
        ],
      },
      // {
      //   path: `${ROUTES.ORPHAN_NOTES}/:sid`,
      //   element: <OrphanNotes />,
      // },
      {
        path: ROUTES.OVERVIEW,
        element: <Overview />,
      },
      {
        path: `${ROUTES.SITE_DETAILS}/:sid`,
        element: <SiteDetails />,
      },
      {
        path: `${ROUTES.DEVICES_AND_SENSORS}/:sid`,
        element: <DevicesAndSensors />,
      },
      {
        path: `${ROUTES.OPEN_RECOMMENDATIONS}/:sid`,
        element: <OpenRecommendations />,
      },
      {
        path: `${ROUTES.AUTOMATIC_DIAGNOSTICS}/:sid`,
        element: <AutomaticDiagnostics />,
      },
      {
        path: `${ROUTES.REPORT_GENERATOR}/:sid`,
        element: <ReportGenerator />,
      },
      {
        path: ROUTES.TAB_ERROR,
        element: <GenericError />,
      },
      {
        path: ROUTES.ERROR,
        element: <GenericError />,
      },
      {
        path: ROUTES.NOT_FOUND,
        element: <ErrorNotFound />,
      },
    ],
  },
  {
    path: '*',
    element: <ErrorNotFound />,
  },
]

export default routesConfig
