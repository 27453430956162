import { StyledDaysDueContainer, StyledDaysDueSpan } from '@/modules/openRecommendations/styled'
import { convertToDisplayValue } from '@/shared/utils'
import { FC } from 'react'

interface DaysInDueProps {
  daysInDue: number
}

const DaysInDue: FC<DaysInDueProps> = ({ daysInDue }) => {
  return (
    <StyledDaysDueContainer>
      {daysInDue ? (
        <StyledDaysDueSpan daysInDue={daysInDue}>{daysInDue} Days</StyledDaysDueSpan>
      ) : (
        convertToDisplayValue(daysInDue)
      )}
    </StyledDaysDueContainer>
  )
}

export default DaysInDue
