import React from 'react'
import {
  StyledAssetHierarchyContainer,
  StyledAssetSelectionContainer,
  StyledCompanyLogo,
  StyledCompanyLogoContainer,
  StyledReportGeneratorLogo,
} from '@/modules/report-generator/styled'
import { dataTestId } from '@/tests/testid'
import { useReportGeneratorContext } from '@/contexts/reportGenerator'
import placeholderLogoSVG from '@/modules/site-details/assets/attachment_failed_4x.webp'

const AssetSelection: React.FC = () => {
  const { reportGeneratorLogoUrl } = useReportGeneratorContext()

  return (
    <StyledAssetSelectionContainer data-testid={dataTestId.reportGeneratorAssetSelection}>
      <StyledCompanyLogoContainer data-testid={dataTestId.reportGeneratorCompanyLogo}>
        <StyledCompanyLogo>
          <StyledReportGeneratorLogo src={reportGeneratorLogoUrl || placeholderLogoSVG} alt="" />
        </StyledCompanyLogo>
      </StyledCompanyLogoContainer>
      <StyledAssetHierarchyContainer>
        <h4>Select assets to report</h4>
      </StyledAssetHierarchyContainer>
    </StyledAssetSelectionContainer>
  )
}
export default AssetSelection
