import { allAssetTypes, criticalityOptions, statusOptions } from '@/modules/report-generator/utils/constants'
import { AllKeysOf, AssetCriticality, ExtractArrayValues } from '@/shared/models/types'
import { AssetHealthStatusSelectType } from '../dashboard/globalFiltering/types'

export enum ReportType {
  summaryCharts = 'Summary Charts',
  summarizedAssetHealth = 'Summarized Asset Health',
  detailedAssetHealth = 'Detailed Asset Health',
  lastMeasurements = 'Last Measurements',
  openRecommendedActions = 'Open Recommended Actions',
}

export type ReportTypeString = AllKeysOf<ReportType>

export type StatusOptionValue = ExtractArrayValues<typeof statusOptions, 'value'>
export type CriticalityOptionValue = ExtractArrayValues<typeof criticalityOptions, 'value'>

export type AssetReportFilters = {
  assets: string[]
  assetStatuses: AssetHealthStatusSelectType[]
  assetCriticalities: AssetCriticality[]
  assetTypes: Array<'all' | (typeof allAssetTypes)[number]>
  reportTypes: ReportTypeString[]
  reportDate: Date
}
export type ReportGeneratorApiPayload = Omit<AssetReportFilters, 'reportDate'> & {
  reportDate: string
}

export interface IReportSelect<R> {
  value: R[]
  onChange: (value: R[]) => void
}

type CoverPage = {
  companyName: string
  siteName: string
  address: string
  createdUser: string
  reportedDate: Date
  logoFileURL: string
}

export type ReportGeneratorApiResponse = {
  coverPage: CoverPage
  summaryCharts: Record<string, unknown> //will be updated in specific api integration ticket
  summarizedAssetHealth: Record<string, unknown>[] //will be updated in specific api integration ticket
  detailedAssetHealth: Record<string, unknown> //will be updated in specific api integration ticket
  openRecommendedActions: Record<string, unknown>[] //will be updated in specific api integration ticket
}
