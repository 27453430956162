import { SPACINGS, styled } from '@skf-internal/ui-components-react'
import { Flexbox } from '@skf-internal/ui-components-react'
import { WidgetWrapper } from '@/modules/dashboard/components/responsiveLayout/WidgetWrapper'

export const StyledDashboardRoot = styled.div`
  padding: 0 2rem;
  margin: 1rem 1.25rem 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const StyledDashboardContainer = styled(Flexbox)`
  overflow: auto;
  height: 76vh;
  padding: 0 1rem;

  @media (width <= 1165px) {
    justify-content: flex-start;
  }

  @media (width >= 1166px) and (width <= 1249px) {
    transform: scale(0.55);
    height: calc(100vh - 10px);
    width: calc(100% / 0.55);
    transform-origin: top left;
  }

  @media (width >= 1250px) and (width <= 1280px) {
    transform: scale(0.65);
    height: calc(100vh - 60px);
    width: 150%;
    position: absolute;
    top: 150px;
    margin: 0 -28%;
  }

  @media (width >= 1281px) and (width <= 1366px) {
    transform: scale(0.65);
    height: calc(100vh - 60px);
    width: 150%;
    position: absolute;
    top: 132px;
    margin: 0 -28%;
  }

  @media (width >= 1367px) and (width <= 1440px) {
    transform: scale(0.7);
    height: calc(100vh - 90px);
    width: 142%;
    position: absolute;
    top: 170px;
    margin: 0 -25%;
  }

  @media (width >= 1441px) and (width <= 1920px) {
    transform: scale(0.78);
    height: calc(100vh + 8px);
    width: 150%;
    position: absolute;
    top: 145px;
    margin: 0 -28%;
  }

  ::-webkit-scrollbar {
    width: 0.625rem;
    height: 1.125rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f142;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 0.625rem;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a9a8a8;
  }
`

export const StyledMainWidgetColumn = styled(Flexbox)`
  flex-grow: 0;
  width: 79.625rem;
`
export const StyledGlobalFilterContainer = styled(Flexbox)`
  gap: ${SPACINGS.md};
  min-height: 4.688rem;
`

export const StyledAssetHealthWidget = styled(WidgetWrapper)`
  height: 25.5rem;
`

export const StyledRecommendedActionsWidget = styled(WidgetWrapper)`
  height: 25.5rem;
`

export const StyledAssetHealthFaultWidget = styled(WidgetWrapper)`
  height: 52.3125rem;
  width: 34.375rem;
  margin-bottom: 0.625rem;
`

export const StyledWrapperOpenFaults = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const StyledBarChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  flex-grow: 1;

  svg {
    cursor: default;

    &:hover {
      cursor: default;
    }
  }
`

export const StyledBarChartContainer = styled.div`
  position: absolute;
  inset: 0;
`

export const StyledRASelectContainer = styled.div`
  * {
    flex-wrap: wrap;
  }

  label {
    font-weight: 700;
  }

  span {
    font-weight: 400;
  }

  width: 205px;
`
