import { OverviewResponse } from '@/models/overview/types'
import useSiteName from '@/modules/overview/columns/thirdLevel/useSiteName'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { MRT_ColumnDef } from 'material-react-table'

const useCompanySite = () => {
  const siteNameColumn = useSiteName()
  const companySiteColumns = useDeepCompareMemo<MRT_ColumnDef<OverviewResponse['sites'][number]>[]>(
    () => [
      {
        header: '',
        id: 'companySiteData',
        columns: siteNameColumn,
      },
    ],
    [siteNameColumn]
  )
  return companySiteColumns
}

export default useCompanySite
