import { convertToDisplayValue } from '@/shared/utils'
import { Flexbox } from '@skf-internal/ui-components-react'
import { FC } from 'react'

interface OpenRecommendationAssetNameProps {
  assetName: string
}

const OpenRecommendationAssetName: FC<OpenRecommendationAssetNameProps> = ({ assetName }) => {
  return <Flexbox>{convertToDisplayValue(assetName)}</Flexbox>
}

export default OpenRecommendationAssetName
