import { ReportStatusLoaderData } from '@/app/routes/types'
import { useFaultsFormStateContext } from '@/contexts/moduleContexts/faultsForm'
import { useReportStatusContext } from '@/contexts/reportStatus'
import { InitialAsset } from '@/models/analysisBacklog/types'
import { FaultState, RecommendedActionState } from '@/models/reportStatus/faultsFormStateTypes'
import ReconfirmButton from '@/modules/report-status/components/reconfirmButton'
import usePublishReport from '@/modules/report-status/hooks/usePublishReport'
import { RESET_FORM } from '@/modules/report-status/reducer/actions.types'
import { StyledReportStatusFooter } from '@/modules/report-status/styled'
import { getOpenFaults } from '@/modules/report-status/utils'
import { isAnyInSpecifiedStates } from '@/modules/report-status/utils/formUtils'
import GenericConfirmationDialogButton from '@/shared/components/GenericConfirmationDialog/GenericConfirmationDialogButton'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { STORAGE_REPORT_STATUS_ORIGIN } from '@/shared/localStorageUtils'
import { getSidFromPathname } from '@/shared/utils'
import { closeGenericConfirmDialog, showGenericConfirmDialog } from '@/store/genericConfirmationDialog/actions'
import { asyncPollerSelector } from '@/store/selectors/asyncPollerSelector'
import { useAppDispatch, useTypedSelector } from '@/store/store'
import { dataTestId } from '@/tests/testid'
import { Button } from '@skf-internal/ui-components-react'
import { FC } from 'react'
import { useLoaderData, useParams } from 'react-router'

interface FaultFormFooterProps {
  assetRow: InitialAsset
  isFormValid: boolean
}

const FaultFormFooter: FC<FaultFormFooterProps> = ({ assetRow, isFormValid }) => {
  const { analyticsCancelButtonBehavior, setAnalyticsCancelButtonBehavior, uploadedEvidenceFilesStatus } =
    useReportStatusContext()

  const {
    faultsFormState: { isSubmitting, isDirty, faults },
    faultsFormDispatch,
  } = useFaultsFormStateContext()

  const { isAsyncPollerActive } = useTypedSelector((state) => asyncPollerSelector(state))

  const analyticsBacklogControlPanelDispatch = useAppDispatch()
  const { assetFaultsAndHistory /*customerNotes*/ } = useLoaderData() as ReportStatusLoaderData

  const { assetId } = useParams()

  const sid = getSidFromPathname(sessionStorage.getItem(STORAGE_REPORT_STATUS_ORIGIN))

  const { execute: publishReport } = usePublishReport(sid)

  const hasOpenFaults = useDeepCompareMemo(() => {
    return getOpenFaults(assetFaultsAndHistory /*customerNotes, assetId*/).length > 0
  }, [assetFaultsAndHistory, assetId /*customerNotes*/])

  const isInEditMode = useDeepCompareMemo(() => {
    return isAnyInSpecifiedStates(faults, [
      RecommendedActionState.EDIT,
      RecommendedActionState.PENDING_CLOSE,
      RecommendedActionState.NEW,
      FaultState.EDIT,
      FaultState.PENDING_CLOSE,
      FaultState.NEW,
    ])
  }, [faults])

  const canReconfirm = !isDirty && hasOpenFaults && !isInEditMode

  const closeDialog = () => {
    analyticsBacklogControlPanelDispatch(closeGenericConfirmDialog())
  }

  const handleConfirmClick = () => {
    analyticsBacklogControlPanelDispatch(
      showGenericConfirmDialog({
        isOpen: true,
        title: 'Report Status Confirmation',
        message: `Publish a new report on ${assetRow.name} from ${assetRow.belongsTo.name}?`,
        buttons: [
          <GenericConfirmationDialogButton
            data-testid={dataTestId.faultFormConfirmDialogCancelButton}
            type="secondary"
            label="Cancel"
            onClick={() => closeDialog()}
          />,
          <GenericConfirmationDialogButton
            data-testid={dataTestId.faultFormConfirmDialogConfirmButton}
            type="primary"
            label="Confirm"
            onClick={async () => {
              await publishReport({ postData: faults, assetId })
              closeDialog()
            }}
          />,
        ],
      })
    )
  }

  return (
    <StyledReportStatusFooter
      feAlignItems="center"
      feJustifyContent="flex-end"
      data-testid={dataTestId.faultFormFooter}
    >
      {/* {Object.keys(fieldsWithErrors).length > 0 && (
        <StyledFooterErrorsAlert feIcon="danger" feSeverity="error">
          <Flexbox feFlexDirection="column">
            <Text>{`The form contains ${getTotalErrorsCount()} ${getTotalErrorsCount() > 1 ? 'errors' : 'error'}`}</Text>
            {Object.entries(fieldsWithErrors).map(([key, value]) => (
              <Text>{value > 1 ? `${key}X${value}` : `${key}`}</Text>
            ))}
          </Flexbox>
        </StyledFooterErrorsAlert>
      )} */}
      <Button
        feType="secondary"
        onClick={() => {
          faultsFormDispatch({ type: RESET_FORM })
          setAnalyticsCancelButtonBehavior({ isDisabled: true })
        }}
        disabled={analyticsCancelButtonBehavior.isDisabled}
        data-testid={dataTestId.faultFormFooterCancelButton}
      >
        Cancel
      </Button>
      {canReconfirm ? (
        <ReconfirmButton
          assetName={assetRow.name}
          assetParent={assetRow.belongsTo.name}
          data-testid={dataTestId.faultFormReconfirmButton}
        />
      ) : (
        <Button
          onClick={handleConfirmClick}
          feLoading={isSubmitting || isAsyncPollerActive}
          feType="primary"
          type="button"
          disabled={!isDirty || !isFormValid || uploadedEvidenceFilesStatus.length !== 0}
          data-testid={dataTestId.faultFormFooterSubmitButton}
        >
          Publish
        </Button>
      )}
    </StyledReportStatusFooter>
  )
}

export default FaultFormFooter
