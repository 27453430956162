import { OverviewResponse } from '@/models/overview/types'
import OverviewCell from '@/modules/overview/components/overviewCell'
import {
  StyledOverviewDevicesSensorsColumnIcon,
  StyledOverviewDevicesSensorsColumnWrapper,
} from '@/modules/overview/styled'
import { calculateIconMarginValue } from '@/modules/overview/utils'
import { overviewCellTooltips } from '@/modules/overview/utils/constants'
import { ROUTES } from '@/shared/constants'
import { MRT_ColumnDef } from 'material-react-table'
import { useMemo } from 'react'

const useSensorsData = () => {
  const sensorsData = useMemo<MRT_ColumnDef<OverviewResponse['sites'][number]>[]>(
    () => [
      {
        accessorKey: 'deviceAndSensors.sensors',
        id: 'sensorsData',
        header: '',
        size: 20,
        Cell: ({ cell, row }) =>
          OverviewCell({
            siteId: row.original.siteId,
            value: cell.getValue<string>(),
            routeTo: ROUTES.DEVICES_AND_SENSORS,
            message: overviewCellTooltips[cell.column.columnDef.id as keyof typeof overviewCellTooltips],
            prefixIndicator: (
              <StyledOverviewDevicesSensorsColumnWrapper marginLeft={calculateIconMarginValue(cell.getValue<string>())}>
                <StyledOverviewDevicesSensorsColumnIcon feColor="white" feIcon="exclamation" feSize="sm" />
              </StyledOverviewDevicesSensorsColumnWrapper>
            ),
          }),
      },
    ],
    []
  )
  return sensorsData
}

export default useSensorsData
