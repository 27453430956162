import { useMemo, useRef, useState } from 'react'
import { isLoaderTypeArray } from '../utils'
import useLoadingDispatch from './useLoadingDispatch'

/**
 * Custom hook that behaves like `useRef`, but ensures that the returned ref is always in sync with the current value.
 *
 * The returned ref object is immutable and uses `Object.freeze` to make it read-only. The hook ensures that the
 * ref is kept updated with the latest value, while maintaining immutability.
 *
 * @template T
 * @param {T} value - The current value to synchronize with the ref.
 *
 * @returns {{ readonly current: T }} - An immutable ref object containing the current value.
 */
function useSyncedRef<T>(value: T): { readonly current: T } {
  const ref = useRef(value)

  ref.current = value

  return useMemo(
    () =>
      Object.freeze({
        get current() {
          return ref.current
        },
      }),
    []
  )
}

export type AsyncStatus = 'loading' | 'success' | 'error' | 'not-executed'

export type AsyncState<Result> =
  | {
      status: 'not-executed'
      error: undefined
      result: Result
    }
  | {
      status: 'success'
      error: undefined
      result: Result
    }
  | {
      status: 'error'
      error: Error
      result: Result
    }
  | {
      status: AsyncStatus
      error: Error | undefined
      result: Result
    }

export type UseAsyncActions<Result, Args extends unknown[] = unknown[]> = {
  /**
   * Reset state to initial.
   */
  reset: () => void
  /**
   * Execute the async function manually.
   */
  execute: (...args: Args) => Promise<Result>
}

export type UseAsyncMeta<Result, Args extends unknown[] = unknown[]> = {
  /**
   * Latest promise returned from the async function.
   */
  promise: Promise<Result> | undefined
  /**
   * List of arguments applied to the latest async function invocation.
   */
  lastArgs: Args | undefined
}

export function useAsync<Result, Args extends unknown[] = unknown[]>(
  asyncFn: (...params: Args) => Promise<Result>,
  initialValue: Result
): [AsyncState<Result>, UseAsyncActions<Result, Args>, UseAsyncMeta<Result, Args>]
export function useAsync<Result, Args extends unknown[] = unknown[]>(
  asyncFn: (...params: Args) => Promise<Result>,
  initialValue?: Result
): [AsyncState<Result | undefined>, UseAsyncActions<Result, Args>, UseAsyncMeta<Result, Args>]

/**
 * Custom hook that tracks the state, result, and errors of an asynchronous function, and provides methods to control its execution.
 *
 * This hook can execute an async function manually, reset its state, and manage loading, success, and error states.
 *
 * @template Result - The type of the result that the async function returns.
 * @template Args - The types of the arguments passed to the async function.
 *
 * @param {(...params: Args) => Promise<Result>} asyncFn - The asynchronous function to be executed, which returns a promise.
 * @param {Result} [initialValue] - The initial value set in the state before the async function is executed.
 *
 * @returns {[AsyncState<Result | undefined>, UseAsyncActions<Result, Args>, UseAsyncMeta<Result, Args>]} -
 * Returns a tuple containing:
 * 1. `AsyncState` - The state object representing the current status, result, and error.
 * 2. `UseAsyncActions` - An object with methods to manually execute the async function and reset the state.
 * 3. `UseAsyncMeta` - An object containing the latest promise and the arguments used in the latest async function invocation.
 */
export function useAsync<Result, Args extends unknown[] = unknown[]>(
  asyncFn: (...params: Args) => Promise<Result>,
  initialValue?: Result
): [AsyncState<Result | undefined>, UseAsyncActions<Result, Args>, UseAsyncMeta<Result, Args>] {
  const [state, setState] = useState<AsyncState<Result | undefined>>({
    status: 'not-executed',
    error: undefined,
    result: initialValue,
  })
  const promiseRef = useRef<Promise<Result>>()
  const argsRef = useRef<Args>()

  const handleLoading = useLoadingDispatch()

  const methods = useSyncedRef({
    execute(...params: Args) {
      argsRef.current = params
      if (isLoaderTypeArray(params)) {
        handleLoading(params)
      }
      const promise = asyncFn(...params)
      promiseRef.current = promise

      setState((s) => ({ ...s, status: 'loading' }))

      promise.then(
        (result) => {
          // We dont want to handle result/error of non-latest function
          // this approach helps to avoid race conditions

          if (promise === promiseRef.current) {
            setState((s) => ({
              ...s,
              status: 'success',
              error: undefined,
              result,
            }))
          }
        },
        (error: Error) => {
          // We dont want to handle result/error of non-latest function
          // this approach helps to avoid race conditions
          if (promise === promiseRef.current) {
            setState((s) => ({ ...s, status: 'error', error }))
          }
        }
      )

      return promise
    },
    reset() {
      setState({
        status: 'not-executed',
        error: undefined,
        result: initialValue,
      })
      promiseRef.current = undefined
      argsRef.current = undefined
    },
  })

  return [
    state,
    useMemo(
      () => ({
        reset() {
          methods.current.reset()
        },
        execute: (...params: Args) => methods.current.execute(...params),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    ),
    { promise: promiseRef.current, lastArgs: argsRef.current },
  ]
}
