import { AssetStatusString } from '@/shared/models/types'

export enum ClosedRaOutcome {
  completed = 'Completed',
  rejected = 'Rejected',
}
export type ClosedRaOutcomeKeys = keyof typeof ClosedRaOutcome
export interface Recommendation {
  assetID: string
  assetName: string
  assetParent: string
  assetStatus: AssetStatusString | ClosedRaOutcomeKeys
  faultId: string
  faultType: string
  observation: string
  raOpenDate: string
  recommendedAction: string
  raDueDate: string
  raComment: string
}
export interface OpenRecommendationData {
  assetID: string
  assetName: string
  assetParent: string
  assetStatus: AssetStatusString
  faultId: string
  faultType: string
  observation: string
  raOpenDate: string
  recommendedAction: string
  raDueDate: string
  raComment: string
  raDaysToDue: number
  raOutcome: null
}
export interface ClosedRecommendationData {
  assetID: string
  assetName: string
  assetParent: string
  assetStatus: AssetStatusString
  faultId: string
  faultType: string
  observation: string
  raOpenDate: string
  recommendedAction: string
  raDueDate: string
  raComment: string
  raDaysToDue: null
  raOutcome: ClosedRaOutcomeKeys
}

export type RecommendedAction = Recommendation & {
  raDaysToDue: null | number
  raOutcome: null | ClosedRaOutcomeKeys
}
export type UnresolvedRecommendation = {
  openRecommendationResponse: RecommendedAction[]
  closedRecommendationResponse: RecommendedAction[]
}
export type UnresolvedRecommendationData = {
  open: OpenRecommendationData[]
  closed: ClosedRecommendationData[]
}

export enum RecommendationState {
  Closed = 'Closed',
  Open = 'Open',
  DefaultDate = '-',
}
export enum ClosedRecommendedActionState {
  dueInDays = '-99999',
}

export type DateFilter = {
  dateFilterType: 'after' | 'before' | 'between'
  startDate?: Date
  endDate?: Date
}
