import { FaultErrorState, RecommendedActionErrorState } from '@/models/reportStatus/faultsFormStateTypes'
import { Status } from '@/models/reportStatus/faultsFormStateTypes'
import { COLORS } from '@skf-internal/ui-components-react'

export const MAX_FILES = 5
export const MAX_TOTAL_SIZE = 50 * 1024 * 1024 // 50 MB in bytes
export const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB in bytes
export const ACCEPTED_FILE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'image/png']
export const EVIDENCE_FILE_WIDTH = 320
export const EVIDENCE_FILE_HEIGHT = 320

const closedRecommendedActionOutcomeLabel: Record<'completed' | 'rejected', string> = {
  completed: 'Completed',
  rejected: 'Rejected',
}

const commonFaultErrorStruct: FaultErrorState = {
  id: '',
  fault: {
    error: '',
    isValid: true,
  },
  collectionDate: {
    error: '',
    isValid: true,
  },
  status: {
    error: '',
    isValid: true,
  },
  observation: {
    error: '',
    isValid: true,
  },
  explanation: {
    error: '',
    isValid: true,
  },
  recommendedActions: [],
}
const commonRecommendedActionErrorStruct: RecommendedActionErrorState = {
  id: '',
  recommendedAction: {
    error: '',
    isValid: true,
  },
  dueDate: {
    error: '',
    isValid: true,
  },
  additionalNotes: {
    error: '',
    isValid: true,
  },
  workOrder: {
    error: '',
    isValid: true,
  },
  outcome: {
    error: '',
    isValid: true,
  },
  closeDate: {
    error: '',
    isValid: true,
  },
}

export enum ErrorTexts {
  REQUIRED = 'Required Field',
}

export const EVIDENCE_S3_FOLDER_NAME = 'evidence'

const statusBackgroundColor: Record<Status, string> = {
  normal: COLORS.gray500,
  acceptable: '#88C008',
  unsatisfactory: '#FFD615',
  unacceptable: COLORS.orangeDark,
  severe: '#EB202A',
}

const statusTextColor: Record<Status, string> = {
  normal: COLORS.white,
  acceptable: COLORS.black,
  unsatisfactory: COLORS.black,
  unacceptable: COLORS.white,
  severe: COLORS.white,
}

const DEFAULT_LABEL_TEXT = 'Select'

export {
  closedRecommendedActionOutcomeLabel,
  commonFaultErrorStruct,
  commonRecommendedActionErrorStruct,
  statusBackgroundColor,
  statusTextColor,
  DEFAULT_LABEL_TEXT,
}
