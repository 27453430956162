import { ReactEChartsRenderer } from '../../../common/e-charts-renderer'
import { FC } from 'react'
import { emptyRaDueDatesStatus, severities } from '@/modules/dashboard/utils/constants'
import type { EChartsOption } from 'echarts'
import { getDueDatesStatusBarChartOptions } from '@/modules/dashboard/utils/recommended-actions'
import useDeepCompareMemo from '@/shared/hooks/useDeepCompareMemo'
import { useDashboardContext } from '@/contexts/dashboard'
import { DueDatesStatusPeriod } from '@/models/widgets/recommended-actions/types'
import { getEnumKeys } from '@/shared/utils'

const DueDatesStatusBarChart: FC = () => {
  const { recommendedActionsDueDatesStatus } = useDashboardContext()

  const sortedPeriodData = useDeepCompareMemo(() => {
    if (!recommendedActionsDueDatesStatus?.length) {
      return []
    }

    return getEnumKeys(DueDatesStatusPeriod).map((period) => ({
      period,
      data: recommendedActionsDueDatesStatus.find((item) => item.period === period)?.data || emptyRaDueDatesStatus,
    }))
  }, [recommendedActionsDueDatesStatus])

  const xAxisLabels = useDeepCompareMemo(() => {
    return getEnumKeys(DueDatesStatusPeriod)
      .flatMap((label) => [DueDatesStatusPeriod[label], ''])
      .slice(0, 7)
  }, [])

  const totals = useDeepCompareMemo(() => {
    if (!recommendedActionsDueDatesStatus?.length) {
      return Array(4).fill('')
    }

    return sortedPeriodData.map(({ data }) => severities.reduce((sum, severity) => sum + (data[severity] || 0), 0))
  }, [recommendedActionsDueDatesStatus, sortedPeriodData])

  const alteredData = useDeepCompareMemo(() => {
    if (!recommendedActionsDueDatesStatus?.length) {
      return Array(7).fill('')
    }

    return sortedPeriodData
      .map(({ data }) => data)
      .flatMap((data) => [
        data,
        {
          normal: null,
          acceptable: null,
          unsatisfactory: null,
          unacceptable: null,
          severe: null,
        },
      ])
      .slice(0, 7)
  }, [recommendedActionsDueDatesStatus, sortedPeriodData])

  const option = useDeepCompareMemo<EChartsOption>(
    () => getDueDatesStatusBarChartOptions(xAxisLabels, totals, alteredData),
    [xAxisLabels, totals, alteredData]
  )

  return <ReactEChartsRenderer option={option} />
}

export default DueDatesStatusBarChart
