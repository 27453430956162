import ShowHierarchyButton from '@/modules/analysisBacklog/components/cell/assetName/showHierarchyButton'
import { StyledAssetName } from '@/modules/analysisBacklog/styled'
import { FC } from 'react'

interface AssetNameCellProps {
  assetName: string
  hierarchy: string
}

const AssetNameCell: FC<AssetNameCellProps> = ({ assetName, hierarchy }) => {
  return (
    <>
      <StyledAssetName>{assetName}</StyledAssetName>
      <ShowHierarchyButton assetName={assetName} hierarchy={hierarchy} />
    </>
  )
}

export default AssetNameCell
