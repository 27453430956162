import { FC } from 'react'
import { RAwidgetDataType } from '@/models/widgets/recommended-actions/types'
import RATableRow from './table-row/RATableRow'
import { StyledRAWidgetTable, StyledRANoDataContainer, StyledHighlightColumn } from '../styled'
import { useDashboardContext } from '@/contexts/dashboard'
import { dataTestId } from '@/tests/testid'
import { recommendationStatuses } from '@/modules/dashboard/utils/constants'

interface RecommendedActionsTableProps {
  slices: RAwidgetDataType
  showPercentages: boolean
  noDataInAllMonth: boolean
}

const RecommendedActionsTrendViewTable: FC<RecommendedActionsTableProps> = ({
  slices,
  showPercentages,
  noDataInAllMonth,
}) => {
  const { dashboardFilters } = useDashboardContext()

  if (noDataInAllMonth) {
    return <StyledRANoDataContainer>No Data</StyledRANoDataContainer>
  }

  return (
    <StyledRAWidgetTable data-testid={dataTestId.recommendedActionsTrendViewTable}>
      <colgroup>
        <col span={12} />
        <StyledHighlightColumn span={1} />
      </colgroup>
      <tbody>
        {recommendationStatuses.map((status) => {
          if (status === 'completed' || status === 'rejected') {
            if (dashboardFilters.raStatus.includes('closed')) {
              return <RATableRow key={status} status={status} slices={slices} showPercentages={showPercentages} />
            }
          }
          if (dashboardFilters.raStatus.includes(status)) {
            return <RATableRow key={status} status={status} slices={slices} showPercentages={showPercentages} />
          }
          return null
        })}
      </tbody>
    </StyledRAWidgetTable>
  )
}

export default RecommendedActionsTrendViewTable
