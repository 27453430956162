import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

const _selectRedirectData = (state: RootState) => state.redirectData
const _selectRedirectDataBySid = (_: RootState, sid: string | undefined) => sid

const redirectDataBySidSelector = createSelector(_selectRedirectData, _selectRedirectDataBySid, (redirectData, sid) => {
  let result = undefined
  if (sid && redirectData) {
    result = redirectData[sid]
  }
  return result
})

const hasRedirectDataSelector = createSelector(_selectRedirectData, (redirectData) => {
  return Object.keys(redirectData).length > 0
})

export { redirectDataBySidSelector, hasRedirectDataSelector }
